@charset "UTF-8";
.container {
  width: calc(100% - 2 * var(--component-padding));
  margin-left: auto;
  margin-right: auto;
}

.grid, .flex, .inline-flex {
  --gap: 0px;
  --gap-x: var(--gap);
  --gap-y: var(--gap);
  gap: var(--gap-y) var(--gap-x);
}
.grid > *, .flex > *, .inline-flex > * {
  --sub-gap: 0px;
  --sub-gap-x: var(--sub-gap);
  --sub-gap-y: var(--sub-gap);
}

.grid {
  --grid-columns: 12;
  display: flex;
  flex-wrap: wrap;
}
.grid > * {
  flex-basis: 100%;
  max-width: 100%;
  min-width: 0;
}

/* #region (Safari < 14.1 fallback) */
@media not all and (min-resolution: 0.001dpcm) {
  @supports not (translate: none) {
    .grid, .flex[class*=gap-], .inline-flex[class*=gap-] {
      gap: 0px;
      margin-bottom: calc(-1 * var(--gap-y));
      margin-left: calc(-1 * var(--gap-x));
    }
    .grid > *, .flex[class*=gap-] > *, .inline-flex[class*=gap-] > * {
      margin-bottom: var(--sub-gap-y);
    }

    .grid {
      --offset: var(--gap-x);
      --gap-modifier: 0;
      --offset-modifier: 1;
    }
    .grid > * {
      margin-left: var(--offset);
    }

    .flex[class*=gap-] > *, .inline-flex[class*=gap-] > * {
      margin-left: var(--sub-gap-x);
    }
  }
}
/* #endregion */
.gap-xxxxs {
  --gap-x: var(--space-xxxxs);
  --gap-y: var(--space-xxxxs);
}
.gap-xxxxs > * {
  --sub-gap-x: var(--space-xxxxs);
  --sub-gap-y: var(--space-xxxxs);
}

.gap-xxxs {
  --gap-x: var(--space-xxxs);
  --gap-y: var(--space-xxxs);
}
.gap-xxxs > * {
  --sub-gap-x: var(--space-xxxs);
  --sub-gap-y: var(--space-xxxs);
}

.gap-xxs {
  --gap-x: var(--space-xxs);
  --gap-y: var(--space-xxs);
}
.gap-xxs > * {
  --sub-gap-x: var(--space-xxs);
  --sub-gap-y: var(--space-xxs);
}

.gap-xs {
  --gap-x: var(--space-xs);
  --gap-y: var(--space-xs);
}
.gap-xs > * {
  --sub-gap-x: var(--space-xs);
  --sub-gap-y: var(--space-xs);
}

.gap-sm {
  --gap-x: var(--space-sm);
  --gap-y: var(--space-sm);
}
.gap-sm > * {
  --sub-gap-x: var(--space-sm);
  --sub-gap-y: var(--space-sm);
}

.gap-md {
  --gap-x: var(--space-md);
  --gap-y: var(--space-md);
}
.gap-md > * {
  --sub-gap-x: var(--space-md);
  --sub-gap-y: var(--space-md);
}

.gap-lg {
  --gap-x: var(--space-lg);
  --gap-y: var(--space-lg);
}
.gap-lg > * {
  --sub-gap-x: var(--space-lg);
  --sub-gap-y: var(--space-lg);
}

.gap-xl {
  --gap-x: var(--space-xl);
  --gap-y: var(--space-xl);
}
.gap-xl > * {
  --sub-gap-x: var(--space-xl);
  --sub-gap-y: var(--space-xl);
}

.gap-xxl {
  --gap-x: var(--space-xxl);
  --gap-y: var(--space-xxl);
}
.gap-xxl > * {
  --sub-gap-x: var(--space-xxl);
  --sub-gap-y: var(--space-xxl);
}

.gap-xxxl {
  --gap-x: var(--space-xxxl);
  --gap-y: var(--space-xxxl);
}
.gap-xxxl > * {
  --sub-gap-x: var(--space-xxxl);
  --sub-gap-y: var(--space-xxxl);
}

.gap-xxxxl {
  --gap-x: var(--space-xxxxl);
  --gap-y: var(--space-xxxxl);
}
.gap-xxxxl > * {
  --sub-gap-x: var(--space-xxxxl);
  --sub-gap-y: var(--space-xxxxl);
}

.gap-0 {
  --gap-x: 0;
  --gap-y: 0;
}
.gap-0 > * {
  --sub-gap-x: 0;
  --sub-gap-y: 0;
}

.gap-x-xxxxs {
  --gap-x: var(--space-xxxxs);
}
.gap-x-xxxxs > * {
  --sub-gap-x: var(--space-xxxxs);
}

.gap-x-xxxs {
  --gap-x: var(--space-xxxs);
}
.gap-x-xxxs > * {
  --sub-gap-x: var(--space-xxxs);
}

.gap-x-xxs {
  --gap-x: var(--space-xxs);
}
.gap-x-xxs > * {
  --sub-gap-x: var(--space-xxs);
}

.gap-x-xs {
  --gap-x: var(--space-xs);
}
.gap-x-xs > * {
  --sub-gap-x: var(--space-xs);
}

.gap-x-sm {
  --gap-x: var(--space-sm);
}
.gap-x-sm > * {
  --sub-gap-x: var(--space-sm);
}

.gap-x-md {
  --gap-x: var(--space-md);
}
.gap-x-md > * {
  --sub-gap-x: var(--space-md);
}

.gap-x-lg {
  --gap-x: var(--space-lg);
}
.gap-x-lg > * {
  --sub-gap-x: var(--space-lg);
}

.gap-x-xl {
  --gap-x: var(--space-xl);
}
.gap-x-xl > * {
  --sub-gap-x: var(--space-xl);
}

.gap-x-xxl {
  --gap-x: var(--space-xxl);
}
.gap-x-xxl > * {
  --sub-gap-x: var(--space-xxl);
}

.gap-x-xxxl {
  --gap-x: var(--space-xxxl);
}
.gap-x-xxxl > * {
  --sub-gap-x: var(--space-xxxl);
}

.gap-x-xxxxl {
  --gap-x: var(--space-xxxxl);
}
.gap-x-xxxxl > * {
  --sub-gap-x: var(--space-xxxxl);
}

.gap-x-0 {
  --gap-x: 0;
}
.gap-x-0 > * {
  --sub-gap-x: 0;
}

.gap-y-xxxxs {
  --gap-y: var(--space-xxxxs);
}
.gap-y-xxxxs > * {
  --sub-gap-y: var(--space-xxxxs);
}

.gap-y-xxxs {
  --gap-y: var(--space-xxxs);
}
.gap-y-xxxs > * {
  --sub-gap-y: var(--space-xxxs);
}

.gap-y-xxs {
  --gap-y: var(--space-xxs);
}
.gap-y-xxs > * {
  --sub-gap-y: var(--space-xxs);
}

.gap-y-xs {
  --gap-y: var(--space-xs);
}
.gap-y-xs > * {
  --sub-gap-y: var(--space-xs);
}

.gap-y-sm {
  --gap-y: var(--space-sm);
}
.gap-y-sm > * {
  --sub-gap-y: var(--space-sm);
}

.gap-y-md {
  --gap-y: var(--space-md);
}
.gap-y-md > * {
  --sub-gap-y: var(--space-md);
}

.gap-y-lg {
  --gap-y: var(--space-lg);
}
.gap-y-lg > * {
  --sub-gap-y: var(--space-lg);
}

.gap-y-xl {
  --gap-y: var(--space-xl);
}
.gap-y-xl > * {
  --sub-gap-y: var(--space-xl);
}

.gap-y-xxl {
  --gap-y: var(--space-xxl);
}
.gap-y-xxl > * {
  --sub-gap-y: var(--space-xxl);
}

.gap-y-xxxl {
  --gap-y: var(--space-xxxl);
}
.gap-y-xxxl > * {
  --sub-gap-y: var(--space-xxxl);
}

.gap-y-xxxxl {
  --gap-y: var(--space-xxxxl);
}
.gap-y-xxxxl > * {
  --sub-gap-y: var(--space-xxxxl);
}

.gap-y-0 {
  --gap-y: 0;
}
.gap-y-0 > * {
  --sub-gap-y: 0;
}

.grid-col-1 {
  --grid-columns: 1;
}

.col-1 {
  --span: 1;
}

.grid-col-2 {
  --grid-columns: 2;
}

.col-2 {
  --span: 2;
}

.grid-col-3 {
  --grid-columns: 3;
}

.col-3 {
  --span: 3;
}

.grid-col-4 {
  --grid-columns: 4;
}

.col-4 {
  --span: 4;
}

.grid-col-5 {
  --grid-columns: 5;
}

.col-5 {
  --span: 5;
}

.grid-col-6 {
  --grid-columns: 6;
}

.col-6 {
  --span: 6;
}

.grid-col-7 {
  --grid-columns: 7;
}

.col-7 {
  --span: 7;
}

.grid-col-8 {
  --grid-columns: 8;
}

.col-8 {
  --span: 8;
}

.grid-col-9 {
  --grid-columns: 9;
}

.col-9 {
  --span: 9;
}

.grid-col-10 {
  --grid-columns: 10;
}

.col-10 {
  --span: 10;
}

.grid-col-11 {
  --grid-columns: 11;
}

.col-11 {
  --span: 11;
}

.grid-col-12 {
  --grid-columns: 12;
}

.col-12 {
  --span: 12;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  flex-basis: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
  max-width: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-content {
  flex-grow: 0;
  flex-basis: initial;
  max-width: initial;
}

.offset-1 {
  --offset: 1;
}

.offset-2 {
  --offset: 2;
}

.offset-3 {
  --offset: 3;
}

.offset-4 {
  --offset: 4;
}

.offset-5 {
  --offset: 5;
}

.offset-6 {
  --offset: 6;
}

.offset-7 {
  --offset: 7;
}

.offset-8 {
  --offset: 8;
}

.offset-9 {
  --offset: 9;
}

.offset-10 {
  --offset: 10;
}

.offset-11 {
  --offset: 11;
}

.offset-1, .offset-2, .offset-3, .offset-4, .offset-5, .offset-6, .offset-7, .offset-8, .offset-9, .offset-10, .offset-11 {
  margin-left: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns) + (var(--offset) + var(--offset-modifier, 0)) * var(--sub-gap-x));
}

@media (min-width: 32rem) {
  .gap-xxxxs\@xs {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }
  .gap-xxxxs\@xs > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@xs {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }
  .gap-xxxs\@xs > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@xs {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }
  .gap-xxs\@xs > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@xs {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }
  .gap-xs\@xs > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@xs {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }
  .gap-sm\@xs > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@xs {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }
  .gap-md\@xs > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@xs {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }
  .gap-lg\@xs > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@xs {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }
  .gap-xl\@xs > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@xs {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }
  .gap-xxl\@xs > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@xs {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }
  .gap-xxxl\@xs > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@xs {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }
  .gap-xxxxl\@xs > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@xs {
    --gap-x: 0;
    --gap-y: 0;
  }
  .gap-0\@xs > * {
    --sub-gap-x: 0;
    --sub-gap-y: 0;
  }

  .gap-x-xxxxs\@xs {
    --gap-x: var(--space-xxxxs);
  }
  .gap-x-xxxxs\@xs > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@xs {
    --gap-x: var(--space-xxxs);
  }
  .gap-x-xxxs\@xs > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@xs {
    --gap-x: var(--space-xxs);
  }
  .gap-x-xxs\@xs > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@xs {
    --gap-x: var(--space-xs);
  }
  .gap-x-xs\@xs > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@xs {
    --gap-x: var(--space-sm);
  }
  .gap-x-sm\@xs > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@xs {
    --gap-x: var(--space-md);
  }
  .gap-x-md\@xs > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@xs {
    --gap-x: var(--space-lg);
  }
  .gap-x-lg\@xs > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@xs {
    --gap-x: var(--space-xl);
  }
  .gap-x-xl\@xs > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@xs {
    --gap-x: var(--space-xxl);
  }
  .gap-x-xxl\@xs > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@xs {
    --gap-x: var(--space-xxxl);
  }
  .gap-x-xxxl\@xs > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@xs {
    --gap-x: var(--space-xxxxl);
  }
  .gap-x-xxxxl\@xs > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@xs {
    --gap-x: 0;
  }
  .gap-x-0\@xs > * {
    --sub-gap-x: 0;
  }

  .gap-y-xxxxs\@xs {
    --gap-y: var(--space-xxxxs);
  }
  .gap-y-xxxxs\@xs > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@xs {
    --gap-y: var(--space-xxxs);
  }
  .gap-y-xxxs\@xs > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@xs {
    --gap-y: var(--space-xxs);
  }
  .gap-y-xxs\@xs > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@xs {
    --gap-y: var(--space-xs);
  }
  .gap-y-xs\@xs > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@xs {
    --gap-y: var(--space-sm);
  }
  .gap-y-sm\@xs > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@xs {
    --gap-y: var(--space-md);
  }
  .gap-y-md\@xs > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@xs {
    --gap-y: var(--space-lg);
  }
  .gap-y-lg\@xs > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@xs {
    --gap-y: var(--space-xl);
  }
  .gap-y-xl\@xs > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@xs {
    --gap-y: var(--space-xxl);
  }
  .gap-y-xxl\@xs > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@xs {
    --gap-y: var(--space-xxxl);
  }
  .gap-y-xxxl\@xs > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@xs {
    --gap-y: var(--space-xxxxl);
  }
  .gap-y-xxxxl\@xs > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@xs {
    --gap-y: 0;
  }
  .gap-y-0\@xs > * {
    --sub-gap-y: 0;
  }

  .grid-col-1\@xs {
    --grid-columns: 1;
  }

  .col-1\@xs {
    --span: 1;
  }

  .grid-col-2\@xs {
    --grid-columns: 2;
  }

  .col-2\@xs {
    --span: 2;
  }

  .grid-col-3\@xs {
    --grid-columns: 3;
  }

  .col-3\@xs {
    --span: 3;
  }

  .grid-col-4\@xs {
    --grid-columns: 4;
  }

  .col-4\@xs {
    --span: 4;
  }

  .grid-col-5\@xs {
    --grid-columns: 5;
  }

  .col-5\@xs {
    --span: 5;
  }

  .grid-col-6\@xs {
    --grid-columns: 6;
  }

  .col-6\@xs {
    --span: 6;
  }

  .grid-col-7\@xs {
    --grid-columns: 7;
  }

  .col-7\@xs {
    --span: 7;
  }

  .grid-col-8\@xs {
    --grid-columns: 8;
  }

  .col-8\@xs {
    --span: 8;
  }

  .grid-col-9\@xs {
    --grid-columns: 9;
  }

  .col-9\@xs {
    --span: 9;
  }

  .grid-col-10\@xs {
    --grid-columns: 10;
  }

  .col-10\@xs {
    --span: 10;
  }

  .grid-col-11\@xs {
    --grid-columns: 11;
  }

  .col-11\@xs {
    --span: 11;
  }

  .grid-col-12\@xs {
    --grid-columns: 12;
  }

  .col-12\@xs {
    --span: 12;
  }

  .col-1\@xs, .col-2\@xs, .col-3\@xs, .col-4\@xs, .col-5\@xs, .col-6\@xs, .col-7\@xs, .col-8\@xs, .col-9\@xs, .col-10\@xs, .col-11\@xs, .col-12\@xs {
    flex-basis: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
  }

  .col\@xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-content\@xs {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@xs {
    --offset: 1;
  }

  .offset-2\@xs {
    --offset: 2;
  }

  .offset-3\@xs {
    --offset: 3;
  }

  .offset-4\@xs {
    --offset: 4;
  }

  .offset-5\@xs {
    --offset: 5;
  }

  .offset-6\@xs {
    --offset: 6;
  }

  .offset-7\@xs {
    --offset: 7;
  }

  .offset-8\@xs {
    --offset: 8;
  }

  .offset-9\@xs {
    --offset: 9;
  }

  .offset-10\@xs {
    --offset: 10;
  }

  .offset-11\@xs {
    --offset: 11;
  }

  .offset-1\@xs, .offset-2\@xs, .offset-3\@xs, .offset-4\@xs, .offset-5\@xs, .offset-6\@xs, .offset-7\@xs, .offset-8\@xs, .offset-9\@xs, .offset-10\@xs, .offset-11\@xs {
    margin-left: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns) + (var(--offset) + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@xs {
    margin-left: 0;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports not (translate: none) {
      .offset-0\@xs {
        margin-left: var(--gap-x);
      }
    }
  }
}
@media (min-width: 48rem) {
  .gap-xxxxs\@sm {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }
  .gap-xxxxs\@sm > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@sm {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }
  .gap-xxxs\@sm > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@sm {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }
  .gap-xxs\@sm > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@sm {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }
  .gap-xs\@sm > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@sm {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }
  .gap-sm\@sm > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@sm {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }
  .gap-md\@sm > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@sm {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }
  .gap-lg\@sm > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@sm {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }
  .gap-xl\@sm > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@sm {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }
  .gap-xxl\@sm > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@sm {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }
  .gap-xxxl\@sm > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@sm {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }
  .gap-xxxxl\@sm > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@sm {
    --gap-x: 0;
    --gap-y: 0;
  }
  .gap-0\@sm > * {
    --sub-gap-x: 0;
    --sub-gap-y: 0;
  }

  .gap-x-xxxxs\@sm {
    --gap-x: var(--space-xxxxs);
  }
  .gap-x-xxxxs\@sm > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@sm {
    --gap-x: var(--space-xxxs);
  }
  .gap-x-xxxs\@sm > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@sm {
    --gap-x: var(--space-xxs);
  }
  .gap-x-xxs\@sm > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@sm {
    --gap-x: var(--space-xs);
  }
  .gap-x-xs\@sm > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@sm {
    --gap-x: var(--space-sm);
  }
  .gap-x-sm\@sm > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@sm {
    --gap-x: var(--space-md);
  }
  .gap-x-md\@sm > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@sm {
    --gap-x: var(--space-lg);
  }
  .gap-x-lg\@sm > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@sm {
    --gap-x: var(--space-xl);
  }
  .gap-x-xl\@sm > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@sm {
    --gap-x: var(--space-xxl);
  }
  .gap-x-xxl\@sm > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@sm {
    --gap-x: var(--space-xxxl);
  }
  .gap-x-xxxl\@sm > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@sm {
    --gap-x: var(--space-xxxxl);
  }
  .gap-x-xxxxl\@sm > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@sm {
    --gap-x: 0;
  }
  .gap-x-0\@sm > * {
    --sub-gap-x: 0;
  }

  .gap-y-xxxxs\@sm {
    --gap-y: var(--space-xxxxs);
  }
  .gap-y-xxxxs\@sm > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@sm {
    --gap-y: var(--space-xxxs);
  }
  .gap-y-xxxs\@sm > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@sm {
    --gap-y: var(--space-xxs);
  }
  .gap-y-xxs\@sm > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@sm {
    --gap-y: var(--space-xs);
  }
  .gap-y-xs\@sm > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@sm {
    --gap-y: var(--space-sm);
  }
  .gap-y-sm\@sm > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@sm {
    --gap-y: var(--space-md);
  }
  .gap-y-md\@sm > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@sm {
    --gap-y: var(--space-lg);
  }
  .gap-y-lg\@sm > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@sm {
    --gap-y: var(--space-xl);
  }
  .gap-y-xl\@sm > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@sm {
    --gap-y: var(--space-xxl);
  }
  .gap-y-xxl\@sm > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@sm {
    --gap-y: var(--space-xxxl);
  }
  .gap-y-xxxl\@sm > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@sm {
    --gap-y: var(--space-xxxxl);
  }
  .gap-y-xxxxl\@sm > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@sm {
    --gap-y: 0;
  }
  .gap-y-0\@sm > * {
    --sub-gap-y: 0;
  }

  .grid-col-1\@sm {
    --grid-columns: 1;
  }

  .col-1\@sm {
    --span: 1;
  }

  .grid-col-2\@sm {
    --grid-columns: 2;
  }

  .col-2\@sm {
    --span: 2;
  }

  .grid-col-3\@sm {
    --grid-columns: 3;
  }

  .col-3\@sm {
    --span: 3;
  }

  .grid-col-4\@sm {
    --grid-columns: 4;
  }

  .col-4\@sm {
    --span: 4;
  }

  .grid-col-5\@sm {
    --grid-columns: 5;
  }

  .col-5\@sm {
    --span: 5;
  }

  .grid-col-6\@sm {
    --grid-columns: 6;
  }

  .col-6\@sm {
    --span: 6;
  }

  .grid-col-7\@sm {
    --grid-columns: 7;
  }

  .col-7\@sm {
    --span: 7;
  }

  .grid-col-8\@sm {
    --grid-columns: 8;
  }

  .col-8\@sm {
    --span: 8;
  }

  .grid-col-9\@sm {
    --grid-columns: 9;
  }

  .col-9\@sm {
    --span: 9;
  }

  .grid-col-10\@sm {
    --grid-columns: 10;
  }

  .col-10\@sm {
    --span: 10;
  }

  .grid-col-11\@sm {
    --grid-columns: 11;
  }

  .col-11\@sm {
    --span: 11;
  }

  .grid-col-12\@sm {
    --grid-columns: 12;
  }

  .col-12\@sm {
    --span: 12;
  }

  .col-1\@sm, .col-2\@sm, .col-3\@sm, .col-4\@sm, .col-5\@sm, .col-6\@sm, .col-7\@sm, .col-8\@sm, .col-9\@sm, .col-10\@sm, .col-11\@sm, .col-12\@sm {
    flex-basis: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
  }

  .col\@sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-content\@sm {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@sm {
    --offset: 1;
  }

  .offset-2\@sm {
    --offset: 2;
  }

  .offset-3\@sm {
    --offset: 3;
  }

  .offset-4\@sm {
    --offset: 4;
  }

  .offset-5\@sm {
    --offset: 5;
  }

  .offset-6\@sm {
    --offset: 6;
  }

  .offset-7\@sm {
    --offset: 7;
  }

  .offset-8\@sm {
    --offset: 8;
  }

  .offset-9\@sm {
    --offset: 9;
  }

  .offset-10\@sm {
    --offset: 10;
  }

  .offset-11\@sm {
    --offset: 11;
  }

  .offset-1\@sm, .offset-2\@sm, .offset-3\@sm, .offset-4\@sm, .offset-5\@sm, .offset-6\@sm, .offset-7\@sm, .offset-8\@sm, .offset-9\@sm, .offset-10\@sm, .offset-11\@sm {
    margin-left: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns) + (var(--offset) + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@sm {
    margin-left: 0;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports not (translate: none) {
      .offset-0\@sm {
        margin-left: var(--gap-x);
      }
    }
  }
}
@media (min-width: 64rem) {
  .gap-xxxxs\@md {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }
  .gap-xxxxs\@md > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@md {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }
  .gap-xxxs\@md > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@md {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }
  .gap-xxs\@md > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@md {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }
  .gap-xs\@md > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@md {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }
  .gap-sm\@md > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@md {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }
  .gap-md\@md > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@md {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }
  .gap-lg\@md > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@md {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }
  .gap-xl\@md > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@md {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }
  .gap-xxl\@md > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@md {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }
  .gap-xxxl\@md > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@md {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }
  .gap-xxxxl\@md > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@md {
    --gap-x: 0;
    --gap-y: 0;
  }
  .gap-0\@md > * {
    --sub-gap-x: 0;
    --sub-gap-y: 0;
  }

  .gap-x-xxxxs\@md {
    --gap-x: var(--space-xxxxs);
  }
  .gap-x-xxxxs\@md > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@md {
    --gap-x: var(--space-xxxs);
  }
  .gap-x-xxxs\@md > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@md {
    --gap-x: var(--space-xxs);
  }
  .gap-x-xxs\@md > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@md {
    --gap-x: var(--space-xs);
  }
  .gap-x-xs\@md > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@md {
    --gap-x: var(--space-sm);
  }
  .gap-x-sm\@md > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@md {
    --gap-x: var(--space-md);
  }
  .gap-x-md\@md > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@md {
    --gap-x: var(--space-lg);
  }
  .gap-x-lg\@md > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@md {
    --gap-x: var(--space-xl);
  }
  .gap-x-xl\@md > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@md {
    --gap-x: var(--space-xxl);
  }
  .gap-x-xxl\@md > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@md {
    --gap-x: var(--space-xxxl);
  }
  .gap-x-xxxl\@md > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@md {
    --gap-x: var(--space-xxxxl);
  }
  .gap-x-xxxxl\@md > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@md {
    --gap-x: 0;
  }
  .gap-x-0\@md > * {
    --sub-gap-x: 0;
  }

  .gap-y-xxxxs\@md {
    --gap-y: var(--space-xxxxs);
  }
  .gap-y-xxxxs\@md > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@md {
    --gap-y: var(--space-xxxs);
  }
  .gap-y-xxxs\@md > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@md {
    --gap-y: var(--space-xxs);
  }
  .gap-y-xxs\@md > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@md {
    --gap-y: var(--space-xs);
  }
  .gap-y-xs\@md > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@md {
    --gap-y: var(--space-sm);
  }
  .gap-y-sm\@md > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@md {
    --gap-y: var(--space-md);
  }
  .gap-y-md\@md > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@md {
    --gap-y: var(--space-lg);
  }
  .gap-y-lg\@md > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@md {
    --gap-y: var(--space-xl);
  }
  .gap-y-xl\@md > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@md {
    --gap-y: var(--space-xxl);
  }
  .gap-y-xxl\@md > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@md {
    --gap-y: var(--space-xxxl);
  }
  .gap-y-xxxl\@md > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@md {
    --gap-y: var(--space-xxxxl);
  }
  .gap-y-xxxxl\@md > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@md {
    --gap-y: 0;
  }
  .gap-y-0\@md > * {
    --sub-gap-y: 0;
  }

  .grid-col-1\@md {
    --grid-columns: 1;
  }

  .col-1\@md {
    --span: 1;
  }

  .grid-col-2\@md {
    --grid-columns: 2;
  }

  .col-2\@md {
    --span: 2;
  }

  .grid-col-3\@md {
    --grid-columns: 3;
  }

  .col-3\@md {
    --span: 3;
  }

  .grid-col-4\@md {
    --grid-columns: 4;
  }

  .col-4\@md {
    --span: 4;
  }

  .grid-col-5\@md {
    --grid-columns: 5;
  }

  .col-5\@md {
    --span: 5;
  }

  .grid-col-6\@md {
    --grid-columns: 6;
  }

  .col-6\@md {
    --span: 6;
  }

  .grid-col-7\@md {
    --grid-columns: 7;
  }

  .col-7\@md {
    --span: 7;
  }

  .grid-col-8\@md {
    --grid-columns: 8;
  }

  .col-8\@md {
    --span: 8;
  }

  .grid-col-9\@md {
    --grid-columns: 9;
  }

  .col-9\@md {
    --span: 9;
  }

  .grid-col-10\@md {
    --grid-columns: 10;
  }

  .col-10\@md {
    --span: 10;
  }

  .grid-col-11\@md {
    --grid-columns: 11;
  }

  .col-11\@md {
    --span: 11;
  }

  .grid-col-12\@md {
    --grid-columns: 12;
  }

  .col-12\@md {
    --span: 12;
  }

  .col-1\@md, .col-2\@md, .col-3\@md, .col-4\@md, .col-5\@md, .col-6\@md, .col-7\@md, .col-8\@md, .col-9\@md, .col-10\@md, .col-11\@md, .col-12\@md {
    flex-basis: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
  }

  .col\@md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-content\@md {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@md {
    --offset: 1;
  }

  .offset-2\@md {
    --offset: 2;
  }

  .offset-3\@md {
    --offset: 3;
  }

  .offset-4\@md {
    --offset: 4;
  }

  .offset-5\@md {
    --offset: 5;
  }

  .offset-6\@md {
    --offset: 6;
  }

  .offset-7\@md {
    --offset: 7;
  }

  .offset-8\@md {
    --offset: 8;
  }

  .offset-9\@md {
    --offset: 9;
  }

  .offset-10\@md {
    --offset: 10;
  }

  .offset-11\@md {
    --offset: 11;
  }

  .offset-1\@md, .offset-2\@md, .offset-3\@md, .offset-4\@md, .offset-5\@md, .offset-6\@md, .offset-7\@md, .offset-8\@md, .offset-9\@md, .offset-10\@md, .offset-11\@md {
    margin-left: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns) + (var(--offset) + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@md {
    margin-left: 0;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports not (translate: none) {
      .offset-0\@md {
        margin-left: var(--gap-x);
      }
    }
  }
}
@media (min-width: 80rem) {
  .gap-xxxxs\@lg {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }
  .gap-xxxxs\@lg > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@lg {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }
  .gap-xxxs\@lg > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@lg {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }
  .gap-xxs\@lg > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@lg {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }
  .gap-xs\@lg > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@lg {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }
  .gap-sm\@lg > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@lg {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }
  .gap-md\@lg > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@lg {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }
  .gap-lg\@lg > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@lg {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }
  .gap-xl\@lg > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@lg {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }
  .gap-xxl\@lg > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@lg {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }
  .gap-xxxl\@lg > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@lg {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }
  .gap-xxxxl\@lg > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@lg {
    --gap-x: 0;
    --gap-y: 0;
  }
  .gap-0\@lg > * {
    --sub-gap-x: 0;
    --sub-gap-y: 0;
  }

  .gap-x-xxxxs\@lg {
    --gap-x: var(--space-xxxxs);
  }
  .gap-x-xxxxs\@lg > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@lg {
    --gap-x: var(--space-xxxs);
  }
  .gap-x-xxxs\@lg > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@lg {
    --gap-x: var(--space-xxs);
  }
  .gap-x-xxs\@lg > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@lg {
    --gap-x: var(--space-xs);
  }
  .gap-x-xs\@lg > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@lg {
    --gap-x: var(--space-sm);
  }
  .gap-x-sm\@lg > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@lg {
    --gap-x: var(--space-md);
  }
  .gap-x-md\@lg > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@lg {
    --gap-x: var(--space-lg);
  }
  .gap-x-lg\@lg > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@lg {
    --gap-x: var(--space-xl);
  }
  .gap-x-xl\@lg > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@lg {
    --gap-x: var(--space-xxl);
  }
  .gap-x-xxl\@lg > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@lg {
    --gap-x: var(--space-xxxl);
  }
  .gap-x-xxxl\@lg > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@lg {
    --gap-x: var(--space-xxxxl);
  }
  .gap-x-xxxxl\@lg > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@lg {
    --gap-x: 0;
  }
  .gap-x-0\@lg > * {
    --sub-gap-x: 0;
  }

  .gap-y-xxxxs\@lg {
    --gap-y: var(--space-xxxxs);
  }
  .gap-y-xxxxs\@lg > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@lg {
    --gap-y: var(--space-xxxs);
  }
  .gap-y-xxxs\@lg > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@lg {
    --gap-y: var(--space-xxs);
  }
  .gap-y-xxs\@lg > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@lg {
    --gap-y: var(--space-xs);
  }
  .gap-y-xs\@lg > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@lg {
    --gap-y: var(--space-sm);
  }
  .gap-y-sm\@lg > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@lg {
    --gap-y: var(--space-md);
  }
  .gap-y-md\@lg > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@lg {
    --gap-y: var(--space-lg);
  }
  .gap-y-lg\@lg > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@lg {
    --gap-y: var(--space-xl);
  }
  .gap-y-xl\@lg > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@lg {
    --gap-y: var(--space-xxl);
  }
  .gap-y-xxl\@lg > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@lg {
    --gap-y: var(--space-xxxl);
  }
  .gap-y-xxxl\@lg > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@lg {
    --gap-y: var(--space-xxxxl);
  }
  .gap-y-xxxxl\@lg > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@lg {
    --gap-y: 0;
  }
  .gap-y-0\@lg > * {
    --sub-gap-y: 0;
  }

  .grid-col-1\@lg {
    --grid-columns: 1;
  }

  .col-1\@lg {
    --span: 1;
  }

  .grid-col-2\@lg {
    --grid-columns: 2;
  }

  .col-2\@lg {
    --span: 2;
  }

  .grid-col-3\@lg {
    --grid-columns: 3;
  }

  .col-3\@lg {
    --span: 3;
  }

  .grid-col-4\@lg {
    --grid-columns: 4;
  }

  .col-4\@lg {
    --span: 4;
  }

  .grid-col-5\@lg {
    --grid-columns: 5;
  }

  .col-5\@lg {
    --span: 5;
  }

  .grid-col-6\@lg {
    --grid-columns: 6;
  }

  .col-6\@lg {
    --span: 6;
  }

  .grid-col-7\@lg {
    --grid-columns: 7;
  }

  .col-7\@lg {
    --span: 7;
  }

  .grid-col-8\@lg {
    --grid-columns: 8;
  }

  .col-8\@lg {
    --span: 8;
  }

  .grid-col-9\@lg {
    --grid-columns: 9;
  }

  .col-9\@lg {
    --span: 9;
  }

  .grid-col-10\@lg {
    --grid-columns: 10;
  }

  .col-10\@lg {
    --span: 10;
  }

  .grid-col-11\@lg {
    --grid-columns: 11;
  }

  .col-11\@lg {
    --span: 11;
  }

  .grid-col-12\@lg {
    --grid-columns: 12;
  }

  .col-12\@lg {
    --span: 12;
  }

  .col-1\@lg, .col-2\@lg, .col-3\@lg, .col-4\@lg, .col-5\@lg, .col-6\@lg, .col-7\@lg, .col-8\@lg, .col-9\@lg, .col-10\@lg, .col-11\@lg, .col-12\@lg {
    flex-basis: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
  }

  .col\@lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-content\@lg {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@lg {
    --offset: 1;
  }

  .offset-2\@lg {
    --offset: 2;
  }

  .offset-3\@lg {
    --offset: 3;
  }

  .offset-4\@lg {
    --offset: 4;
  }

  .offset-5\@lg {
    --offset: 5;
  }

  .offset-6\@lg {
    --offset: 6;
  }

  .offset-7\@lg {
    --offset: 7;
  }

  .offset-8\@lg {
    --offset: 8;
  }

  .offset-9\@lg {
    --offset: 9;
  }

  .offset-10\@lg {
    --offset: 10;
  }

  .offset-11\@lg {
    --offset: 11;
  }

  .offset-1\@lg, .offset-2\@lg, .offset-3\@lg, .offset-4\@lg, .offset-5\@lg, .offset-6\@lg, .offset-7\@lg, .offset-8\@lg, .offset-9\@lg, .offset-10\@lg, .offset-11\@lg {
    margin-left: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns) + (var(--offset) + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@lg {
    margin-left: 0;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports not (translate: none) {
      .offset-0\@lg {
        margin-left: var(--gap-x);
      }
    }
  }
}
@media (min-width: 90rem) {
  .gap-xxxxs\@xl {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }
  .gap-xxxxs\@xl > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@xl {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }
  .gap-xxxs\@xl > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@xl {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }
  .gap-xxs\@xl > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@xl {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }
  .gap-xs\@xl > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@xl {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }
  .gap-sm\@xl > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@xl {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }
  .gap-md\@xl > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@xl {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }
  .gap-lg\@xl > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@xl {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }
  .gap-xl\@xl > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@xl {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }
  .gap-xxl\@xl > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@xl {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }
  .gap-xxxl\@xl > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@xl {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }
  .gap-xxxxl\@xl > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@xl {
    --gap-x: 0;
    --gap-y: 0;
  }
  .gap-0\@xl > * {
    --sub-gap-x: 0;
    --sub-gap-y: 0;
  }

  .gap-x-xxxxs\@xl {
    --gap-x: var(--space-xxxxs);
  }
  .gap-x-xxxxs\@xl > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@xl {
    --gap-x: var(--space-xxxs);
  }
  .gap-x-xxxs\@xl > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@xl {
    --gap-x: var(--space-xxs);
  }
  .gap-x-xxs\@xl > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@xl {
    --gap-x: var(--space-xs);
  }
  .gap-x-xs\@xl > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@xl {
    --gap-x: var(--space-sm);
  }
  .gap-x-sm\@xl > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@xl {
    --gap-x: var(--space-md);
  }
  .gap-x-md\@xl > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@xl {
    --gap-x: var(--space-lg);
  }
  .gap-x-lg\@xl > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@xl {
    --gap-x: var(--space-xl);
  }
  .gap-x-xl\@xl > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@xl {
    --gap-x: var(--space-xxl);
  }
  .gap-x-xxl\@xl > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@xl {
    --gap-x: var(--space-xxxl);
  }
  .gap-x-xxxl\@xl > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@xl {
    --gap-x: var(--space-xxxxl);
  }
  .gap-x-xxxxl\@xl > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@xl {
    --gap-x: 0;
  }
  .gap-x-0\@xl > * {
    --sub-gap-x: 0;
  }

  .gap-y-xxxxs\@xl {
    --gap-y: var(--space-xxxxs);
  }
  .gap-y-xxxxs\@xl > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@xl {
    --gap-y: var(--space-xxxs);
  }
  .gap-y-xxxs\@xl > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@xl {
    --gap-y: var(--space-xxs);
  }
  .gap-y-xxs\@xl > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@xl {
    --gap-y: var(--space-xs);
  }
  .gap-y-xs\@xl > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@xl {
    --gap-y: var(--space-sm);
  }
  .gap-y-sm\@xl > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@xl {
    --gap-y: var(--space-md);
  }
  .gap-y-md\@xl > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@xl {
    --gap-y: var(--space-lg);
  }
  .gap-y-lg\@xl > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@xl {
    --gap-y: var(--space-xl);
  }
  .gap-y-xl\@xl > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@xl {
    --gap-y: var(--space-xxl);
  }
  .gap-y-xxl\@xl > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@xl {
    --gap-y: var(--space-xxxl);
  }
  .gap-y-xxxl\@xl > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@xl {
    --gap-y: var(--space-xxxxl);
  }
  .gap-y-xxxxl\@xl > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@xl {
    --gap-y: 0;
  }
  .gap-y-0\@xl > * {
    --sub-gap-y: 0;
  }

  .grid-col-1\@xl {
    --grid-columns: 1;
  }

  .col-1\@xl {
    --span: 1;
  }

  .grid-col-2\@xl {
    --grid-columns: 2;
  }

  .col-2\@xl {
    --span: 2;
  }

  .grid-col-3\@xl {
    --grid-columns: 3;
  }

  .col-3\@xl {
    --span: 3;
  }

  .grid-col-4\@xl {
    --grid-columns: 4;
  }

  .col-4\@xl {
    --span: 4;
  }

  .grid-col-5\@xl {
    --grid-columns: 5;
  }

  .col-5\@xl {
    --span: 5;
  }

  .grid-col-6\@xl {
    --grid-columns: 6;
  }

  .col-6\@xl {
    --span: 6;
  }

  .grid-col-7\@xl {
    --grid-columns: 7;
  }

  .col-7\@xl {
    --span: 7;
  }

  .grid-col-8\@xl {
    --grid-columns: 8;
  }

  .col-8\@xl {
    --span: 8;
  }

  .grid-col-9\@xl {
    --grid-columns: 9;
  }

  .col-9\@xl {
    --span: 9;
  }

  .grid-col-10\@xl {
    --grid-columns: 10;
  }

  .col-10\@xl {
    --span: 10;
  }

  .grid-col-11\@xl {
    --grid-columns: 11;
  }

  .col-11\@xl {
    --span: 11;
  }

  .grid-col-12\@xl {
    --grid-columns: 12;
  }

  .col-12\@xl {
    --span: 12;
  }

  .col-1\@xl, .col-2\@xl, .col-3\@xl, .col-4\@xl, .col-5\@xl, .col-6\@xl, .col-7\@xl, .col-8\@xl, .col-9\@xl, .col-10\@xl, .col-11\@xl, .col-12\@xl {
    flex-basis: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns) + (var(--span) - 1) * var(--sub-gap-x));
  }

  .col\@xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-content\@xl {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@xl {
    --offset: 1;
  }

  .offset-2\@xl {
    --offset: 2;
  }

  .offset-3\@xl {
    --offset: 3;
  }

  .offset-4\@xl {
    --offset: 4;
  }

  .offset-5\@xl {
    --offset: 5;
  }

  .offset-6\@xl {
    --offset: 6;
  }

  .offset-7\@xl {
    --offset: 7;
  }

  .offset-8\@xl {
    --offset: 8;
  }

  .offset-9\@xl {
    --offset: 9;
  }

  .offset-10\@xl {
    --offset: 10;
  }

  .offset-11\@xl {
    --offset: 11;
  }

  .offset-1\@xl, .offset-2\@xl, .offset-3\@xl, .offset-4\@xl, .offset-5\@xl, .offset-6\@xl, .offset-7\@xl, .offset-8\@xl, .offset-9\@xl, .offset-10\@xl, .offset-11\@xl {
    margin-left: calc((100% - (var(--grid-columns) - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns) + (var(--offset) + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@xl {
    margin-left: 0;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports not (translate: none) {
      .offset-0\@xl {
        margin-left: var(--gap-x);
      }
    }
  }
}
*, *::after, *::before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--color-bg, white);
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul, menu {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

button, input, textarea, select {
  margin: 0;
}

.btn, .form-control, .link, .reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

select.form-control::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  max-width: 100%;
}

[data-theme] {
  background-color: var(--color-bg, white);
  color: var(--color-contrast-high, #323639);
}

:root {
  --space-unit: 1rem;
}

:root, * {
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}

:root {
  --radius-sm: calc(var(--radius, 0.25em)/2);
  --radius-md: var(--radius, 0.25em);
  --radius-lg: calc(var(--radius, 0.25em)*2);
  --shadow-xs: 0 0.1px 0.3px rgba(0, 0, 0, 0.06),
                0 1px 2px rgba(0, 0, 0, 0.12);
  --shadow-sm: 0 0.3px 0.4px rgba(0, 0, 0, 0.025),
                0 0.9px 1.5px rgba(0, 0, 0, 0.05),
                0 3.5px 6px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 0.9px 1.5px rgba(0, 0, 0, 0.03),
                0 3.1px 5.5px rgba(0, 0, 0, 0.08),
                0 14px 25px rgba(0, 0, 0, 0.12);
  --shadow-lg: 0 1.2px 1.9px -1px rgba(0, 0, 0, 0.014),
                0 3.3px 5.3px -1px rgba(0, 0, 0, 0.038),
                0 8.5px 12.7px -1px rgba(0, 0, 0, 0.085),
                0 30px 42px -1px rgba(0, 0, 0, 0.15);
  --shadow-xl: 0 1.5px 2.1px -6px rgba(0, 0, 0, 0.012),
                0 3.6px 5.2px -6px rgba(0, 0, 0, 0.035),
                0 7.3px 10.6px -6px rgba(0, 0, 0, 0.07),
                0 16.2px 21.9px -6px rgba(0, 0, 0, 0.117),
                0 46px 60px -6px rgba(0, 0, 0, 0.2);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
}

:root {
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-size: var(--text-base-size, 1rem);
  font-family: var(--font-primary, sans-serif);
  color: var(--color-contrast-high, #323639);
  font-weight: var(--body-font-weight, normal);
}

h1, h2, h3, h4 {
  color: var(--color-contrast-higher, #0d1317);
  line-height: var(--heading-line-height, 1.2);
  font-weight: var(--heading-font-weight, 700);
}

h1 {
  font-size: var(--text-xxl, 2rem);
}

h2 {
  font-size: var(--text-xl, 1.75rem);
}

h3 {
  font-size: var(--text-lg, 1.375rem);
}

h4 {
  font-size: var(--text-md, 1.125rem);
}

small {
  font-size: var(--text-sm, 0.75rem);
}

a, .link {
  color: var(--color-primary, #4827ec);
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

s {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

.text-component h1, .text-component h2, .text-component h3, .text-component h4 {
  line-height: calc(var(--heading-line-height) * var(--line-height-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * 0.3125 * var(--text-space-y-multiplier, 1));
}
.text-component h2, .text-component h3, .text-component h4 {
  margin-top: calc(var(--space-unit) * 0.9375 * var(--text-space-y-multiplier, 1));
}
.text-component p, .text-component blockquote, .text-component ul li, .text-component ol li {
  line-height: calc(var(--body-line-height) * var(--line-height-multiplier, 1));
}
.text-component ul, .text-component ol, .text-component p, .text-component blockquote, .text-component .text-component__block {
  margin-bottom: calc(var(--space-unit) * 0.9375 * var(--text-space-y-multiplier, 1));
}
.text-component ul, .text-component ol {
  list-style-position: inside;
}
.text-component ul ul, .text-component ul ol, .text-component ol ul, .text-component ol ol {
  padding-left: 1em;
  margin-bottom: 0;
}
.text-component ul {
  list-style-type: disc;
}
.text-component ol {
  list-style-type: decimal;
}
.text-component img {
  display: block;
  margin: 0 auto;
}
.text-component figcaption {
  text-align: center;
  margin-top: calc(var(--space-unit) * 0.5);
}
.text-component em {
  font-style: italic;
}
.text-component hr {
  margin-top: calc(var(--space-unit) * 1.875 * var(--text-space-y-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * 1.875 * var(--text-space-y-multiplier, 1));
  margin-left: auto;
  margin-right: auto;
}
.text-component > *:first-child {
  margin-top: 0;
}
.text-component > *:last-child {
  margin-bottom: 0;
}

.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__block--left,
.text-component__block--right {
    width: 45%;
  }
  .text-component__block--left img,
.text-component__block--right img {
    width: 100%;
  }

  .text-component__block--left {
    float: left;
    margin-right: calc(var(--space-unit) * 0.9375 * var(--text-space-y-multiplier, 1));
  }

  .text-component__block--right {
    float: right;
    margin-left: calc(var(--space-unit) * 0.9375 * var(--text-space-y-multiplier, 1));
  }
}
@media (min-width: 90rem) {
  .text-component__block--outset {
    width: calc(100% + 10.5 * var(--space-unit));
  }
  .text-component__block--outset img {
    width: 100%;
  }

  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: calc(-5.25 * var(--space-unit));
  }

  .text-component__block--left, .text-component__block--right {
    width: 50%;
  }

  .text-component__block--right.text-component__block--outset {
    margin-right: calc(-5.25 * var(--space-unit));
  }
}
:root {
  --icon-xxxs: 8px;
  --icon-xxs: 12px;
  --icon-xs: 16px;
  --icon-sm: 24px;
  --icon-md: 32px;
  --icon-lg: 48px;
  --icon-xl: 64px;
  --icon-xxl: 96px;
  --icon-xxxl: 128px;
}

.icon {
  --size: 1em;
  height: var(--size);
  width: var(--size);
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.icon--xxxs {
  --size: var(--icon-xxxs);
}

.icon--xxs {
  --size: var(--icon-xxs);
}

.icon--xs {
  --size: var(--icon-xs);
}

.icon--sm {
  --size: var(--icon-sm);
}

.icon--md {
  --size: var(--icon-md);
}

.icon--lg {
  --size: var(--icon-lg);
}

.icon--xl {
  --size: var(--icon-xl);
}

.icon--xxl {
  --size: var(--icon-xxl);
}

.icon--xxxl {
  --size: var(--icon-xxxl);
}

.icon--is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon use {
  color: inherit;
  fill: currentColor;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  font-size: var(--btn-font-size, 1em);
  padding-top: var(--btn-padding-y, 0.5em);
  padding-bottom: var(--btn-padding-y, 0.5em);
  padding-left: var(--btn-padding-x, 0.75em);
  padding-right: var(--btn-padding-x, 0.75em);
  border-radius: var(--btn-radius, 0.25em);
}

.btn--sm {
  font-size: var(--btn-font-size-sm, 0.8em);
}

.btn--md {
  font-size: var(--btn-font-size-md, 1.2em);
}

.btn--lg {
  font-size: var(--btn-font-size-lg, 1.4em);
}

.btn--icon {
  padding: var(--btn-padding-y, 0.5em);
}

.form-control {
  font-size: var(--form-control-font-size, 1em);
  padding-top: var(--form-control-padding-y, 0.5em);
  padding-bottom: var(--form-control-padding-y, 0.5em);
  padding-left: var(--form-control-padding-x, 0.75em);
  padding-right: var(--form-control-padding-x, 0.75em);
  border-radius: var(--form-control-radius, 0.25em);
}

.form-legend {
  color: var(--color-contrast-higher, #0d1317);
  line-height: var(--heading-line-height, 1.2);
  font-size: var(--text-md, 1.125rem);
  margin-bottom: var(--space-sm);
}

.form-label {
  display: inline-block;
  font-size: var(--text-sm, 0.75rem);
}

:root {
  --z-index-header: 3;
  --z-index-popover: 5;
  --z-index-fixed-element: 10;
  --z-index-overlay: 15;
}

:root {
  --display: block;
}

.is-visible {
  display: var(--display) !important;
}

.is-hidden {
  display: none !important;
}

html:not(.js) .no-js\:is-hidden {
  display: none !important;
}

@media print {
  .print\:is-hidden {
    display: none !important;
  }
}
.sr-only, .sr-only-focusable:not(:focus):not(:focus-within) {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

[class^=aspect-ratio], [class*=" aspect-ratio"] {
  --aspect-ratio: calc(16/9);
  position: relative;
  height: 0;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}
[class^=aspect-ratio] > *, [class*=" aspect-ratio"] > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
[class^=aspect-ratio] > *:not(iframe), [class*=" aspect-ratio"] > *:not(iframe) {
  object-fit: cover;
}

.aspect-ratio-16\:9 {
  --aspect-ratio: calc(16/9);
}

.aspect-ratio-3\:2 {
  --aspect-ratio: calc(3/2);
}

.aspect-ratio-4\:3 {
  --aspect-ratio: calc(4/3);
}

.aspect-ratio-5\:4 {
  --aspect-ratio: calc(5/4);
}

.aspect-ratio-1\:1 {
  --aspect-ratio: calc(1/1);
}

.aspect-ratio-4\:5 {
  --aspect-ratio: calc(4/5);
}

.aspect-ratio-3\:4 {
  --aspect-ratio: calc(3/4);
}

.aspect-ratio-2\:3 {
  --aspect-ratio: calc(2/3);
}

.aspect-ratio-9\:16 {
  --aspect-ratio: calc(9/16);
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.contents {
  display: contents;
}

.hide {
  display: none;
}

.space-unit-rem {
  --space-unit: 1rem;
}

.space-unit-em {
  --space-unit: 1em;
}

.space-unit-px {
  --space-unit: 16px;
}

.margin-xxxxs {
  margin: var(--space-xxxxs);
}

.margin-xxxs {
  margin: var(--space-xxxs);
}

.margin-xxs {
  margin: var(--space-xxs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-md {
  margin: var(--space-md);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-xxl {
  margin: var(--space-xxl);
}

.margin-xxxl {
  margin: var(--space-xxxl);
}

.margin-xxxxl {
  margin: var(--space-xxxxl);
}

.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0;
}

.margin-top-xxxxs {
  margin-top: var(--space-xxxxs);
}

.margin-top-xxxs {
  margin-top: var(--space-xxxs);
}

.margin-top-xxs {
  margin-top: var(--space-xxs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-top-xxl {
  margin-top: var(--space-xxl);
}

.margin-top-xxxl {
  margin-top: var(--space-xxxl);
}

.margin-top-xxxxl {
  margin-top: var(--space-xxxxl);
}

.margin-top-auto {
  margin-top: auto;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-xxxxs {
  margin-bottom: var(--space-xxxxs);
}

.margin-bottom-xxxs {
  margin-bottom: var(--space-xxxs);
}

.margin-bottom-xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom-xxxl {
  margin-bottom: var(--space-xxxl);
}

.margin-bottom-xxxxl {
  margin-bottom: var(--space-xxxxl);
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-right-xxxxs {
  margin-right: var(--space-xxxxs);
}

.margin-right-xxxs {
  margin-right: var(--space-xxxs);
}

.margin-right-xxs {
  margin-right: var(--space-xxs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-right-xxl {
  margin-right: var(--space-xxl);
}

.margin-right-xxxl {
  margin-right: var(--space-xxxl);
}

.margin-right-xxxxl {
  margin-right: var(--space-xxxxl);
}

.margin-right-auto {
  margin-right: auto;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-left-xxxxs {
  margin-left: var(--space-xxxxs);
}

.margin-left-xxxs {
  margin-left: var(--space-xxxs);
}

.margin-left-xxs {
  margin-left: var(--space-xxs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-left-xxl {
  margin-left: var(--space-xxl);
}

.margin-left-xxxl {
  margin-left: var(--space-xxxl);
}

.margin-left-xxxxl {
  margin-left: var(--space-xxxxl);
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-x-xxxxs {
  margin-left: var(--space-xxxxs);
  margin-right: var(--space-xxxxs);
}

.margin-x-xxxs {
  margin-left: var(--space-xxxs);
  margin-right: var(--space-xxxs);
}

.margin-x-xxs {
  margin-left: var(--space-xxs);
  margin-right: var(--space-xxs);
}

.margin-x-xs {
  margin-left: var(--space-xs);
  margin-right: var(--space-xs);
}

.margin-x-sm {
  margin-left: var(--space-sm);
  margin-right: var(--space-sm);
}

.margin-x-md {
  margin-left: var(--space-md);
  margin-right: var(--space-md);
}

.margin-x-lg {
  margin-left: var(--space-lg);
  margin-right: var(--space-lg);
}

.margin-x-xl {
  margin-left: var(--space-xl);
  margin-right: var(--space-xl);
}

.margin-x-xxl {
  margin-left: var(--space-xxl);
  margin-right: var(--space-xxl);
}

.margin-x-xxxl {
  margin-left: var(--space-xxxl);
  margin-right: var(--space-xxxl);
}

.margin-x-xxxxl {
  margin-left: var(--space-xxxxl);
  margin-right: var(--space-xxxxl);
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-y-xxxxs {
  margin-top: var(--space-xxxxs);
  margin-bottom: var(--space-xxxxs);
}

.margin-y-xxxs {
  margin-top: var(--space-xxxs);
  margin-bottom: var(--space-xxxs);
}

.margin-y-xxs {
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxs);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-y-xxl {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.margin-y-xxxl {
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxxl);
}

.margin-y-xxxxl {
  margin-top: var(--space-xxxxl);
  margin-bottom: var(--space-xxxxl);
}

.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.padding-xxxxs {
  padding: var(--space-xxxxs);
}

.padding-xxxs {
  padding: var(--space-xxxs);
}

.padding-xxs {
  padding: var(--space-xxs);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-md {
  padding: var(--space-md);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-xl {
  padding: var(--space-xl);
}

.padding-xxl {
  padding: var(--space-xxl);
}

.padding-xxxl {
  padding: var(--space-xxxl);
}

.padding-xxxxl {
  padding: var(--space-xxxxl);
}

.padding-0 {
  padding: 0;
}

.padding-component {
  padding: var(--component-padding);
}

.padding-top-xxxxs {
  padding-top: var(--space-xxxxs);
}

.padding-top-xxxs {
  padding-top: var(--space-xxxs);
}

.padding-top-xxs {
  padding-top: var(--space-xxs);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-top-xxl {
  padding-top: var(--space-xxl);
}

.padding-top-xxxl {
  padding-top: var(--space-xxxl);
}

.padding-top-xxxxl {
  padding-top: var(--space-xxxxl);
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-component {
  padding-top: var(--component-padding);
}

.padding-bottom-xxxxs {
  padding-bottom: var(--space-xxxxs);
}

.padding-bottom-xxxs {
  padding-bottom: var(--space-xxxs);
}

.padding-bottom-xxs {
  padding-bottom: var(--space-xxs);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-bottom-xxl {
  padding-bottom: var(--space-xxl);
}

.padding-bottom-xxxl {
  padding-bottom: var(--space-xxxl);
}

.padding-bottom-xxxxl {
  padding-bottom: var(--space-xxxxl);
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-component {
  padding-bottom: var(--component-padding);
}

.padding-right-xxxxs {
  padding-right: var(--space-xxxxs);
}

.padding-right-xxxs {
  padding-right: var(--space-xxxs);
}

.padding-right-xxs {
  padding-right: var(--space-xxs);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-right-xxl {
  padding-right: var(--space-xxl);
}

.padding-right-xxxl {
  padding-right: var(--space-xxxl);
}

.padding-right-xxxxl {
  padding-right: var(--space-xxxxl);
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-component {
  padding-right: var(--component-padding);
}

.padding-left-xxxxs {
  padding-left: var(--space-xxxxs);
}

.padding-left-xxxs {
  padding-left: var(--space-xxxs);
}

.padding-left-xxs {
  padding-left: var(--space-xxs);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-left-xxl {
  padding-left: var(--space-xxl);
}

.padding-left-xxxl {
  padding-left: var(--space-xxxl);
}

.padding-left-xxxxl {
  padding-left: var(--space-xxxxl);
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-component {
  padding-left: var(--component-padding);
}

.padding-x-xxxxs {
  padding-left: var(--space-xxxxs);
  padding-right: var(--space-xxxxs);
}

.padding-x-xxxs {
  padding-left: var(--space-xxxs);
  padding-right: var(--space-xxxs);
}

.padding-x-xxs {
  padding-left: var(--space-xxs);
  padding-right: var(--space-xxs);
}

.padding-x-xs {
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
}

.padding-x-sm {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
}

.padding-x-md {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}

.padding-x-lg {
  padding-left: var(--space-lg);
  padding-right: var(--space-lg);
}

.padding-x-xl {
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
}

.padding-x-xxl {
  padding-left: var(--space-xxl);
  padding-right: var(--space-xxl);
}

.padding-x-xxxl {
  padding-left: var(--space-xxxl);
  padding-right: var(--space-xxxl);
}

.padding-x-xxxxl {
  padding-left: var(--space-xxxxl);
  padding-right: var(--space-xxxxl);
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-x-component {
  padding-left: var(--component-padding);
  padding-right: var(--component-padding);
}

.padding-y-xxxxs {
  padding-top: var(--space-xxxxs);
  padding-bottom: var(--space-xxxxs);
}

.padding-y-xxxs {
  padding-top: var(--space-xxxs);
  padding-bottom: var(--space-xxxs);
}

.padding-y-xxs {
  padding-top: var(--space-xxs);
  padding-bottom: var(--space-xxs);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-y-xxl {
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.padding-y-xxxl {
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxxl);
}

.padding-y-xxxxl {
  padding-top: var(--space-xxxxl);
  padding-bottom: var(--space-xxxxl);
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-y-component {
  padding-top: var(--component-padding);
  padding-bottom: var(--component-padding);
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.truncate, .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}

.break-word {
  overflow-wrap: break-word;
  min-width: 0;
}

.text-unit-rem, .text-unit-em, .text-unit-px {
  font-size: var(--text-unit);
}

.text-unit-rem {
  --text-unit: 1rem;
}

.text-unit-em {
  --text-unit: 1em;
}

.text-unit-px {
  --text-unit: 16px;
}

.text-xs {
  font-size: var(--text-xs, 0.6875rem);
}

.text-sm {
  font-size: var(--text-sm, 0.75rem);
}

.text-base {
  font-size: var(--text-unit, 1rem);
}

.text-md {
  font-size: var(--text-md, 1.125rem);
}

.text-lg {
  font-size: var(--text-lg, 1.375rem);
}

.text-xl {
  font-size: var(--text-xl, 1.75rem);
}

.text-xxl {
  font-size: var(--text-xxl, 2rem);
}

.text-xxxl {
  font-size: var(--text-xxxl, 2.5rem);
}

.text-xxxxl {
  font-size: var(--text-xxxxl, 3rem);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.letter-spacing-xs {
  letter-spacing: -0.1em;
}

.letter-spacing-sm {
  letter-spacing: -0.05em;
}

.letter-spacing-md {
  letter-spacing: 0.05em;
}

.letter-spacing-lg {
  letter-spacing: 0.1em;
}

.letter-spacing-xl {
  letter-spacing: 0.2em;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold, .text-bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--font-primary);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.text-shadow-xs {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.text-shadow-sm {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.text-shadow-md {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
}

.text-shadow-lg {
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.15), 0 4px 16px rgba(0, 0, 0, 0.2);
}

.text-shadow-xl {
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.15), 0 4px 16px rgba(0, 0, 0, 0.2), 0 6px 24px rgba(0, 0, 0, 0.25);
}

.text-shadow-none {
  text-shadow: none;
}

.text-space-y-xxs {
  --text-space-y-multiplier: 0.25 !important;
}

.text-space-y-xs {
  --text-space-y-multiplier: 0.5 !important;
}

.text-space-y-sm {
  --text-space-y-multiplier: 0.75 !important;
}

.text-space-y-md {
  --text-space-y-multiplier: 1.25 !important;
}

.text-space-y-lg {
  --text-space-y-multiplier: 1.5 !important;
}

.text-space-y-xl {
  --text-space-y-multiplier: 1.75 !important;
}

.text-space-y-xxl {
  --text-space-y-multiplier: 2 !important;
}

.line-height-xs {
  --heading-line-height: 1;
  --body-line-height: 1.1;
}
.line-height-xs:not(.text-component) {
  line-height: 1.1;
}

.line-height-sm {
  --heading-line-height: 1.1;
  --body-line-height: 1.2;
}
.line-height-sm:not(.text-component) {
  line-height: 1.2;
}

.line-height-md {
  --heading-line-height: 1.15;
  --body-line-height: 1.4;
}
.line-height-md:not(.text-component) {
  line-height: 1.4;
}

.line-height-lg {
  --heading-line-height: 1.22;
  --body-line-height: 1.58;
}
.line-height-lg:not(.text-component) {
  line-height: 1.58;
}

.line-height-xl {
  --heading-line-height: 1.3;
  --body-line-height: 1.72;
}
.line-height-xl:not(.text-component) {
  line-height: 1.72;
}

.line-height-body {
  line-height: var(--body-line-height);
}

.line-height-heading {
  line-height: var(--heading-line-height);
}

.line-height-normal {
  line-height: normal !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.ws-nowrap, .text-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.user-select-none {
  user-select: none;
}

.user-select-all {
  user-select: all;
}

[class^=color-], [class*=" color-"] {
  --color-o: 1;
}

.color-inherit {
  color: inherit;
}

.color-bg-darker {
  color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--color-o, 1));
}

.color-bg-dark {
  color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--color-o, 1));
}

.color-bg {
  color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--color-o, 1));
}

.color-bg-light {
  color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--color-o, 1));
}

.color-bg-lighter {
  color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--color-o, 1));
}

.color-contrast-lower {
  color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--color-o, 1));
}

.color-contrast-low {
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--color-o, 1));
}

.color-contrast-medium {
  color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--color-o, 1));
}

.color-contrast-high {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--color-o, 1));
}

.color-contrast-higher {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--color-o, 1));
}

.color-primary-darker {
  color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--color-o, 1));
}

.color-primary-dark {
  color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--color-o, 1));
}

.color-primary {
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--color-o, 1));
}

.color-primary-light {
  color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--color-o, 1));
}

.color-primary-lighter {
  color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--color-o, 1));
}

.color-accent-darker {
  color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--color-o, 1));
}

.color-accent-dark {
  color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--color-o, 1));
}

.color-accent {
  color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--color-o, 1));
}

.color-accent-light {
  color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--color-o, 1));
}

.color-accent-lighter {
  color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--color-o, 1));
}

.color-success-darker {
  color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--color-o, 1));
}

.color-success-dark {
  color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--color-o, 1));
}

.color-success {
  color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--color-o, 1));
}

.color-success-light {
  color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--color-o, 1));
}

.color-success-lighter {
  color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--color-o, 1));
}

.color-warning-darker {
  color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--color-o, 1));
}

.color-warning-dark {
  color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--color-o, 1));
}

.color-warning {
  color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--color-o, 1));
}

.color-warning-light {
  color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--color-o, 1));
}

.color-warning-lighter {
  color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--color-o, 1));
}

.color-error-darker {
  color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--color-o, 1));
}

.color-error-dark {
  color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--color-o, 1));
}

.color-error {
  color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--color-o, 1));
}

.color-error-light {
  color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--color-o, 1));
}

.color-error-lighter {
  color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--color-o, 1));
}

.color-white {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-o, 1));
}

.color-black {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--color-o, 1));
}

.color-opacity-0 {
  --color-o: 0;
}

.color-opacity-10\% {
  --color-o: 0.1;
}

.color-opacity-20\% {
  --color-o: 0.2;
}

.color-opacity-30\% {
  --color-o: 0.3;
}

.color-opacity-40\% {
  --color-o: 0.4;
}

.color-opacity-50\% {
  --color-o: 0.5;
}

.color-opacity-60\% {
  --color-o: 0.6;
}

.color-opacity-70\% {
  --color-o: 0.7;
}

.color-opacity-80\% {
  --color-o: 0.8;
}

.color-opacity-90\% {
  --color-o: 0.9;
}

[class^=color-gradient], [class*=" color-gradient"] {
  color: transparent !important;
  background-clip: text;
}

.width-xxxxs {
  width: var(--size-xxxxs, 0.25rem);
}

.width-xxxs {
  width: var(--size-xxxs, 0.5rem);
}

.width-xxs {
  width: var(--size-xxs, 0.75rem);
}

.width-xs {
  width: var(--size-xs, 1rem);
}

.width-sm {
  width: var(--size-sm, 1.5rem);
}

.width-md {
  width: var(--size-md, 2rem);
}

.width-lg {
  width: var(--size-lg, 3rem);
}

.width-xl {
  width: var(--size-xl, 4rem);
}

.width-xxl {
  width: var(--size-xxl, 6rem);
}

.width-xxxl {
  width: var(--size-xxxl, 8rem);
}

.width-xxxxl {
  width: var(--size-xxxxl, 16rem);
}

.width-0 {
  width: 0;
}

.width-10\% {
  width: 10%;
}

.width-20\% {
  width: 20%;
}

.width-25\% {
  width: 25%;
}

.width-30\% {
  width: 30%;
}

.width-33\% {
  width: 33.3333333333%;
}

.width-40\% {
  width: 40%;
}

.width-50\% {
  width: 50%;
}

.width-60\% {
  width: 60%;
}

.width-66\% {
  width: 66.6666666667%;
}

.width-70\% {
  width: 70%;
}

.width-75\% {
  width: 75%;
}

.width-80\% {
  width: 80%;
}

.width-90\% {
  width: 90%;
}

.width-100\% {
  width: 100%;
}

.width-100vw {
  width: 100vw;
}

.width-auto {
  width: auto;
}

.height-xxxxs {
  height: var(--size-xxxxs, 0.25rem);
}

.height-xxxs {
  height: var(--size-xxxs, 0.5rem);
}

.height-xxs {
  height: var(--size-xxs, 0.75rem);
}

.height-xs {
  height: var(--size-xs, 1rem);
}

.height-sm {
  height: var(--size-sm, 1.5rem);
}

.height-md {
  height: var(--size-md, 2rem);
}

.height-lg {
  height: var(--size-lg, 3rem);
}

.height-xl {
  height: var(--size-xl, 4rem);
}

.height-xxl {
  height: var(--size-xxl, 6rem);
}

.height-xxxl {
  height: var(--size-xxxl, 8rem);
}

.height-xxxxl {
  height: var(--size-xxxxl, 16rem);
}

.height-0 {
  height: 0;
}

.height-10\% {
  height: 10%;
}

.height-20\% {
  height: 20%;
}

.height-25\% {
  height: 25%;
}

.height-30\% {
  height: 30%;
}

.height-33\% {
  height: 33.3333333333%;
}

.height-40\% {
  height: 40%;
}

.height-50\% {
  height: 50%;
}

.height-60\% {
  height: 60%;
}

.height-66\% {
  height: 66.6666666667%;
}

.height-70\% {
  height: 70%;
}

.height-75\% {
  height: 75%;
}

.height-80\% {
  height: 80%;
}

.height-90\% {
  height: 90%;
}

.height-100\% {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.min-width-0 {
  min-width: 0;
}

.min-width-25\% {
  min-width: 25%;
}

.min-width-33\% {
  min-width: 33.3333333333%;
}

.min-width-50\% {
  min-width: 50%;
}

.min-width-66\% {
  min-width: 66.6666666667%;
}

.min-width-75\% {
  min-width: 75%;
}

.min-width-100\% {
  min-width: 100%;
}

.min-width-100vw {
  min-width: 100vw;
}

.min-height-100\% {
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

:root {
  --max-width-xxxxs: 20rem;
  --max-width-xxxs: 26rem;
  --max-width-xxs: 32rem;
  --max-width-xs: 38rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --max-width-xxl: 100rem;
  --max-width-xxxl: 120rem;
  --max-width-xxxxl: 150rem;
}

.max-width-xxxxs {
  max-width: var(--max-width-xxxxs);
}

.max-width-xxxs {
  max-width: var(--max-width-xxxs);
}

.max-width-xxs {
  max-width: var(--max-width-xxs);
}

.max-width-xs {
  max-width: var(--max-width-xs);
}

.max-width-sm {
  max-width: var(--max-width-sm);
}

.max-width-md {
  max-width: var(--max-width-md);
}

.max-width-lg {
  max-width: var(--max-width-lg);
}

.max-width-xl {
  max-width: var(--max-width-xl);
}

.max-width-xxl {
  max-width: var(--max-width-xxl);
}

.max-width-xxxl {
  max-width: var(--max-width-xxxl);
}

.max-width-xxxxl {
  max-width: var(--max-width-xxxxl);
}

.max-width-100\% {
  max-width: 100%;
}

.max-width-none {
  max-width: none;
}

[class^=max-width-adaptive], [class*=" max-width-adaptive"] {
  max-width: 32rem;
}

@media (min-width: 48rem) {
  .max-width-adaptive-sm, .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 48rem;
  }
}
@media (min-width: 64rem) {
  .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 64rem;
  }
}
@media (min-width: 80rem) {
  .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 80rem;
  }
}
@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
  }
}
.max-height-100\% {
  max-height: 100%;
}

.max-height-100vh {
  max-height: 100vh;
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.shadow-none {
  box-shadow: none;
}

:root {
  --inner-glow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075);
  --inner-glow-top: inset 0 1px 0.5px hsla(0, 0%, 100%, 0.075);
}

.inner-glow, .inner-glow-top {
  position: relative;
}
.inner-glow::after, .inner-glow-top::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  pointer-events: none;
}

.inner-glow::after {
  box-shadow: var(--inner-glow);
}

.inner-glow-top::after {
  box-shadow: var(--inner-glow-top);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-0 {
  top: 0;
}

.top-50\% {
  top: 50%;
}

.top-xxxxs {
  top: var(--space-xxxxs);
}

.top-xxxs {
  top: var(--space-xxxs);
}

.top-xxs {
  top: var(--space-xxs);
}

.top-xs {
  top: var(--space-xs);
}

.top-sm {
  top: var(--space-sm);
}

.top-md {
  top: var(--space-md);
}

.top-lg {
  top: var(--space-lg);
}

.top-xl {
  top: var(--space-xl);
}

.top-xxl {
  top: var(--space-xxl);
}

.top-xxxl {
  top: var(--space-xxxl);
}

.top-xxxxl {
  top: var(--space-xxxxl);
}

.bottom-0 {
  bottom: 0;
}

.bottom-50\% {
  bottom: 50%;
}

.bottom-xxxxs {
  bottom: var(--space-xxxxs);
}

.bottom-xxxs {
  bottom: var(--space-xxxs);
}

.bottom-xxs {
  bottom: var(--space-xxs);
}

.bottom-xs {
  bottom: var(--space-xs);
}

.bottom-sm {
  bottom: var(--space-sm);
}

.bottom-md {
  bottom: var(--space-md);
}

.bottom-lg {
  bottom: var(--space-lg);
}

.bottom-xl {
  bottom: var(--space-xl);
}

.bottom-xxl {
  bottom: var(--space-xxl);
}

.bottom-xxxl {
  bottom: var(--space-xxxl);
}

.bottom-xxxxl {
  bottom: var(--space-xxxxl);
}

.right-0 {
  right: 0;
}

.right-50\% {
  right: 50%;
}

.right-xxxxs {
  right: var(--space-xxxxs);
}

.right-xxxs {
  right: var(--space-xxxs);
}

.right-xxs {
  right: var(--space-xxs);
}

.right-xs {
  right: var(--space-xs);
}

.right-sm {
  right: var(--space-sm);
}

.right-md {
  right: var(--space-md);
}

.right-lg {
  right: var(--space-lg);
}

.right-xl {
  right: var(--space-xl);
}

.right-xxl {
  right: var(--space-xxl);
}

.right-xxxl {
  right: var(--space-xxxl);
}

.right-xxxxl {
  right: var(--space-xxxxl);
}

.left-0 {
  left: 0;
}

.left-50\% {
  left: 50%;
}

.left-xxxxs {
  left: var(--space-xxxxs);
}

.left-xxxs {
  left: var(--space-xxxs);
}

.left-xxs {
  left: var(--space-xxs);
}

.left-xs {
  left: var(--space-xs);
}

.left-sm {
  left: var(--space-sm);
}

.left-md {
  left: var(--space-md);
}

.left-lg {
  left: var(--space-lg);
}

.left-xl {
  left: var(--space-xl);
}

.left-xxl {
  left: var(--space-xxl);
}

.left-xxxl {
  left: var(--space-xxxl);
}

.left-xxxxl {
  left: var(--space-xxxxl);
}

.z-index-header {
  z-index: var(--z-index-header);
}

.z-index-popover {
  z-index: var(--z-index-popover);
}

.z-index-fixed-element {
  z-index: var(--z-index-fixed-element);
}

.z-index-overlay {
  z-index: var(--z-index-overlay);
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.momentum-scrolling {
  -webkit-overflow-scrolling: touch;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.scroll-padding-xxxxs {
  scroll-padding: var(--space-xxxxs);
}

.scroll-padding-xxxs {
  scroll-padding: var(--space-xxxs);
}

.scroll-padding-xxs {
  scroll-padding: var(--space-xxs);
}

.scroll-padding-xs {
  scroll-padding: var(--space-xs);
}

.scroll-padding-sm {
  scroll-padding: var(--space-sm);
}

.scroll-padding-md {
  scroll-padding: var(--space-md);
}

.scroll-padding-lg {
  scroll-padding: var(--space-lg);
}

.scroll-padding-xl {
  scroll-padding: var(--space-xl);
}

.scroll-padding-xxl {
  scroll-padding: var(--space-xxl);
}

.scroll-padding-xxxl {
  scroll-padding: var(--space-xxxl);
}

.scroll-padding-xxxxl {
  scroll-padding: var(--space-xxxxl);
}

.opacity-0 {
  opacity: 0;
}

.opacity-10\% {
  opacity: 0.1;
}

.opacity-20\% {
  opacity: 0.2;
}

.opacity-30\% {
  opacity: 0.3;
}

.opacity-40\% {
  opacity: 0.4;
}

.opacity-50\% {
  opacity: 0.5;
}

.opacity-60\% {
  opacity: 0.6;
}

.opacity-70\% {
  opacity: 0.7;
}

.opacity-80\% {
  opacity: 0.8;
}

.opacity-90\% {
  opacity: 0.9;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

[class^=border-], [class*=" border-"] {
  --border-o: 1;
}

.border {
  border: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-top {
  border-top: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-bottom {
  border-bottom: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-left {
  border-left: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-right {
  border-right: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-2 {
  --border-width: 2px;
}

.border-3 {
  --border-width: 3px;
}

.border-4 {
  --border-width: 4px;
}

.border-dotted {
  --border-style: dotted;
}

.border-dashed {
  --border-style: dashed;
}

.border-bg-darker {
  border-color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--border-o, 1));
}

.border-bg-dark {
  border-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--border-o, 1));
}

.border-bg {
  border-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--border-o, 1));
}

.border-bg-light {
  border-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--border-o, 1));
}

.border-bg-lighter {
  border-color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--border-o, 1));
}

.border-contrast-lower {
  border-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-contrast-low {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-contrast-medium {
  border-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--border-o, 1));
}

.border-contrast-high {
  border-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--border-o, 1));
}

.border-contrast-higher {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o, 1));
}

.border-primary-darker {
  border-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--border-o, 1));
}

.border-primary-dark {
  border-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--border-o, 1));
}

.border-primary {
  border-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--border-o, 1));
}

.border-primary-light {
  border-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--border-o, 1));
}

.border-primary-lighter {
  border-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--border-o, 1));
}

.border-accent-darker {
  border-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--border-o, 1));
}

.border-accent-dark {
  border-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--border-o, 1));
}

.border-accent {
  border-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--border-o, 1));
}

.border-accent-light {
  border-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--border-o, 1));
}

.border-accent-lighter {
  border-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--border-o, 1));
}

.border-success-darker {
  border-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--border-o, 1));
}

.border-success-dark {
  border-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--border-o, 1));
}

.border-success {
  border-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--border-o, 1));
}

.border-success-light {
  border-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--border-o, 1));
}

.border-success-lighter {
  border-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--border-o, 1));
}

.border-warning-darker {
  border-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--border-o, 1));
}

.border-warning-dark {
  border-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--border-o, 1));
}

.border-warning {
  border-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--border-o, 1));
}

.border-warning-light {
  border-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--border-o, 1));
}

.border-warning-lighter {
  border-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--border-o, 1));
}

.border-error-darker {
  border-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--border-o, 1));
}

.border-error-dark {
  border-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--border-o, 1));
}

.border-error {
  border-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--border-o, 1));
}

.border-error-light {
  border-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--border-o, 1));
}

.border-error-lighter {
  border-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--border-o, 1));
}

.border-white {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--border-o, 1));
}

.border-black {
  border-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--border-o, 1));
}

.border-opacity-0 {
  --border-o: 0;
}

.border-opacity-10\% {
  --border-o: 0.1;
}

.border-opacity-20\% {
  --border-o: 0.2;
}

.border-opacity-30\% {
  --border-o: 0.3;
}

.border-opacity-40\% {
  --border-o: 0.4;
}

.border-opacity-50\% {
  --border-o: 0.5;
}

.border-opacity-60\% {
  --border-o: 0.6;
}

.border-opacity-70\% {
  --border-o: 0.7;
}

.border-opacity-80\% {
  --border-o: 0.8;
}

.border-opacity-90\% {
  --border-o: 0.9;
}

.radius-sm {
  border-radius: var(--radius-sm);
}

.radius-md {
  border-radius: var(--radius-md);
}

.radius-lg {
  border-radius: var(--radius-lg);
}

.radius-50\% {
  border-radius: 50%;
}

.radius-full {
  border-radius: 50em;
}

.radius-0 {
  border-radius: 0;
}

.radius-inherit {
  border-radius: inherit;
}

.radius-top-left-0 {
  border-top-left-radius: 0;
}

.radius-top-right-0 {
  border-top-right-radius: 0;
}

.radius-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.radius-bottom-left-0 {
  border-bottom-left-radius: 0;
}

.bg, [class^=bg-], [class*=" bg-"] {
  --bg-o: 1;
}

.bg-transparent {
  background-color: transparent;
}

.bg-inherit {
  background-color: inherit;
}

.bg-darker {
  background-color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--bg-o));
}

.bg-dark {
  background-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--bg-o));
}

.bg {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--bg-o));
}

.bg-light {
  background-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--bg-o));
}

.bg-lighter {
  background-color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--bg-o));
}

.bg-contrast-lower {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--bg-o, 1));
}

.bg-contrast-low {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--bg-o, 1));
}

.bg-contrast-medium {
  background-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--bg-o, 1));
}

.bg-contrast-high {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--bg-o, 1));
}

.bg-contrast-higher {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--bg-o, 1));
}

.bg-primary-darker {
  background-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--bg-o, 1));
}

.bg-primary-dark {
  background-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--bg-o, 1));
}

.bg-primary {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--bg-o, 1));
}

.bg-primary-light {
  background-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--bg-o, 1));
}

.bg-primary-lighter {
  background-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--bg-o, 1));
}

.bg-accent-darker {
  background-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--bg-o, 1));
}

.bg-accent-dark {
  background-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--bg-o, 1));
}

.bg-accent {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--bg-o, 1));
}

.bg-accent-light {
  background-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--bg-o, 1));
}

.bg-accent-lighter {
  background-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--bg-o, 1));
}

.bg-success-darker {
  background-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--bg-o, 1));
}

.bg-success-dark {
  background-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--bg-o, 1));
}

.bg-success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--bg-o, 1));
}

.bg-success-light {
  background-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--bg-o, 1));
}

.bg-success-lighter {
  background-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--bg-o, 1));
}

.bg-warning-darker {
  background-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--bg-o, 1));
}

.bg-warning-dark {
  background-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--bg-o, 1));
}

.bg-warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--bg-o, 1));
}

.bg-warning-light {
  background-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--bg-o, 1));
}

.bg-warning-lighter {
  background-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--bg-o, 1));
}

.bg-error-darker {
  background-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--bg-o, 1));
}

.bg-error-dark {
  background-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--bg-o, 1));
}

.bg-error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--bg-o, 1));
}

.bg-error-light {
  background-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--bg-o, 1));
}

.bg-error-lighter {
  background-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--bg-o, 1));
}

.bg-white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--bg-o, 1));
}

.bg-black {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--bg-o, 1));
}

.bg-opacity-0 {
  --bg-o: 0;
}

.bg-opacity-10\% {
  --bg-o: 0.1;
}

.bg-opacity-20\% {
  --bg-o: 0.2;
}

.bg-opacity-30\% {
  --bg-o: 0.3;
}

.bg-opacity-40\% {
  --bg-o: 0.4;
}

.bg-opacity-50\% {
  --bg-o: 0.5;
}

.bg-opacity-60\% {
  --bg-o: 0.6;
}

.bg-opacity-70\% {
  --bg-o: 0.7;
}

.bg-opacity-80\% {
  --bg-o: 0.8;
}

.bg-opacity-90\% {
  --bg-o: 0.9;
}

.bg-center {
  background-position: center;
}

.bg-top {
  background-position: center top;
}

.bg-right {
  background-position: right center;
}

.bg-bottom {
  background-position: center bottom;
}

.bg-left {
  background-position: left center;
}

.bg-top-left {
  background-position: left top;
}

.bg-top-right {
  background-position: right top;
}

.bg-bottom-left {
  background-position: left bottom;
}

.bg-bottom-right {
  background-position: right bottom;
}

.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.backdrop-blur-10 {
  backdrop-filter: blur(10px);
}

.backdrop-blur-20 {
  backdrop-filter: blur(20px);
}

.isolate {
  isolation: isolate;
}

.blend-multiply {
  mix-blend-mode: multiply;
}

.blend-overlay {
  mix-blend-mode: overlay;
}

.blend-difference {
  mix-blend-mode: difference;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.perspective-xs {
  perspective: 250px;
}

.perspective-sm {
  perspective: 500px;
}

.perspective-md {
  perspective: 1000px;
}

.perspective-lg {
  perspective: 1500px;
}

.perspective-xl {
  perspective: 3000px;
}

[class^=flip], [class*=" flip"],
[class^=-rotate], [class*=" -rotate"],
[class^=rotate], [class*=" rotate"],
[class^=-translate], [class*=" -translate"],
[class^=translate], [class*=" translate"],
[class^=-scale], [class*=" -scale"],
[class^=scale], [class*=" scale"],
[class^=-skew], [class*=" -skew"] [class^=skew],
[class*=" skew"] {
  --translate: 0;
  --rotate: 0;
  --skew: 0;
  --scale: 1;
  transform: translate3d(var(--translate-x, var(--translate)), var(--translate-y, var(--translate)), var(--translate-z, 0)) rotateX(var(--rotate-x, 0)) rotateY(var(--rotate-y, 0)) rotateZ(var(--rotate-z, var(--rotate))) skewX(var(--skew-x, var(--skew))) skewY(var(--skew-y, 0)) scaleX(var(--scale-x, var(--scale))) scaleY(var(--scale-y, var(--scale)));
}

.flip {
  --scale: -1;
}

.flip-x {
  --scale-x: -1;
}

.flip-y {
  --scale-y: -1;
}

.rotate-90 {
  --rotate: 90deg;
}

.rotate-180 {
  --rotate: 180deg;
}

.rotate-270 {
  --rotate: 270deg;
}

.-translate-50\% {
  --translate: -50%;
}

.-translate-x-50\% {
  --translate-x: -50%;
}

.-translate-y-50\% {
  --translate-y: -50%;
}

.translate-50\% {
  --translate: 50%;
}

.translate-x-50\% {
  --translate-x: 50%;
}

.translate-y-50\% {
  --translate-y: 50%;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: center top;
}

.origin-right {
  transform-origin: right center;
}

.origin-bottom {
  transform-origin: center bottom;
}

.origin-left {
  transform-origin: left center;
}

.origin-top-left {
  transform-origin: left top;
}

.origin-top-right {
  transform-origin: right top;
}

.origin-bottom-left {
  transform-origin: left bottom;
}

.origin-bottom-right {
  transform-origin: right bottom;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-2 {
  stroke-width: 2px;
}

.stroke-3 {
  stroke-width: 3px;
}

.stroke-4 {
  stroke-width: 4px;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 32rem) {
  .flex\@xs {
    display: flex;
  }

  .inline-flex\@xs {
    display: inline-flex;
  }

  .flex-wrap\@xs {
    flex-wrap: wrap;
  }

  .flex-nowrap\@xs {
    flex-wrap: nowrap;
  }

  .flex-column\@xs {
    flex-direction: column;
  }

  .flex-column-reverse\@xs {
    flex-direction: column-reverse;
  }

  .flex-row\@xs {
    flex-direction: row;
  }

  .flex-row-reverse\@xs {
    flex-direction: row-reverse;
  }

  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@xs {
    flex-grow: 1;
  }

  .flex-grow-0\@xs {
    flex-grow: 0;
  }

  .flex-shrink\@xs {
    flex-shrink: 1;
  }

  .flex-shrink-0\@xs {
    flex-shrink: 0;
  }

  .flex-basis-0\@xs {
    flex-basis: 0;
  }

  .justify-start\@xs {
    justify-content: flex-start;
  }

  .justify-end\@xs {
    justify-content: flex-end;
  }

  .justify-center\@xs {
    justify-content: center;
  }

  .justify-between\@xs {
    justify-content: space-between;
  }

  .items-center\@xs {
    align-items: center;
  }

  .items-start\@xs {
    align-items: flex-start;
  }

  .items-end\@xs {
    align-items: flex-end;
  }

  .items-baseline\@xs {
    align-items: baseline;
  }

  .order-1\@xs {
    order: 1;
  }

  .order-2\@xs {
    order: 2;
  }

  .order-3\@xs {
    order: 3;
  }

  .block\@xs {
    display: block;
  }

  .inline-block\@xs {
    display: inline-block;
  }

  .inline\@xs {
    display: inline;
  }

  .contents\@xs {
    display: contents;
  }

  .hide\@xs {
    display: none !important;
  }

  .margin-xxxxs\@xs {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@xs {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@xs {
    margin: var(--space-xxs);
  }

  .margin-xs\@xs {
    margin: var(--space-xs);
  }

  .margin-sm\@xs {
    margin: var(--space-sm);
  }

  .margin-md\@xs {
    margin: var(--space-md);
  }

  .margin-lg\@xs {
    margin: var(--space-lg);
  }

  .margin-xl\@xs {
    margin: var(--space-xl);
  }

  .margin-xxl\@xs {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@xs {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@xs {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@xs {
    margin: auto;
  }

  .margin-0\@xs {
    margin: 0;
  }

  .margin-top-xxxxs\@xs {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@xs {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@xs {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@xs {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@xs {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@xs {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@xs {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@xs {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@xs {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@xs {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@xs {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@xs {
    margin-top: auto;
  }

  .margin-top-0\@xs {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@xs {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@xs {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@xs {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@xs {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@xs {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@xs {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@xs {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@xs {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@xs {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@xs {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@xs {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@xs {
    margin-bottom: auto;
  }

  .margin-bottom-0\@xs {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@xs {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@xs {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@xs {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@xs {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@xs {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@xs {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@xs {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@xs {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@xs {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@xs {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@xs {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@xs {
    margin-right: auto;
  }

  .margin-right-0\@xs {
    margin-right: 0;
  }

  .margin-left-xxxxs\@xs {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@xs {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@xs {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@xs {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@xs {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@xs {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@xs {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@xs {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@xs {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@xs {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@xs {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@xs {
    margin-left: auto;
  }

  .margin-left-0\@xs {
    margin-left: 0;
  }

  .margin-x-xxxxs\@xs {
    margin-left: var(--space-xxxxs);
    margin-right: var(--space-xxxxs);
  }

  .margin-x-xxxs\@xs {
    margin-left: var(--space-xxxs);
    margin-right: var(--space-xxxs);
  }

  .margin-x-xxs\@xs {
    margin-left: var(--space-xxs);
    margin-right: var(--space-xxs);
  }

  .margin-x-xs\@xs {
    margin-left: var(--space-xs);
    margin-right: var(--space-xs);
  }

  .margin-x-sm\@xs {
    margin-left: var(--space-sm);
    margin-right: var(--space-sm);
  }

  .margin-x-md\@xs {
    margin-left: var(--space-md);
    margin-right: var(--space-md);
  }

  .margin-x-lg\@xs {
    margin-left: var(--space-lg);
    margin-right: var(--space-lg);
  }

  .margin-x-xl\@xs {
    margin-left: var(--space-xl);
    margin-right: var(--space-xl);
  }

  .margin-x-xxl\@xs {
    margin-left: var(--space-xxl);
    margin-right: var(--space-xxl);
  }

  .margin-x-xxxl\@xs {
    margin-left: var(--space-xxxl);
    margin-right: var(--space-xxxl);
  }

  .margin-x-xxxxl\@xs {
    margin-left: var(--space-xxxxl);
    margin-right: var(--space-xxxxl);
  }

  .margin-x-auto\@xs {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@xs {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@xs {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@xs {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@xs {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@xs {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@xs {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@xs {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@xs {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@xs {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@xs {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@xs {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@xs {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@xs {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@xs {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@xs {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@xs {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@xs {
    padding: var(--space-xxs);
  }

  .padding-xs\@xs {
    padding: var(--space-xs);
  }

  .padding-sm\@xs {
    padding: var(--space-sm);
  }

  .padding-md\@xs {
    padding: var(--space-md);
  }

  .padding-lg\@xs {
    padding: var(--space-lg);
  }

  .padding-xl\@xs {
    padding: var(--space-xl);
  }

  .padding-xxl\@xs {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@xs {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@xs {
    padding: var(--space-xxxxl);
  }

  .padding-0\@xs {
    padding: 0;
  }

  .padding-component\@xs {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@xs {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@xs {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@xs {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@xs {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@xs {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@xs {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@xs {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@xs {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@xs {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@xs {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@xs {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@xs {
    padding-top: 0;
  }

  .padding-top-component\@xs {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@xs {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@xs {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@xs {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@xs {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@xs {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@xs {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@xs {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@xs {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@xs {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@xs {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@xs {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@xs {
    padding-bottom: 0;
  }

  .padding-bottom-component\@xs {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@xs {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@xs {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@xs {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@xs {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@xs {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@xs {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@xs {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@xs {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@xs {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@xs {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@xs {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@xs {
    padding-right: 0;
  }

  .padding-right-component\@xs {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@xs {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@xs {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@xs {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@xs {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@xs {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@xs {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@xs {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@xs {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@xs {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@xs {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@xs {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@xs {
    padding-left: 0;
  }

  .padding-left-component\@xs {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@xs {
    padding-left: var(--space-xxxxs);
    padding-right: var(--space-xxxxs);
  }

  .padding-x-xxxs\@xs {
    padding-left: var(--space-xxxs);
    padding-right: var(--space-xxxs);
  }

  .padding-x-xxs\@xs {
    padding-left: var(--space-xxs);
    padding-right: var(--space-xxs);
  }

  .padding-x-xs\@xs {
    padding-left: var(--space-xs);
    padding-right: var(--space-xs);
  }

  .padding-x-sm\@xs {
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
  }

  .padding-x-md\@xs {
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }

  .padding-x-lg\@xs {
    padding-left: var(--space-lg);
    padding-right: var(--space-lg);
  }

  .padding-x-xl\@xs {
    padding-left: var(--space-xl);
    padding-right: var(--space-xl);
  }

  .padding-x-xxl\@xs {
    padding-left: var(--space-xxl);
    padding-right: var(--space-xxl);
  }

  .padding-x-xxxl\@xs {
    padding-left: var(--space-xxxl);
    padding-right: var(--space-xxxl);
  }

  .padding-x-xxxxl\@xs {
    padding-left: var(--space-xxxxl);
    padding-right: var(--space-xxxxl);
  }

  .padding-x-0\@xs {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@xs {
    padding-left: var(--component-padding);
    padding-right: var(--component-padding);
  }

  .padding-y-xxxxs\@xs {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@xs {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@xs {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@xs {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@xs {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@xs {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@xs {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@xs {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@xs {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@xs {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@xs {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@xs {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@xs {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@xs {
    text-align: center;
  }

  .text-left\@xs {
    text-align: left;
  }

  .text-right\@xs {
    text-align: right;
  }

  .text-justify\@xs {
    text-align: justify;
  }

  .text-xs\@xs {
    font-size: var(--text-xs, 0.6875rem);
  }

  .text-sm\@xs {
    font-size: var(--text-sm, 0.75rem);
  }

  .text-base\@xs {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@xs {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@xs {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@xs {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@xs {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@xs {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@xs {
    font-size: var(--text-xxxxl, 3rem);
  }

  .width-xxxxs\@xs {
    width: var(--size-xxxxs, 0.25rem);
  }

  .width-xxxs\@xs {
    width: var(--size-xxxs, 0.5rem);
  }

  .width-xxs\@xs {
    width: var(--size-xxs, 0.75rem);
  }

  .width-xs\@xs {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@xs {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@xs {
    width: var(--size-md, 2rem);
  }

  .width-lg\@xs {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@xs {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@xs {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@xs {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@xs {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@xs {
    width: 0;
  }

  .width-10\%\@xs {
    width: 10%;
  }

  .width-20\%\@xs {
    width: 20%;
  }

  .width-25\%\@xs {
    width: 25%;
  }

  .width-30\%\@xs {
    width: 30%;
  }

  .width-33\%\@xs {
    width: 33.3333333333%;
  }

  .width-40\%\@xs {
    width: 40%;
  }

  .width-50\%\@xs {
    width: 50%;
  }

  .width-60\%\@xs {
    width: 60%;
  }

  .width-66\%\@xs {
    width: 66.6666666667%;
  }

  .width-70\%\@xs {
    width: 70%;
  }

  .width-75\%\@xs {
    width: 75%;
  }

  .width-80\%\@xs {
    width: 80%;
  }

  .width-90\%\@xs {
    width: 90%;
  }

  .width-100\%\@xs {
    width: 100%;
  }

  .width-100vw\@xs {
    width: 100vw;
  }

  .width-auto\@xs {
    width: auto;
  }

  .height-xxxxs\@xs {
    height: var(--size-xxxxs, 0.25rem);
  }

  .height-xxxs\@xs {
    height: var(--size-xxxs, 0.5rem);
  }

  .height-xxs\@xs {
    height: var(--size-xxs, 0.75rem);
  }

  .height-xs\@xs {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@xs {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@xs {
    height: var(--size-md, 2rem);
  }

  .height-lg\@xs {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@xs {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@xs {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@xs {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@xs {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@xs {
    height: 0;
  }

  .height-10\%\@xs {
    height: 10%;
  }

  .height-20\%\@xs {
    height: 20%;
  }

  .height-25\%\@xs {
    height: 25%;
  }

  .height-30\%\@xs {
    height: 30%;
  }

  .height-33\%\@xs {
    height: 33.3333333333%;
  }

  .height-40\%\@xs {
    height: 40%;
  }

  .height-50\%\@xs {
    height: 50%;
  }

  .height-60\%\@xs {
    height: 60%;
  }

  .height-66\%\@xs {
    height: 66.6666666667%;
  }

  .height-70\%\@xs {
    height: 70%;
  }

  .height-75\%\@xs {
    height: 75%;
  }

  .height-80\%\@xs {
    height: 80%;
  }

  .height-90\%\@xs {
    height: 90%;
  }

  .height-100\%\@xs {
    height: 100%;
  }

  .height-100vh\@xs {
    height: 100vh;
  }

  .height-auto\@xs {
    height: auto;
  }

  .max-width-xxxxs\@xs {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@xs {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@xs {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@xs {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@xs {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@xs {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@xs {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@xs {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@xs {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@xs {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@xs {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@xs {
    max-width: 100%;
  }

  .max-width-none\@xs {
    max-width: none;
  }

  .position-relative\@xs {
    position: relative;
  }

  .position-absolute\@xs {
    position: absolute;
  }

  .position-fixed\@xs {
    position: fixed;
  }

  .position-sticky\@xs {
    position: sticky;
  }

  .position-static\@xs {
    position: static;
  }

  .inset-0\@xs {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .top-0\@xs {
    top: 0;
  }

  .top-50\%\@xs {
    top: 50%;
  }

  .top-xxxxs\@xs {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@xs {
    top: var(--space-xxxs);
  }

  .top-xxs\@xs {
    top: var(--space-xxs);
  }

  .top-xs\@xs {
    top: var(--space-xs);
  }

  .top-sm\@xs {
    top: var(--space-sm);
  }

  .top-md\@xs {
    top: var(--space-md);
  }

  .top-lg\@xs {
    top: var(--space-lg);
  }

  .top-xl\@xs {
    top: var(--space-xl);
  }

  .top-xxl\@xs {
    top: var(--space-xxl);
  }

  .top-xxxl\@xs {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@xs {
    top: var(--space-xxxxl);
  }

  .bottom-0\@xs {
    bottom: 0;
  }

  .bottom-50\%\@xs {
    bottom: 50%;
  }

  .bottom-xxxxs\@xs {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@xs {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@xs {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@xs {
    bottom: var(--space-xs);
  }

  .bottom-sm\@xs {
    bottom: var(--space-sm);
  }

  .bottom-md\@xs {
    bottom: var(--space-md);
  }

  .bottom-lg\@xs {
    bottom: var(--space-lg);
  }

  .bottom-xl\@xs {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@xs {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@xs {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@xs {
    bottom: var(--space-xxxxl);
  }

  .right-0\@xs {
    right: 0;
  }

  .right-50\%\@xs {
    right: 50%;
  }

  .right-xxxxs\@xs {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@xs {
    right: var(--space-xxxs);
  }

  .right-xxs\@xs {
    right: var(--space-xxs);
  }

  .right-xs\@xs {
    right: var(--space-xs);
  }

  .right-sm\@xs {
    right: var(--space-sm);
  }

  .right-md\@xs {
    right: var(--space-md);
  }

  .right-lg\@xs {
    right: var(--space-lg);
  }

  .right-xl\@xs {
    right: var(--space-xl);
  }

  .right-xxl\@xs {
    right: var(--space-xxl);
  }

  .right-xxxl\@xs {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@xs {
    right: var(--space-xxxxl);
  }

  .left-0\@xs {
    left: 0;
  }

  .left-50\%\@xs {
    left: 50%;
  }

  .left-xxxxs\@xs {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@xs {
    left: var(--space-xxxs);
  }

  .left-xxs\@xs {
    left: var(--space-xxs);
  }

  .left-xs\@xs {
    left: var(--space-xs);
  }

  .left-sm\@xs {
    left: var(--space-sm);
  }

  .left-md\@xs {
    left: var(--space-md);
  }

  .left-lg\@xs {
    left: var(--space-lg);
  }

  .left-xl\@xs {
    left: var(--space-xl);
  }

  .left-xxl\@xs {
    left: var(--space-xxl);
  }

  .left-xxxl\@xs {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@xs {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@xs {
    overflow: hidden;
  }

  .overflow-auto\@xs {
    overflow: auto;
  }

  .momentum-scrolling\@xs {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@xs {
    overscroll-behavior: contain;
  }

  .visible\@xs {
    visibility: visible;
  }

  .invisible\@xs {
    visibility: hidden;
  }
}
@media not all and (min-width: 32rem) {
  .display\@xs {
    display: none !important;
  }
}
@media (min-width: 48rem) {
  .flex\@sm {
    display: flex;
  }

  .inline-flex\@sm {
    display: inline-flex;
  }

  .flex-wrap\@sm {
    flex-wrap: wrap;
  }

  .flex-nowrap\@sm {
    flex-wrap: nowrap;
  }

  .flex-column\@sm {
    flex-direction: column;
  }

  .flex-column-reverse\@sm {
    flex-direction: column-reverse;
  }

  .flex-row\@sm {
    flex-direction: row;
  }

  .flex-row-reverse\@sm {
    flex-direction: row-reverse;
  }

  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@sm {
    flex-grow: 1;
  }

  .flex-grow-0\@sm {
    flex-grow: 0;
  }

  .flex-shrink\@sm {
    flex-shrink: 1;
  }

  .flex-shrink-0\@sm {
    flex-shrink: 0;
  }

  .flex-basis-0\@sm {
    flex-basis: 0;
  }

  .justify-start\@sm {
    justify-content: flex-start;
  }

  .justify-end\@sm {
    justify-content: flex-end;
  }

  .justify-center\@sm {
    justify-content: center;
  }

  .justify-between\@sm {
    justify-content: space-between;
  }

  .items-center\@sm {
    align-items: center;
  }

  .items-start\@sm {
    align-items: flex-start;
  }

  .items-end\@sm {
    align-items: flex-end;
  }

  .items-baseline\@sm {
    align-items: baseline;
  }

  .order-1\@sm {
    order: 1;
  }

  .order-2\@sm {
    order: 2;
  }

  .order-3\@sm {
    order: 3;
  }

  .block\@sm {
    display: block;
  }

  .inline-block\@sm {
    display: inline-block;
  }

  .inline\@sm {
    display: inline;
  }

  .contents\@sm {
    display: contents;
  }

  .hide\@sm {
    display: none !important;
  }

  .margin-xxxxs\@sm {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@sm {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@sm {
    margin: var(--space-xxs);
  }

  .margin-xs\@sm {
    margin: var(--space-xs);
  }

  .margin-sm\@sm {
    margin: var(--space-sm);
  }

  .margin-md\@sm {
    margin: var(--space-md);
  }

  .margin-lg\@sm {
    margin: var(--space-lg);
  }

  .margin-xl\@sm {
    margin: var(--space-xl);
  }

  .margin-xxl\@sm {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@sm {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@sm {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@sm {
    margin: auto;
  }

  .margin-0\@sm {
    margin: 0;
  }

  .margin-top-xxxxs\@sm {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@sm {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@sm {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@sm {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@sm {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@sm {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@sm {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@sm {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@sm {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@sm {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@sm {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@sm {
    margin-top: auto;
  }

  .margin-top-0\@sm {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@sm {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@sm {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@sm {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@sm {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@sm {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@sm {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@sm {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@sm {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@sm {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@sm {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@sm {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@sm {
    margin-bottom: auto;
  }

  .margin-bottom-0\@sm {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@sm {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@sm {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@sm {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@sm {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@sm {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@sm {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@sm {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@sm {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@sm {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@sm {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@sm {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@sm {
    margin-right: auto;
  }

  .margin-right-0\@sm {
    margin-right: 0;
  }

  .margin-left-xxxxs\@sm {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@sm {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@sm {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@sm {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@sm {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@sm {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@sm {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@sm {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@sm {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@sm {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@sm {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@sm {
    margin-left: auto;
  }

  .margin-left-0\@sm {
    margin-left: 0;
  }

  .margin-x-xxxxs\@sm {
    margin-left: var(--space-xxxxs);
    margin-right: var(--space-xxxxs);
  }

  .margin-x-xxxs\@sm {
    margin-left: var(--space-xxxs);
    margin-right: var(--space-xxxs);
  }

  .margin-x-xxs\@sm {
    margin-left: var(--space-xxs);
    margin-right: var(--space-xxs);
  }

  .margin-x-xs\@sm {
    margin-left: var(--space-xs);
    margin-right: var(--space-xs);
  }

  .margin-x-sm\@sm {
    margin-left: var(--space-sm);
    margin-right: var(--space-sm);
  }

  .margin-x-md\@sm {
    margin-left: var(--space-md);
    margin-right: var(--space-md);
  }

  .margin-x-lg\@sm {
    margin-left: var(--space-lg);
    margin-right: var(--space-lg);
  }

  .margin-x-xl\@sm {
    margin-left: var(--space-xl);
    margin-right: var(--space-xl);
  }

  .margin-x-xxl\@sm {
    margin-left: var(--space-xxl);
    margin-right: var(--space-xxl);
  }

  .margin-x-xxxl\@sm {
    margin-left: var(--space-xxxl);
    margin-right: var(--space-xxxl);
  }

  .margin-x-xxxxl\@sm {
    margin-left: var(--space-xxxxl);
    margin-right: var(--space-xxxxl);
  }

  .margin-x-auto\@sm {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@sm {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@sm {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@sm {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@sm {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@sm {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@sm {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@sm {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@sm {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@sm {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@sm {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@sm {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@sm {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@sm {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@sm {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@sm {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@sm {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@sm {
    padding: var(--space-xxs);
  }

  .padding-xs\@sm {
    padding: var(--space-xs);
  }

  .padding-sm\@sm {
    padding: var(--space-sm);
  }

  .padding-md\@sm {
    padding: var(--space-md);
  }

  .padding-lg\@sm {
    padding: var(--space-lg);
  }

  .padding-xl\@sm {
    padding: var(--space-xl);
  }

  .padding-xxl\@sm {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@sm {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@sm {
    padding: var(--space-xxxxl);
  }

  .padding-0\@sm {
    padding: 0;
  }

  .padding-component\@sm {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@sm {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@sm {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@sm {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@sm {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@sm {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@sm {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@sm {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@sm {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@sm {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@sm {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@sm {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@sm {
    padding-top: 0;
  }

  .padding-top-component\@sm {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@sm {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@sm {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@sm {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@sm {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@sm {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@sm {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@sm {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@sm {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@sm {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@sm {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@sm {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@sm {
    padding-bottom: 0;
  }

  .padding-bottom-component\@sm {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@sm {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@sm {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@sm {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@sm {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@sm {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@sm {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@sm {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@sm {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@sm {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@sm {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@sm {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@sm {
    padding-right: 0;
  }

  .padding-right-component\@sm {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@sm {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@sm {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@sm {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@sm {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@sm {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@sm {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@sm {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@sm {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@sm {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@sm {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@sm {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@sm {
    padding-left: 0;
  }

  .padding-left-component\@sm {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@sm {
    padding-left: var(--space-xxxxs);
    padding-right: var(--space-xxxxs);
  }

  .padding-x-xxxs\@sm {
    padding-left: var(--space-xxxs);
    padding-right: var(--space-xxxs);
  }

  .padding-x-xxs\@sm {
    padding-left: var(--space-xxs);
    padding-right: var(--space-xxs);
  }

  .padding-x-xs\@sm {
    padding-left: var(--space-xs);
    padding-right: var(--space-xs);
  }

  .padding-x-sm\@sm {
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
  }

  .padding-x-md\@sm {
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }

  .padding-x-lg\@sm {
    padding-left: var(--space-lg);
    padding-right: var(--space-lg);
  }

  .padding-x-xl\@sm {
    padding-left: var(--space-xl);
    padding-right: var(--space-xl);
  }

  .padding-x-xxl\@sm {
    padding-left: var(--space-xxl);
    padding-right: var(--space-xxl);
  }

  .padding-x-xxxl\@sm {
    padding-left: var(--space-xxxl);
    padding-right: var(--space-xxxl);
  }

  .padding-x-xxxxl\@sm {
    padding-left: var(--space-xxxxl);
    padding-right: var(--space-xxxxl);
  }

  .padding-x-0\@sm {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@sm {
    padding-left: var(--component-padding);
    padding-right: var(--component-padding);
  }

  .padding-y-xxxxs\@sm {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@sm {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@sm {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@sm {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@sm {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@sm {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@sm {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@sm {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@sm {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@sm {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@sm {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@sm {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@sm {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@sm {
    text-align: center;
  }

  .text-left\@sm {
    text-align: left;
  }

  .text-right\@sm {
    text-align: right;
  }

  .text-justify\@sm {
    text-align: justify;
  }

  .text-xs\@sm {
    font-size: var(--text-xs, 0.6875rem);
  }

  .text-sm\@sm {
    font-size: var(--text-sm, 0.75rem);
  }

  .text-base\@sm {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@sm {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@sm {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@sm {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@sm {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@sm {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@sm {
    font-size: var(--text-xxxxl, 3rem);
  }

  .width-xxxxs\@sm {
    width: var(--size-xxxxs, 0.25rem);
  }

  .width-xxxs\@sm {
    width: var(--size-xxxs, 0.5rem);
  }

  .width-xxs\@sm {
    width: var(--size-xxs, 0.75rem);
  }

  .width-xs\@sm {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@sm {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@sm {
    width: var(--size-md, 2rem);
  }

  .width-lg\@sm {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@sm {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@sm {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@sm {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@sm {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@sm {
    width: 0;
  }

  .width-10\%\@sm {
    width: 10%;
  }

  .width-20\%\@sm {
    width: 20%;
  }

  .width-25\%\@sm {
    width: 25%;
  }

  .width-30\%\@sm {
    width: 30%;
  }

  .width-33\%\@sm {
    width: 33.3333333333%;
  }

  .width-40\%\@sm {
    width: 40%;
  }

  .width-50\%\@sm {
    width: 50%;
  }

  .width-60\%\@sm {
    width: 60%;
  }

  .width-66\%\@sm {
    width: 66.6666666667%;
  }

  .width-70\%\@sm {
    width: 70%;
  }

  .width-75\%\@sm {
    width: 75%;
  }

  .width-80\%\@sm {
    width: 80%;
  }

  .width-90\%\@sm {
    width: 90%;
  }

  .width-100\%\@sm {
    width: 100%;
  }

  .width-100vw\@sm {
    width: 100vw;
  }

  .width-auto\@sm {
    width: auto;
  }

  .height-xxxxs\@sm {
    height: var(--size-xxxxs, 0.25rem);
  }

  .height-xxxs\@sm {
    height: var(--size-xxxs, 0.5rem);
  }

  .height-xxs\@sm {
    height: var(--size-xxs, 0.75rem);
  }

  .height-xs\@sm {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@sm {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@sm {
    height: var(--size-md, 2rem);
  }

  .height-lg\@sm {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@sm {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@sm {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@sm {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@sm {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@sm {
    height: 0;
  }

  .height-10\%\@sm {
    height: 10%;
  }

  .height-20\%\@sm {
    height: 20%;
  }

  .height-25\%\@sm {
    height: 25%;
  }

  .height-30\%\@sm {
    height: 30%;
  }

  .height-33\%\@sm {
    height: 33.3333333333%;
  }

  .height-40\%\@sm {
    height: 40%;
  }

  .height-50\%\@sm {
    height: 50%;
  }

  .height-60\%\@sm {
    height: 60%;
  }

  .height-66\%\@sm {
    height: 66.6666666667%;
  }

  .height-70\%\@sm {
    height: 70%;
  }

  .height-75\%\@sm {
    height: 75%;
  }

  .height-80\%\@sm {
    height: 80%;
  }

  .height-90\%\@sm {
    height: 90%;
  }

  .height-100\%\@sm {
    height: 100%;
  }

  .height-100vh\@sm {
    height: 100vh;
  }

  .height-auto\@sm {
    height: auto;
  }

  .max-width-xxxxs\@sm {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@sm {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@sm {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@sm {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@sm {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@sm {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@sm {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@sm {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@sm {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@sm {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@sm {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@sm {
    max-width: 100%;
  }

  .max-width-none\@sm {
    max-width: none;
  }

  .position-relative\@sm {
    position: relative;
  }

  .position-absolute\@sm {
    position: absolute;
  }

  .position-fixed\@sm {
    position: fixed;
  }

  .position-sticky\@sm {
    position: sticky;
  }

  .position-static\@sm {
    position: static;
  }

  .inset-0\@sm {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .top-0\@sm {
    top: 0;
  }

  .top-50\%\@sm {
    top: 50%;
  }

  .top-xxxxs\@sm {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@sm {
    top: var(--space-xxxs);
  }

  .top-xxs\@sm {
    top: var(--space-xxs);
  }

  .top-xs\@sm {
    top: var(--space-xs);
  }

  .top-sm\@sm {
    top: var(--space-sm);
  }

  .top-md\@sm {
    top: var(--space-md);
  }

  .top-lg\@sm {
    top: var(--space-lg);
  }

  .top-xl\@sm {
    top: var(--space-xl);
  }

  .top-xxl\@sm {
    top: var(--space-xxl);
  }

  .top-xxxl\@sm {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@sm {
    top: var(--space-xxxxl);
  }

  .bottom-0\@sm {
    bottom: 0;
  }

  .bottom-50\%\@sm {
    bottom: 50%;
  }

  .bottom-xxxxs\@sm {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@sm {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@sm {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@sm {
    bottom: var(--space-xs);
  }

  .bottom-sm\@sm {
    bottom: var(--space-sm);
  }

  .bottom-md\@sm {
    bottom: var(--space-md);
  }

  .bottom-lg\@sm {
    bottom: var(--space-lg);
  }

  .bottom-xl\@sm {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@sm {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@sm {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@sm {
    bottom: var(--space-xxxxl);
  }

  .right-0\@sm {
    right: 0;
  }

  .right-50\%\@sm {
    right: 50%;
  }

  .right-xxxxs\@sm {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@sm {
    right: var(--space-xxxs);
  }

  .right-xxs\@sm {
    right: var(--space-xxs);
  }

  .right-xs\@sm {
    right: var(--space-xs);
  }

  .right-sm\@sm {
    right: var(--space-sm);
  }

  .right-md\@sm {
    right: var(--space-md);
  }

  .right-lg\@sm {
    right: var(--space-lg);
  }

  .right-xl\@sm {
    right: var(--space-xl);
  }

  .right-xxl\@sm {
    right: var(--space-xxl);
  }

  .right-xxxl\@sm {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@sm {
    right: var(--space-xxxxl);
  }

  .left-0\@sm {
    left: 0;
  }

  .left-50\%\@sm {
    left: 50%;
  }

  .left-xxxxs\@sm {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@sm {
    left: var(--space-xxxs);
  }

  .left-xxs\@sm {
    left: var(--space-xxs);
  }

  .left-xs\@sm {
    left: var(--space-xs);
  }

  .left-sm\@sm {
    left: var(--space-sm);
  }

  .left-md\@sm {
    left: var(--space-md);
  }

  .left-lg\@sm {
    left: var(--space-lg);
  }

  .left-xl\@sm {
    left: var(--space-xl);
  }

  .left-xxl\@sm {
    left: var(--space-xxl);
  }

  .left-xxxl\@sm {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@sm {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@sm {
    overflow: hidden;
  }

  .overflow-auto\@sm {
    overflow: auto;
  }

  .momentum-scrolling\@sm {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@sm {
    overscroll-behavior: contain;
  }

  .visible\@sm {
    visibility: visible;
  }

  .invisible\@sm {
    visibility: hidden;
  }
}
@media not all and (min-width: 48rem) {
  .display\@sm {
    display: none !important;
  }
}
@media (min-width: 64rem) {
  .flex\@md {
    display: flex;
  }

  .inline-flex\@md {
    display: inline-flex;
  }

  .flex-wrap\@md {
    flex-wrap: wrap;
  }

  .flex-nowrap\@md {
    flex-wrap: nowrap;
  }

  .flex-column\@md {
    flex-direction: column;
  }

  .flex-column-reverse\@md {
    flex-direction: column-reverse;
  }

  .flex-row\@md {
    flex-direction: row;
  }

  .flex-row-reverse\@md {
    flex-direction: row-reverse;
  }

  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@md {
    flex-grow: 1;
  }

  .flex-grow-0\@md {
    flex-grow: 0;
  }

  .flex-shrink\@md {
    flex-shrink: 1;
  }

  .flex-shrink-0\@md {
    flex-shrink: 0;
  }

  .flex-basis-0\@md {
    flex-basis: 0;
  }

  .justify-start\@md {
    justify-content: flex-start;
  }

  .justify-end\@md {
    justify-content: flex-end;
  }

  .justify-center\@md {
    justify-content: center;
  }

  .justify-between\@md {
    justify-content: space-between;
  }

  .items-center\@md {
    align-items: center;
  }

  .items-start\@md {
    align-items: flex-start;
  }

  .items-end\@md {
    align-items: flex-end;
  }

  .items-baseline\@md {
    align-items: baseline;
  }

  .order-1\@md {
    order: 1;
  }

  .order-2\@md {
    order: 2;
  }

  .order-3\@md {
    order: 3;
  }

  .block\@md {
    display: block;
  }

  .inline-block\@md {
    display: inline-block;
  }

  .inline\@md {
    display: inline;
  }

  .contents\@md {
    display: contents;
  }

  .hide\@md {
    display: none !important;
  }

  .margin-xxxxs\@md {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@md {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@md {
    margin: var(--space-xxs);
  }

  .margin-xs\@md {
    margin: var(--space-xs);
  }

  .margin-sm\@md {
    margin: var(--space-sm);
  }

  .margin-md\@md {
    margin: var(--space-md);
  }

  .margin-lg\@md {
    margin: var(--space-lg);
  }

  .margin-xl\@md {
    margin: var(--space-xl);
  }

  .margin-xxl\@md {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@md {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@md {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@md {
    margin: auto;
  }

  .margin-0\@md {
    margin: 0;
  }

  .margin-top-xxxxs\@md {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@md {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@md {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@md {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@md {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@md {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@md {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@md {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@md {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@md {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@md {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@md {
    margin-top: auto;
  }

  .margin-top-0\@md {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@md {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@md {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@md {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@md {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@md {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@md {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@md {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@md {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@md {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@md {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@md {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@md {
    margin-bottom: auto;
  }

  .margin-bottom-0\@md {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@md {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@md {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@md {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@md {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@md {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@md {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@md {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@md {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@md {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@md {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@md {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@md {
    margin-right: auto;
  }

  .margin-right-0\@md {
    margin-right: 0;
  }

  .margin-left-xxxxs\@md {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@md {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@md {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@md {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@md {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@md {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@md {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@md {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@md {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@md {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@md {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@md {
    margin-left: auto;
  }

  .margin-left-0\@md {
    margin-left: 0;
  }

  .margin-x-xxxxs\@md {
    margin-left: var(--space-xxxxs);
    margin-right: var(--space-xxxxs);
  }

  .margin-x-xxxs\@md {
    margin-left: var(--space-xxxs);
    margin-right: var(--space-xxxs);
  }

  .margin-x-xxs\@md {
    margin-left: var(--space-xxs);
    margin-right: var(--space-xxs);
  }

  .margin-x-xs\@md {
    margin-left: var(--space-xs);
    margin-right: var(--space-xs);
  }

  .margin-x-sm\@md {
    margin-left: var(--space-sm);
    margin-right: var(--space-sm);
  }

  .margin-x-md\@md {
    margin-left: var(--space-md);
    margin-right: var(--space-md);
  }

  .margin-x-lg\@md {
    margin-left: var(--space-lg);
    margin-right: var(--space-lg);
  }

  .margin-x-xl\@md {
    margin-left: var(--space-xl);
    margin-right: var(--space-xl);
  }

  .margin-x-xxl\@md {
    margin-left: var(--space-xxl);
    margin-right: var(--space-xxl);
  }

  .margin-x-xxxl\@md {
    margin-left: var(--space-xxxl);
    margin-right: var(--space-xxxl);
  }

  .margin-x-xxxxl\@md {
    margin-left: var(--space-xxxxl);
    margin-right: var(--space-xxxxl);
  }

  .margin-x-auto\@md {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@md {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@md {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@md {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@md {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@md {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@md {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@md {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@md {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@md {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@md {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@md {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@md {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@md {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@md {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@md {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@md {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@md {
    padding: var(--space-xxs);
  }

  .padding-xs\@md {
    padding: var(--space-xs);
  }

  .padding-sm\@md {
    padding: var(--space-sm);
  }

  .padding-md\@md {
    padding: var(--space-md);
  }

  .padding-lg\@md {
    padding: var(--space-lg);
  }

  .padding-xl\@md {
    padding: var(--space-xl);
  }

  .padding-xxl\@md {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@md {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@md {
    padding: var(--space-xxxxl);
  }

  .padding-0\@md {
    padding: 0;
  }

  .padding-component\@md {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@md {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@md {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@md {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@md {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@md {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@md {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@md {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@md {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@md {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@md {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@md {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@md {
    padding-top: 0;
  }

  .padding-top-component\@md {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@md {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@md {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@md {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@md {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@md {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@md {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@md {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@md {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@md {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@md {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@md {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@md {
    padding-bottom: 0;
  }

  .padding-bottom-component\@md {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@md {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@md {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@md {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@md {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@md {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@md {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@md {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@md {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@md {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@md {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@md {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@md {
    padding-right: 0;
  }

  .padding-right-component\@md {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@md {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@md {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@md {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@md {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@md {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@md {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@md {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@md {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@md {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@md {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@md {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@md {
    padding-left: 0;
  }

  .padding-left-component\@md {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@md {
    padding-left: var(--space-xxxxs);
    padding-right: var(--space-xxxxs);
  }

  .padding-x-xxxs\@md {
    padding-left: var(--space-xxxs);
    padding-right: var(--space-xxxs);
  }

  .padding-x-xxs\@md {
    padding-left: var(--space-xxs);
    padding-right: var(--space-xxs);
  }

  .padding-x-xs\@md {
    padding-left: var(--space-xs);
    padding-right: var(--space-xs);
  }

  .padding-x-sm\@md {
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
  }

  .padding-x-md\@md {
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }

  .padding-x-lg\@md {
    padding-left: var(--space-lg);
    padding-right: var(--space-lg);
  }

  .padding-x-xl\@md {
    padding-left: var(--space-xl);
    padding-right: var(--space-xl);
  }

  .padding-x-xxl\@md {
    padding-left: var(--space-xxl);
    padding-right: var(--space-xxl);
  }

  .padding-x-xxxl\@md {
    padding-left: var(--space-xxxl);
    padding-right: var(--space-xxxl);
  }

  .padding-x-xxxxl\@md {
    padding-left: var(--space-xxxxl);
    padding-right: var(--space-xxxxl);
  }

  .padding-x-0\@md {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@md {
    padding-left: var(--component-padding);
    padding-right: var(--component-padding);
  }

  .padding-y-xxxxs\@md {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@md {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@md {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@md {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@md {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@md {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@md {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@md {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@md {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@md {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@md {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@md {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@md {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@md {
    text-align: center;
  }

  .text-left\@md {
    text-align: left;
  }

  .text-right\@md {
    text-align: right;
  }

  .text-justify\@md {
    text-align: justify;
  }

  .text-xs\@md {
    font-size: var(--text-xs, 0.6875rem);
  }

  .text-sm\@md {
    font-size: var(--text-sm, 0.75rem);
  }

  .text-base\@md {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@md {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@md {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@md {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@md {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@md {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@md {
    font-size: var(--text-xxxxl, 3rem);
  }

  .width-xxxxs\@md {
    width: var(--size-xxxxs, 0.25rem);
  }

  .width-xxxs\@md {
    width: var(--size-xxxs, 0.5rem);
  }

  .width-xxs\@md {
    width: var(--size-xxs, 0.75rem);
  }

  .width-xs\@md {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@md {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@md {
    width: var(--size-md, 2rem);
  }

  .width-lg\@md {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@md {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@md {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@md {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@md {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@md {
    width: 0;
  }

  .width-10\%\@md {
    width: 10%;
  }

  .width-20\%\@md {
    width: 20%;
  }

  .width-25\%\@md {
    width: 25%;
  }

  .width-30\%\@md {
    width: 30%;
  }

  .width-33\%\@md {
    width: 33.3333333333%;
  }

  .width-40\%\@md {
    width: 40%;
  }

  .width-50\%\@md {
    width: 50%;
  }

  .width-60\%\@md {
    width: 60%;
  }

  .width-66\%\@md {
    width: 66.6666666667%;
  }

  .width-70\%\@md {
    width: 70%;
  }

  .width-75\%\@md {
    width: 75%;
  }

  .width-80\%\@md {
    width: 80%;
  }

  .width-90\%\@md {
    width: 90%;
  }

  .width-100\%\@md {
    width: 100%;
  }

  .width-100vw\@md {
    width: 100vw;
  }

  .width-auto\@md {
    width: auto;
  }

  .height-xxxxs\@md {
    height: var(--size-xxxxs, 0.25rem);
  }

  .height-xxxs\@md {
    height: var(--size-xxxs, 0.5rem);
  }

  .height-xxs\@md {
    height: var(--size-xxs, 0.75rem);
  }

  .height-xs\@md {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@md {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@md {
    height: var(--size-md, 2rem);
  }

  .height-lg\@md {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@md {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@md {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@md {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@md {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@md {
    height: 0;
  }

  .height-10\%\@md {
    height: 10%;
  }

  .height-20\%\@md {
    height: 20%;
  }

  .height-25\%\@md {
    height: 25%;
  }

  .height-30\%\@md {
    height: 30%;
  }

  .height-33\%\@md {
    height: 33.3333333333%;
  }

  .height-40\%\@md {
    height: 40%;
  }

  .height-50\%\@md {
    height: 50%;
  }

  .height-60\%\@md {
    height: 60%;
  }

  .height-66\%\@md {
    height: 66.6666666667%;
  }

  .height-70\%\@md {
    height: 70%;
  }

  .height-75\%\@md {
    height: 75%;
  }

  .height-80\%\@md {
    height: 80%;
  }

  .height-90\%\@md {
    height: 90%;
  }

  .height-100\%\@md {
    height: 100%;
  }

  .height-100vh\@md {
    height: 100vh;
  }

  .height-auto\@md {
    height: auto;
  }

  .max-width-xxxxs\@md {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@md {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@md {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@md {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@md {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@md {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@md {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@md {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@md {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@md {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@md {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@md {
    max-width: 100%;
  }

  .max-width-none\@md {
    max-width: none;
  }

  .position-relative\@md {
    position: relative;
  }

  .position-absolute\@md {
    position: absolute;
  }

  .position-fixed\@md {
    position: fixed;
  }

  .position-sticky\@md {
    position: sticky;
  }

  .position-static\@md {
    position: static;
  }

  .inset-0\@md {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .top-0\@md {
    top: 0;
  }

  .top-50\%\@md {
    top: 50%;
  }

  .top-xxxxs\@md {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@md {
    top: var(--space-xxxs);
  }

  .top-xxs\@md {
    top: var(--space-xxs);
  }

  .top-xs\@md {
    top: var(--space-xs);
  }

  .top-sm\@md {
    top: var(--space-sm);
  }

  .top-md\@md {
    top: var(--space-md);
  }

  .top-lg\@md {
    top: var(--space-lg);
  }

  .top-xl\@md {
    top: var(--space-xl);
  }

  .top-xxl\@md {
    top: var(--space-xxl);
  }

  .top-xxxl\@md {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@md {
    top: var(--space-xxxxl);
  }

  .bottom-0\@md {
    bottom: 0;
  }

  .bottom-50\%\@md {
    bottom: 50%;
  }

  .bottom-xxxxs\@md {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@md {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@md {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@md {
    bottom: var(--space-xs);
  }

  .bottom-sm\@md {
    bottom: var(--space-sm);
  }

  .bottom-md\@md {
    bottom: var(--space-md);
  }

  .bottom-lg\@md {
    bottom: var(--space-lg);
  }

  .bottom-xl\@md {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@md {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@md {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@md {
    bottom: var(--space-xxxxl);
  }

  .right-0\@md {
    right: 0;
  }

  .right-50\%\@md {
    right: 50%;
  }

  .right-xxxxs\@md {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@md {
    right: var(--space-xxxs);
  }

  .right-xxs\@md {
    right: var(--space-xxs);
  }

  .right-xs\@md {
    right: var(--space-xs);
  }

  .right-sm\@md {
    right: var(--space-sm);
  }

  .right-md\@md {
    right: var(--space-md);
  }

  .right-lg\@md {
    right: var(--space-lg);
  }

  .right-xl\@md {
    right: var(--space-xl);
  }

  .right-xxl\@md {
    right: var(--space-xxl);
  }

  .right-xxxl\@md {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@md {
    right: var(--space-xxxxl);
  }

  .left-0\@md {
    left: 0;
  }

  .left-50\%\@md {
    left: 50%;
  }

  .left-xxxxs\@md {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@md {
    left: var(--space-xxxs);
  }

  .left-xxs\@md {
    left: var(--space-xxs);
  }

  .left-xs\@md {
    left: var(--space-xs);
  }

  .left-sm\@md {
    left: var(--space-sm);
  }

  .left-md\@md {
    left: var(--space-md);
  }

  .left-lg\@md {
    left: var(--space-lg);
  }

  .left-xl\@md {
    left: var(--space-xl);
  }

  .left-xxl\@md {
    left: var(--space-xxl);
  }

  .left-xxxl\@md {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@md {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@md {
    overflow: hidden;
  }

  .overflow-auto\@md {
    overflow: auto;
  }

  .momentum-scrolling\@md {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@md {
    overscroll-behavior: contain;
  }

  .visible\@md {
    visibility: visible;
  }

  .invisible\@md {
    visibility: hidden;
  }
}
@media not all and (min-width: 64rem) {
  .display\@md {
    display: none !important;
  }
}
@media (min-width: 80rem) {
  .flex\@lg {
    display: flex;
  }

  .inline-flex\@lg {
    display: inline-flex;
  }

  .flex-wrap\@lg {
    flex-wrap: wrap;
  }

  .flex-nowrap\@lg {
    flex-wrap: nowrap;
  }

  .flex-column\@lg {
    flex-direction: column;
  }

  .flex-column-reverse\@lg {
    flex-direction: column-reverse;
  }

  .flex-row\@lg {
    flex-direction: row;
  }

  .flex-row-reverse\@lg {
    flex-direction: row-reverse;
  }

  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@lg {
    flex-grow: 1;
  }

  .flex-grow-0\@lg {
    flex-grow: 0;
  }

  .flex-shrink\@lg {
    flex-shrink: 1;
  }

  .flex-shrink-0\@lg {
    flex-shrink: 0;
  }

  .flex-basis-0\@lg {
    flex-basis: 0;
  }

  .justify-start\@lg {
    justify-content: flex-start;
  }

  .justify-end\@lg {
    justify-content: flex-end;
  }

  .justify-center\@lg {
    justify-content: center;
  }

  .justify-between\@lg {
    justify-content: space-between;
  }

  .items-center\@lg {
    align-items: center;
  }

  .items-start\@lg {
    align-items: flex-start;
  }

  .items-end\@lg {
    align-items: flex-end;
  }

  .items-baseline\@lg {
    align-items: baseline;
  }

  .order-1\@lg {
    order: 1;
  }

  .order-2\@lg {
    order: 2;
  }

  .order-3\@lg {
    order: 3;
  }

  .block\@lg {
    display: block;
  }

  .inline-block\@lg {
    display: inline-block;
  }

  .inline\@lg {
    display: inline;
  }

  .contents\@lg {
    display: contents;
  }

  .hide\@lg {
    display: none !important;
  }

  .margin-xxxxs\@lg {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@lg {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@lg {
    margin: var(--space-xxs);
  }

  .margin-xs\@lg {
    margin: var(--space-xs);
  }

  .margin-sm\@lg {
    margin: var(--space-sm);
  }

  .margin-md\@lg {
    margin: var(--space-md);
  }

  .margin-lg\@lg {
    margin: var(--space-lg);
  }

  .margin-xl\@lg {
    margin: var(--space-xl);
  }

  .margin-xxl\@lg {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@lg {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@lg {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@lg {
    margin: auto;
  }

  .margin-0\@lg {
    margin: 0;
  }

  .margin-top-xxxxs\@lg {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@lg {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@lg {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@lg {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@lg {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@lg {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@lg {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@lg {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@lg {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@lg {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@lg {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@lg {
    margin-top: auto;
  }

  .margin-top-0\@lg {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@lg {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@lg {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@lg {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@lg {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@lg {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@lg {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@lg {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@lg {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@lg {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@lg {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@lg {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@lg {
    margin-bottom: auto;
  }

  .margin-bottom-0\@lg {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@lg {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@lg {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@lg {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@lg {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@lg {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@lg {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@lg {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@lg {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@lg {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@lg {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@lg {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@lg {
    margin-right: auto;
  }

  .margin-right-0\@lg {
    margin-right: 0;
  }

  .margin-left-xxxxs\@lg {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@lg {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@lg {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@lg {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@lg {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@lg {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@lg {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@lg {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@lg {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@lg {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@lg {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@lg {
    margin-left: auto;
  }

  .margin-left-0\@lg {
    margin-left: 0;
  }

  .margin-x-xxxxs\@lg {
    margin-left: var(--space-xxxxs);
    margin-right: var(--space-xxxxs);
  }

  .margin-x-xxxs\@lg {
    margin-left: var(--space-xxxs);
    margin-right: var(--space-xxxs);
  }

  .margin-x-xxs\@lg {
    margin-left: var(--space-xxs);
    margin-right: var(--space-xxs);
  }

  .margin-x-xs\@lg {
    margin-left: var(--space-xs);
    margin-right: var(--space-xs);
  }

  .margin-x-sm\@lg {
    margin-left: var(--space-sm);
    margin-right: var(--space-sm);
  }

  .margin-x-md\@lg {
    margin-left: var(--space-md);
    margin-right: var(--space-md);
  }

  .margin-x-lg\@lg {
    margin-left: var(--space-lg);
    margin-right: var(--space-lg);
  }

  .margin-x-xl\@lg {
    margin-left: var(--space-xl);
    margin-right: var(--space-xl);
  }

  .margin-x-xxl\@lg {
    margin-left: var(--space-xxl);
    margin-right: var(--space-xxl);
  }

  .margin-x-xxxl\@lg {
    margin-left: var(--space-xxxl);
    margin-right: var(--space-xxxl);
  }

  .margin-x-xxxxl\@lg {
    margin-left: var(--space-xxxxl);
    margin-right: var(--space-xxxxl);
  }

  .margin-x-auto\@lg {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@lg {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@lg {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@lg {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@lg {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@lg {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@lg {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@lg {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@lg {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@lg {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@lg {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@lg {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@lg {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@lg {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@lg {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@lg {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@lg {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@lg {
    padding: var(--space-xxs);
  }

  .padding-xs\@lg {
    padding: var(--space-xs);
  }

  .padding-sm\@lg {
    padding: var(--space-sm);
  }

  .padding-md\@lg {
    padding: var(--space-md);
  }

  .padding-lg\@lg {
    padding: var(--space-lg);
  }

  .padding-xl\@lg {
    padding: var(--space-xl);
  }

  .padding-xxl\@lg {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@lg {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@lg {
    padding: var(--space-xxxxl);
  }

  .padding-0\@lg {
    padding: 0;
  }

  .padding-component\@lg {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@lg {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@lg {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@lg {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@lg {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@lg {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@lg {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@lg {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@lg {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@lg {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@lg {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@lg {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@lg {
    padding-top: 0;
  }

  .padding-top-component\@lg {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@lg {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@lg {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@lg {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@lg {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@lg {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@lg {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@lg {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@lg {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@lg {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@lg {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@lg {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@lg {
    padding-bottom: 0;
  }

  .padding-bottom-component\@lg {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@lg {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@lg {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@lg {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@lg {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@lg {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@lg {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@lg {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@lg {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@lg {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@lg {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@lg {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@lg {
    padding-right: 0;
  }

  .padding-right-component\@lg {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@lg {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@lg {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@lg {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@lg {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@lg {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@lg {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@lg {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@lg {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@lg {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@lg {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@lg {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@lg {
    padding-left: 0;
  }

  .padding-left-component\@lg {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@lg {
    padding-left: var(--space-xxxxs);
    padding-right: var(--space-xxxxs);
  }

  .padding-x-xxxs\@lg {
    padding-left: var(--space-xxxs);
    padding-right: var(--space-xxxs);
  }

  .padding-x-xxs\@lg {
    padding-left: var(--space-xxs);
    padding-right: var(--space-xxs);
  }

  .padding-x-xs\@lg {
    padding-left: var(--space-xs);
    padding-right: var(--space-xs);
  }

  .padding-x-sm\@lg {
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
  }

  .padding-x-md\@lg {
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }

  .padding-x-lg\@lg {
    padding-left: var(--space-lg);
    padding-right: var(--space-lg);
  }

  .padding-x-xl\@lg {
    padding-left: var(--space-xl);
    padding-right: var(--space-xl);
  }

  .padding-x-xxl\@lg {
    padding-left: var(--space-xxl);
    padding-right: var(--space-xxl);
  }

  .padding-x-xxxl\@lg {
    padding-left: var(--space-xxxl);
    padding-right: var(--space-xxxl);
  }

  .padding-x-xxxxl\@lg {
    padding-left: var(--space-xxxxl);
    padding-right: var(--space-xxxxl);
  }

  .padding-x-0\@lg {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@lg {
    padding-left: var(--component-padding);
    padding-right: var(--component-padding);
  }

  .padding-y-xxxxs\@lg {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@lg {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@lg {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@lg {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@lg {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@lg {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@lg {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@lg {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@lg {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@lg {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@lg {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@lg {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@lg {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@lg {
    text-align: center;
  }

  .text-left\@lg {
    text-align: left;
  }

  .text-right\@lg {
    text-align: right;
  }

  .text-justify\@lg {
    text-align: justify;
  }

  .text-xs\@lg {
    font-size: var(--text-xs, 0.6875rem);
  }

  .text-sm\@lg {
    font-size: var(--text-sm, 0.75rem);
  }

  .text-base\@lg {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@lg {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@lg {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@lg {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@lg {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@lg {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@lg {
    font-size: var(--text-xxxxl, 3rem);
  }

  .width-xxxxs\@lg {
    width: var(--size-xxxxs, 0.25rem);
  }

  .width-xxxs\@lg {
    width: var(--size-xxxs, 0.5rem);
  }

  .width-xxs\@lg {
    width: var(--size-xxs, 0.75rem);
  }

  .width-xs\@lg {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@lg {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@lg {
    width: var(--size-md, 2rem);
  }

  .width-lg\@lg {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@lg {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@lg {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@lg {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@lg {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@lg {
    width: 0;
  }

  .width-10\%\@lg {
    width: 10%;
  }

  .width-20\%\@lg {
    width: 20%;
  }

  .width-25\%\@lg {
    width: 25%;
  }

  .width-30\%\@lg {
    width: 30%;
  }

  .width-33\%\@lg {
    width: 33.3333333333%;
  }

  .width-40\%\@lg {
    width: 40%;
  }

  .width-50\%\@lg {
    width: 50%;
  }

  .width-60\%\@lg {
    width: 60%;
  }

  .width-66\%\@lg {
    width: 66.6666666667%;
  }

  .width-70\%\@lg {
    width: 70%;
  }

  .width-75\%\@lg {
    width: 75%;
  }

  .width-80\%\@lg {
    width: 80%;
  }

  .width-90\%\@lg {
    width: 90%;
  }

  .width-100\%\@lg {
    width: 100%;
  }

  .width-100vw\@lg {
    width: 100vw;
  }

  .width-auto\@lg {
    width: auto;
  }

  .height-xxxxs\@lg {
    height: var(--size-xxxxs, 0.25rem);
  }

  .height-xxxs\@lg {
    height: var(--size-xxxs, 0.5rem);
  }

  .height-xxs\@lg {
    height: var(--size-xxs, 0.75rem);
  }

  .height-xs\@lg {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@lg {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@lg {
    height: var(--size-md, 2rem);
  }

  .height-lg\@lg {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@lg {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@lg {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@lg {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@lg {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@lg {
    height: 0;
  }

  .height-10\%\@lg {
    height: 10%;
  }

  .height-20\%\@lg {
    height: 20%;
  }

  .height-25\%\@lg {
    height: 25%;
  }

  .height-30\%\@lg {
    height: 30%;
  }

  .height-33\%\@lg {
    height: 33.3333333333%;
  }

  .height-40\%\@lg {
    height: 40%;
  }

  .height-50\%\@lg {
    height: 50%;
  }

  .height-60\%\@lg {
    height: 60%;
  }

  .height-66\%\@lg {
    height: 66.6666666667%;
  }

  .height-70\%\@lg {
    height: 70%;
  }

  .height-75\%\@lg {
    height: 75%;
  }

  .height-80\%\@lg {
    height: 80%;
  }

  .height-90\%\@lg {
    height: 90%;
  }

  .height-100\%\@lg {
    height: 100%;
  }

  .height-100vh\@lg {
    height: 100vh;
  }

  .height-auto\@lg {
    height: auto;
  }

  .max-width-xxxxs\@lg {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@lg {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@lg {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@lg {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@lg {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@lg {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@lg {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@lg {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@lg {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@lg {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@lg {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@lg {
    max-width: 100%;
  }

  .max-width-none\@lg {
    max-width: none;
  }

  .position-relative\@lg {
    position: relative;
  }

  .position-absolute\@lg {
    position: absolute;
  }

  .position-fixed\@lg {
    position: fixed;
  }

  .position-sticky\@lg {
    position: sticky;
  }

  .position-static\@lg {
    position: static;
  }

  .inset-0\@lg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .top-0\@lg {
    top: 0;
  }

  .top-50\%\@lg {
    top: 50%;
  }

  .top-xxxxs\@lg {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@lg {
    top: var(--space-xxxs);
  }

  .top-xxs\@lg {
    top: var(--space-xxs);
  }

  .top-xs\@lg {
    top: var(--space-xs);
  }

  .top-sm\@lg {
    top: var(--space-sm);
  }

  .top-md\@lg {
    top: var(--space-md);
  }

  .top-lg\@lg {
    top: var(--space-lg);
  }

  .top-xl\@lg {
    top: var(--space-xl);
  }

  .top-xxl\@lg {
    top: var(--space-xxl);
  }

  .top-xxxl\@lg {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@lg {
    top: var(--space-xxxxl);
  }

  .bottom-0\@lg {
    bottom: 0;
  }

  .bottom-50\%\@lg {
    bottom: 50%;
  }

  .bottom-xxxxs\@lg {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@lg {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@lg {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@lg {
    bottom: var(--space-xs);
  }

  .bottom-sm\@lg {
    bottom: var(--space-sm);
  }

  .bottom-md\@lg {
    bottom: var(--space-md);
  }

  .bottom-lg\@lg {
    bottom: var(--space-lg);
  }

  .bottom-xl\@lg {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@lg {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@lg {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@lg {
    bottom: var(--space-xxxxl);
  }

  .right-0\@lg {
    right: 0;
  }

  .right-50\%\@lg {
    right: 50%;
  }

  .right-xxxxs\@lg {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@lg {
    right: var(--space-xxxs);
  }

  .right-xxs\@lg {
    right: var(--space-xxs);
  }

  .right-xs\@lg {
    right: var(--space-xs);
  }

  .right-sm\@lg {
    right: var(--space-sm);
  }

  .right-md\@lg {
    right: var(--space-md);
  }

  .right-lg\@lg {
    right: var(--space-lg);
  }

  .right-xl\@lg {
    right: var(--space-xl);
  }

  .right-xxl\@lg {
    right: var(--space-xxl);
  }

  .right-xxxl\@lg {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@lg {
    right: var(--space-xxxxl);
  }

  .left-0\@lg {
    left: 0;
  }

  .left-50\%\@lg {
    left: 50%;
  }

  .left-xxxxs\@lg {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@lg {
    left: var(--space-xxxs);
  }

  .left-xxs\@lg {
    left: var(--space-xxs);
  }

  .left-xs\@lg {
    left: var(--space-xs);
  }

  .left-sm\@lg {
    left: var(--space-sm);
  }

  .left-md\@lg {
    left: var(--space-md);
  }

  .left-lg\@lg {
    left: var(--space-lg);
  }

  .left-xl\@lg {
    left: var(--space-xl);
  }

  .left-xxl\@lg {
    left: var(--space-xxl);
  }

  .left-xxxl\@lg {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@lg {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@lg {
    overflow: hidden;
  }

  .overflow-auto\@lg {
    overflow: auto;
  }

  .momentum-scrolling\@lg {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@lg {
    overscroll-behavior: contain;
  }

  .visible\@lg {
    visibility: visible;
  }

  .invisible\@lg {
    visibility: hidden;
  }
}
@media not all and (min-width: 80rem) {
  .display\@lg {
    display: none !important;
  }
}
@media (min-width: 90rem) {
  .flex\@xl {
    display: flex;
  }

  .inline-flex\@xl {
    display: inline-flex;
  }

  .flex-wrap\@xl {
    flex-wrap: wrap;
  }

  .flex-nowrap\@xl {
    flex-wrap: nowrap;
  }

  .flex-column\@xl {
    flex-direction: column;
  }

  .flex-column-reverse\@xl {
    flex-direction: column-reverse;
  }

  .flex-row\@xl {
    flex-direction: row;
  }

  .flex-row-reverse\@xl {
    flex-direction: row-reverse;
  }

  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@xl {
    flex-grow: 1;
  }

  .flex-grow-0\@xl {
    flex-grow: 0;
  }

  .flex-shrink\@xl {
    flex-shrink: 1;
  }

  .flex-shrink-0\@xl {
    flex-shrink: 0;
  }

  .flex-basis-0\@xl {
    flex-basis: 0;
  }

  .justify-start\@xl {
    justify-content: flex-start;
  }

  .justify-end\@xl {
    justify-content: flex-end;
  }

  .justify-center\@xl {
    justify-content: center;
  }

  .justify-between\@xl {
    justify-content: space-between;
  }

  .items-center\@xl {
    align-items: center;
  }

  .items-start\@xl {
    align-items: flex-start;
  }

  .items-end\@xl {
    align-items: flex-end;
  }

  .items-baseline\@xl {
    align-items: baseline;
  }

  .order-1\@xl {
    order: 1;
  }

  .order-2\@xl {
    order: 2;
  }

  .order-3\@xl {
    order: 3;
  }

  .block\@xl {
    display: block;
  }

  .inline-block\@xl {
    display: inline-block;
  }

  .inline\@xl {
    display: inline;
  }

  .contents\@xl {
    display: contents;
  }

  .hide\@xl {
    display: none !important;
  }

  .margin-xxxxs\@xl {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@xl {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@xl {
    margin: var(--space-xxs);
  }

  .margin-xs\@xl {
    margin: var(--space-xs);
  }

  .margin-sm\@xl {
    margin: var(--space-sm);
  }

  .margin-md\@xl {
    margin: var(--space-md);
  }

  .margin-lg\@xl {
    margin: var(--space-lg);
  }

  .margin-xl\@xl {
    margin: var(--space-xl);
  }

  .margin-xxl\@xl {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@xl {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@xl {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@xl {
    margin: auto;
  }

  .margin-0\@xl {
    margin: 0;
  }

  .margin-top-xxxxs\@xl {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@xl {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@xl {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@xl {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@xl {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@xl {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@xl {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@xl {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@xl {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@xl {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@xl {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@xl {
    margin-top: auto;
  }

  .margin-top-0\@xl {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@xl {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@xl {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@xl {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@xl {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@xl {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@xl {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@xl {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@xl {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@xl {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@xl {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@xl {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@xl {
    margin-bottom: auto;
  }

  .margin-bottom-0\@xl {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@xl {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@xl {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@xl {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@xl {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@xl {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@xl {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@xl {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@xl {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@xl {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@xl {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@xl {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@xl {
    margin-right: auto;
  }

  .margin-right-0\@xl {
    margin-right: 0;
  }

  .margin-left-xxxxs\@xl {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@xl {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@xl {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@xl {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@xl {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@xl {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@xl {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@xl {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@xl {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@xl {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@xl {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@xl {
    margin-left: auto;
  }

  .margin-left-0\@xl {
    margin-left: 0;
  }

  .margin-x-xxxxs\@xl {
    margin-left: var(--space-xxxxs);
    margin-right: var(--space-xxxxs);
  }

  .margin-x-xxxs\@xl {
    margin-left: var(--space-xxxs);
    margin-right: var(--space-xxxs);
  }

  .margin-x-xxs\@xl {
    margin-left: var(--space-xxs);
    margin-right: var(--space-xxs);
  }

  .margin-x-xs\@xl {
    margin-left: var(--space-xs);
    margin-right: var(--space-xs);
  }

  .margin-x-sm\@xl {
    margin-left: var(--space-sm);
    margin-right: var(--space-sm);
  }

  .margin-x-md\@xl {
    margin-left: var(--space-md);
    margin-right: var(--space-md);
  }

  .margin-x-lg\@xl {
    margin-left: var(--space-lg);
    margin-right: var(--space-lg);
  }

  .margin-x-xl\@xl {
    margin-left: var(--space-xl);
    margin-right: var(--space-xl);
  }

  .margin-x-xxl\@xl {
    margin-left: var(--space-xxl);
    margin-right: var(--space-xxl);
  }

  .margin-x-xxxl\@xl {
    margin-left: var(--space-xxxl);
    margin-right: var(--space-xxxl);
  }

  .margin-x-xxxxl\@xl {
    margin-left: var(--space-xxxxl);
    margin-right: var(--space-xxxxl);
  }

  .margin-x-auto\@xl {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@xl {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@xl {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@xl {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@xl {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@xl {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@xl {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@xl {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@xl {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@xl {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@xl {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@xl {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@xl {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@xl {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@xl {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@xl {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@xl {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@xl {
    padding: var(--space-xxs);
  }

  .padding-xs\@xl {
    padding: var(--space-xs);
  }

  .padding-sm\@xl {
    padding: var(--space-sm);
  }

  .padding-md\@xl {
    padding: var(--space-md);
  }

  .padding-lg\@xl {
    padding: var(--space-lg);
  }

  .padding-xl\@xl {
    padding: var(--space-xl);
  }

  .padding-xxl\@xl {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@xl {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@xl {
    padding: var(--space-xxxxl);
  }

  .padding-0\@xl {
    padding: 0;
  }

  .padding-component\@xl {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@xl {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@xl {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@xl {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@xl {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@xl {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@xl {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@xl {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@xl {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@xl {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@xl {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@xl {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@xl {
    padding-top: 0;
  }

  .padding-top-component\@xl {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@xl {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@xl {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@xl {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@xl {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@xl {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@xl {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@xl {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@xl {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@xl {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@xl {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@xl {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@xl {
    padding-bottom: 0;
  }

  .padding-bottom-component\@xl {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@xl {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@xl {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@xl {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@xl {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@xl {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@xl {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@xl {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@xl {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@xl {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@xl {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@xl {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@xl {
    padding-right: 0;
  }

  .padding-right-component\@xl {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@xl {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@xl {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@xl {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@xl {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@xl {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@xl {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@xl {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@xl {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@xl {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@xl {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@xl {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@xl {
    padding-left: 0;
  }

  .padding-left-component\@xl {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@xl {
    padding-left: var(--space-xxxxs);
    padding-right: var(--space-xxxxs);
  }

  .padding-x-xxxs\@xl {
    padding-left: var(--space-xxxs);
    padding-right: var(--space-xxxs);
  }

  .padding-x-xxs\@xl {
    padding-left: var(--space-xxs);
    padding-right: var(--space-xxs);
  }

  .padding-x-xs\@xl {
    padding-left: var(--space-xs);
    padding-right: var(--space-xs);
  }

  .padding-x-sm\@xl {
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
  }

  .padding-x-md\@xl {
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }

  .padding-x-lg\@xl {
    padding-left: var(--space-lg);
    padding-right: var(--space-lg);
  }

  .padding-x-xl\@xl {
    padding-left: var(--space-xl);
    padding-right: var(--space-xl);
  }

  .padding-x-xxl\@xl {
    padding-left: var(--space-xxl);
    padding-right: var(--space-xxl);
  }

  .padding-x-xxxl\@xl {
    padding-left: var(--space-xxxl);
    padding-right: var(--space-xxxl);
  }

  .padding-x-xxxxl\@xl {
    padding-left: var(--space-xxxxl);
    padding-right: var(--space-xxxxl);
  }

  .padding-x-0\@xl {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@xl {
    padding-left: var(--component-padding);
    padding-right: var(--component-padding);
  }

  .padding-y-xxxxs\@xl {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@xl {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@xl {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@xl {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@xl {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@xl {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@xl {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@xl {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@xl {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@xl {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@xl {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@xl {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@xl {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@xl {
    text-align: center;
  }

  .text-left\@xl {
    text-align: left;
  }

  .text-right\@xl {
    text-align: right;
  }

  .text-justify\@xl {
    text-align: justify;
  }

  .text-xs\@xl {
    font-size: var(--text-xs, 0.6875rem);
  }

  .text-sm\@xl {
    font-size: var(--text-sm, 0.75rem);
  }

  .text-base\@xl {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@xl {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@xl {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@xl {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@xl {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@xl {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@xl {
    font-size: var(--text-xxxxl, 3rem);
  }

  .width-xxxxs\@xl {
    width: var(--size-xxxxs, 0.25rem);
  }

  .width-xxxs\@xl {
    width: var(--size-xxxs, 0.5rem);
  }

  .width-xxs\@xl {
    width: var(--size-xxs, 0.75rem);
  }

  .width-xs\@xl {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@xl {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@xl {
    width: var(--size-md, 2rem);
  }

  .width-lg\@xl {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@xl {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@xl {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@xl {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@xl {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@xl {
    width: 0;
  }

  .width-10\%\@xl {
    width: 10%;
  }

  .width-20\%\@xl {
    width: 20%;
  }

  .width-25\%\@xl {
    width: 25%;
  }

  .width-30\%\@xl {
    width: 30%;
  }

  .width-33\%\@xl {
    width: 33.3333333333%;
  }

  .width-40\%\@xl {
    width: 40%;
  }

  .width-50\%\@xl {
    width: 50%;
  }

  .width-60\%\@xl {
    width: 60%;
  }

  .width-66\%\@xl {
    width: 66.6666666667%;
  }

  .width-70\%\@xl {
    width: 70%;
  }

  .width-75\%\@xl {
    width: 75%;
  }

  .width-80\%\@xl {
    width: 80%;
  }

  .width-90\%\@xl {
    width: 90%;
  }

  .width-100\%\@xl {
    width: 100%;
  }

  .width-100vw\@xl {
    width: 100vw;
  }

  .width-auto\@xl {
    width: auto;
  }

  .height-xxxxs\@xl {
    height: var(--size-xxxxs, 0.25rem);
  }

  .height-xxxs\@xl {
    height: var(--size-xxxs, 0.5rem);
  }

  .height-xxs\@xl {
    height: var(--size-xxs, 0.75rem);
  }

  .height-xs\@xl {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@xl {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@xl {
    height: var(--size-md, 2rem);
  }

  .height-lg\@xl {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@xl {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@xl {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@xl {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@xl {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@xl {
    height: 0;
  }

  .height-10\%\@xl {
    height: 10%;
  }

  .height-20\%\@xl {
    height: 20%;
  }

  .height-25\%\@xl {
    height: 25%;
  }

  .height-30\%\@xl {
    height: 30%;
  }

  .height-33\%\@xl {
    height: 33.3333333333%;
  }

  .height-40\%\@xl {
    height: 40%;
  }

  .height-50\%\@xl {
    height: 50%;
  }

  .height-60\%\@xl {
    height: 60%;
  }

  .height-66\%\@xl {
    height: 66.6666666667%;
  }

  .height-70\%\@xl {
    height: 70%;
  }

  .height-75\%\@xl {
    height: 75%;
  }

  .height-80\%\@xl {
    height: 80%;
  }

  .height-90\%\@xl {
    height: 90%;
  }

  .height-100\%\@xl {
    height: 100%;
  }

  .height-100vh\@xl {
    height: 100vh;
  }

  .height-auto\@xl {
    height: auto;
  }

  .max-width-xxxxs\@xl {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@xl {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@xl {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@xl {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@xl {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@xl {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@xl {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@xl {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@xl {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@xl {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@xl {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@xl {
    max-width: 100%;
  }

  .max-width-none\@xl {
    max-width: none;
  }

  .position-relative\@xl {
    position: relative;
  }

  .position-absolute\@xl {
    position: absolute;
  }

  .position-fixed\@xl {
    position: fixed;
  }

  .position-sticky\@xl {
    position: sticky;
  }

  .position-static\@xl {
    position: static;
  }

  .inset-0\@xl {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .top-0\@xl {
    top: 0;
  }

  .top-50\%\@xl {
    top: 50%;
  }

  .top-xxxxs\@xl {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@xl {
    top: var(--space-xxxs);
  }

  .top-xxs\@xl {
    top: var(--space-xxs);
  }

  .top-xs\@xl {
    top: var(--space-xs);
  }

  .top-sm\@xl {
    top: var(--space-sm);
  }

  .top-md\@xl {
    top: var(--space-md);
  }

  .top-lg\@xl {
    top: var(--space-lg);
  }

  .top-xl\@xl {
    top: var(--space-xl);
  }

  .top-xxl\@xl {
    top: var(--space-xxl);
  }

  .top-xxxl\@xl {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@xl {
    top: var(--space-xxxxl);
  }

  .bottom-0\@xl {
    bottom: 0;
  }

  .bottom-50\%\@xl {
    bottom: 50%;
  }

  .bottom-xxxxs\@xl {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@xl {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@xl {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@xl {
    bottom: var(--space-xs);
  }

  .bottom-sm\@xl {
    bottom: var(--space-sm);
  }

  .bottom-md\@xl {
    bottom: var(--space-md);
  }

  .bottom-lg\@xl {
    bottom: var(--space-lg);
  }

  .bottom-xl\@xl {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@xl {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@xl {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@xl {
    bottom: var(--space-xxxxl);
  }

  .right-0\@xl {
    right: 0;
  }

  .right-50\%\@xl {
    right: 50%;
  }

  .right-xxxxs\@xl {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@xl {
    right: var(--space-xxxs);
  }

  .right-xxs\@xl {
    right: var(--space-xxs);
  }

  .right-xs\@xl {
    right: var(--space-xs);
  }

  .right-sm\@xl {
    right: var(--space-sm);
  }

  .right-md\@xl {
    right: var(--space-md);
  }

  .right-lg\@xl {
    right: var(--space-lg);
  }

  .right-xl\@xl {
    right: var(--space-xl);
  }

  .right-xxl\@xl {
    right: var(--space-xxl);
  }

  .right-xxxl\@xl {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@xl {
    right: var(--space-xxxxl);
  }

  .left-0\@xl {
    left: 0;
  }

  .left-50\%\@xl {
    left: 50%;
  }

  .left-xxxxs\@xl {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@xl {
    left: var(--space-xxxs);
  }

  .left-xxs\@xl {
    left: var(--space-xxs);
  }

  .left-xs\@xl {
    left: var(--space-xs);
  }

  .left-sm\@xl {
    left: var(--space-sm);
  }

  .left-md\@xl {
    left: var(--space-md);
  }

  .left-lg\@xl {
    left: var(--space-lg);
  }

  .left-xl\@xl {
    left: var(--space-xl);
  }

  .left-xxl\@xl {
    left: var(--space-xxl);
  }

  .left-xxxl\@xl {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@xl {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@xl {
    overflow: hidden;
  }

  .overflow-auto\@xl {
    overflow: auto;
  }

  .momentum-scrolling\@xl {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@xl {
    overscroll-behavior: contain;
  }

  .visible\@xl {
    visibility: visible;
  }

  .invisible\@xl {
    visibility: hidden;
  }
}
@media not all and (min-width: 90rem) {
  .display\@xl {
    display: none !important;
  }
}
:root,
[data-theme=default] {
  --color-primary-darker: hsl(51, 97%, 39%);
  --color-primary-darker-h: 51;
  --color-primary-darker-s: 97%;
  --color-primary-darker-l: 39%;
  --color-primary-dark: hsl(51, 97%, 49%);
  --color-primary-dark-h: 51;
  --color-primary-dark-s: 97%;
  --color-primary-dark-l: 49%;
  --color-primary: hsl(51, 97%, 59%);
  --color-primary-h: 51;
  --color-primary-s: 97%;
  --color-primary-l: 59%;
  --color-primary-light: hsl(51, 97%, 69%);
  --color-primary-light-h: 51;
  --color-primary-light-s: 97%;
  --color-primary-light-l: 69%;
  --color-primary-lighter: hsl(51, 97%, 79%);
  --color-primary-lighter-h: 51;
  --color-primary-lighter-s: 97%;
  --color-primary-lighter-l: 79%;
  --color-accent-darker: hsl(356, 95%, 26%);
  --color-accent-darker-h: 356;
  --color-accent-darker-s: 95%;
  --color-accent-darker-l: 26%;
  --color-accent-dark: hsl(356, 95%, 36%);
  --color-accent-dark-h: 356;
  --color-accent-dark-s: 95%;
  --color-accent-dark-l: 36%;
  --color-accent: hsl(356, 95%, 46%);
  --color-accent-h: 356;
  --color-accent-s: 95%;
  --color-accent-l: 46%;
  --color-accent-light: hsl(356, 95%, 56%);
  --color-accent-light-h: 356;
  --color-accent-light-s: 95%;
  --color-accent-light-l: 56%;
  --color-accent-lighter: hsl(356, 95%, 66%);
  --color-accent-lighter-h: 356;
  --color-accent-lighter-s: 95%;
  --color-accent-lighter-l: 66%;
  --color-black: hsl(204, 28%, 7%);
  --color-black-h: 204;
  --color-black-s: 28%;
  --color-black-l: 7%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-warning-darker: hsl(46, 100%, 47%);
  --color-warning-darker-h: 46;
  --color-warning-darker-s: 100%;
  --color-warning-darker-l: 47%;
  --color-warning-dark: hsl(46, 100%, 50%);
  --color-warning-dark-h: 46;
  --color-warning-dark-s: 100%;
  --color-warning-dark-l: 50%;
  --color-warning: hsl(46, 100%, 61%);
  --color-warning-h: 46;
  --color-warning-s: 100%;
  --color-warning-l: 61%;
  --color-warning-light: hsl(46, 100%, 71%);
  --color-warning-light-h: 46;
  --color-warning-light-s: 100%;
  --color-warning-light-l: 71%;
  --color-warning-lighter: hsl(46, 100%, 80%);
  --color-warning-lighter-h: 46;
  --color-warning-lighter-s: 100%;
  --color-warning-lighter-l: 80%;
  --color-success-darker: hsl(122, 50%, 47%);
  --color-success-darker-h: 122;
  --color-success-darker-s: 50%;
  --color-success-darker-l: 47%;
  --color-success-dark: hsl(122, 50%, 52%);
  --color-success-dark-h: 122;
  --color-success-dark-s: 50%;
  --color-success-dark-l: 52%;
  --color-success: hsl(122, 50%, 60%);
  --color-success-h: 122;
  --color-success-s: 50%;
  --color-success-l: 60%;
  --color-success-light: hsl(122, 50%, 69%);
  --color-success-light-h: 122;
  --color-success-light-s: 50%;
  --color-success-light-l: 69%;
  --color-success-lighter: hsl(122, 50%, 76%);
  --color-success-lighter-h: 122;
  --color-success-lighter-s: 50%;
  --color-success-lighter-l: 76%;
  --color-error-darker: hsl(342, 89%, 38%);
  --color-error-darker-h: 342;
  --color-error-darker-s: 89%;
  --color-error-darker-l: 38%;
  --color-error-dark: hsl(342, 89%, 43%);
  --color-error-dark-h: 342;
  --color-error-dark-s: 89%;
  --color-error-dark-l: 43%;
  --color-error: hsl(342, 89%, 48%);
  --color-error-h: 342;
  --color-error-s: 89%;
  --color-error-l: 48%;
  --color-error-light: hsl(342, 89%, 56%);
  --color-error-light-h: 342;
  --color-error-light-s: 89%;
  --color-error-light-l: 56%;
  --color-error-lighter: hsl(342, 89%, 62%);
  --color-error-lighter-h: 342;
  --color-error-lighter-s: 89%;
  --color-error-lighter-l: 62%;
  --color-bg-darker: hsl(210, 4%, 89%);
  --color-bg-darker-h: 210;
  --color-bg-darker-s: 4%;
  --color-bg-darker-l: 89%;
  --color-bg-dark: hsl(180, 3%, 94%);
  --color-bg-dark-h: 180;
  --color-bg-dark-s: 3%;
  --color-bg-dark-l: 94%;
  --color-bg: hsl(0, 0%, 100%);
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-bg-light: hsl(180, 3%, 100%);
  --color-bg-light-h: 180;
  --color-bg-light-s: 3%;
  --color-bg-light-l: 100%;
  --color-bg-lighter: hsl(210, 4%, 100%);
  --color-bg-lighter-h: 210;
  --color-bg-lighter-s: 4%;
  --color-bg-lighter-l: 100%;
  --color-contrast-lower: hsl(180, 1%, 84%);
  --color-contrast-lower-h: 180;
  --color-contrast-lower-s: 1%;
  --color-contrast-lower-l: 84%;
  --color-contrast-low: hsl(210, 2%, 64%);
  --color-contrast-low-h: 210;
  --color-contrast-low-s: 2%;
  --color-contrast-low-l: 64%;
  --color-contrast-medium: hsl(204, 2%, 46%);
  --color-contrast-medium-h: 204;
  --color-contrast-medium-s: 2%;
  --color-contrast-medium-l: 46%;
  --color-contrast-high: hsl(210, 7%, 21%);
  --color-contrast-high-h: 210;
  --color-contrast-high-s: 7%;
  --color-contrast-high-l: 21%;
  --color-contrast-higher: hsl(204, 28%, 7%);
  --color-contrast-higher-h: 204;
  --color-contrast-higher-s: 28%;
  --color-contrast-higher-l: 7%;
}

[data-theme] {
  background-color: var(--color-bg);
  color: var(--color-contrast-high);
}

@media (prefers-color-scheme: dark) {
  @supports (--css: variables) {
    [data-theme=default] {
      --color-primary-darker: hsl(51, 97%, 39%);
      --color-primary-darker-h: 51;
      --color-primary-darker-s: 97%;
      --color-primary-darker-l: 39%;
      --color-primary-dark: hsl(51, 97%, 49%);
      --color-primary-dark-h: 51;
      --color-primary-dark-s: 97%;
      --color-primary-dark-l: 49%;
      --color-primary: hsl(51, 97%, 59%);
      --color-primary-h: 51;
      --color-primary-s: 97%;
      --color-primary-l: 59%;
      --color-primary-light: hsl(51, 97%, 69%);
      --color-primary-light-h: 51;
      --color-primary-light-s: 97%;
      --color-primary-light-l: 69%;
      --color-primary-lighter: hsl(51, 97%, 79%);
      --color-primary-lighter-h: 51;
      --color-primary-lighter-s: 97%;
      --color-primary-lighter-l: 79%;
      --color-accent-darker: hsl(356, 95%, 26%);
      --color-accent-darker-h: 356;
      --color-accent-darker-s: 95%;
      --color-accent-darker-l: 26%;
      --color-accent-dark: hsl(356, 95%, 36%);
      --color-accent-dark-h: 356;
      --color-accent-dark-s: 95%;
      --color-accent-dark-l: 36%;
      --color-accent: hsl(356, 95%, 46%);
      --color-accent-h: 356;
      --color-accent-s: 95%;
      --color-accent-l: 46%;
      --color-accent-light: hsl(356, 95%, 56%);
      --color-accent-light-h: 356;
      --color-accent-light-s: 95%;
      --color-accent-light-l: 56%;
      --color-accent-lighter: hsl(356, 95%, 66%);
      --color-accent-lighter-h: 356;
      --color-accent-lighter-s: 95%;
      --color-accent-lighter-l: 66%;
      --color-black: hsl(204, 28%, 7%);
      --color-black-h: 204;
      --color-black-s: 28%;
      --color-black-l: 7%;
      --color-white: hsl(0, 0%, 100%);
      --color-white-h: 0;
      --color-white-s: 0%;
      --color-white-l: 100%;
      --color-warning-darker: hsl(46, 100%, 47%);
      --color-warning-darker-h: 46;
      --color-warning-darker-s: 100%;
      --color-warning-darker-l: 47%;
      --color-warning-dark: hsl(46, 100%, 50%);
      --color-warning-dark-h: 46;
      --color-warning-dark-s: 100%;
      --color-warning-dark-l: 50%;
      --color-warning: hsl(46, 100%, 61%);
      --color-warning-h: 46;
      --color-warning-s: 100%;
      --color-warning-l: 61%;
      --color-warning-light: hsl(46, 100%, 71%);
      --color-warning-light-h: 46;
      --color-warning-light-s: 100%;
      --color-warning-light-l: 71%;
      --color-warning-lighter: hsl(46, 100%, 80%);
      --color-warning-lighter-h: 46;
      --color-warning-lighter-s: 100%;
      --color-warning-lighter-l: 80%;
      --color-success-darker: hsl(122, 50%, 47%);
      --color-success-darker-h: 122;
      --color-success-darker-s: 50%;
      --color-success-darker-l: 47%;
      --color-success-dark: hsl(122, 50%, 52%);
      --color-success-dark-h: 122;
      --color-success-dark-s: 50%;
      --color-success-dark-l: 52%;
      --color-success: hsl(122, 50%, 60%);
      --color-success-h: 122;
      --color-success-s: 50%;
      --color-success-l: 60%;
      --color-success-light: hsl(122, 50%, 69%);
      --color-success-light-h: 122;
      --color-success-light-s: 50%;
      --color-success-light-l: 69%;
      --color-success-lighter: hsl(122, 50%, 76%);
      --color-success-lighter-h: 122;
      --color-success-lighter-s: 50%;
      --color-success-lighter-l: 76%;
      --color-error-darker: hsl(342, 92%, 41%);
      --color-error-darker-h: 342;
      --color-error-darker-s: 92%;
      --color-error-darker-l: 41%;
      --color-error-dark: hsl(342, 92%, 47%);
      --color-error-dark-h: 342;
      --color-error-dark-s: 92%;
      --color-error-dark-l: 47%;
      --color-error: hsl(342, 92%, 54%);
      --color-error-h: 342;
      --color-error-s: 92%;
      --color-error-l: 54%;
      --color-error-light: hsl(342, 92%, 60%);
      --color-error-light-h: 342;
      --color-error-light-s: 92%;
      --color-error-light-l: 60%;
      --color-error-lighter: hsl(342, 92%, 65%);
      --color-error-lighter-h: 342;
      --color-error-lighter-s: 92%;
      --color-error-lighter-l: 65%;
      --color-bg-darker: hsl(204, 15%, 6%);
      --color-bg-darker-h: 204;
      --color-bg-darker-s: 15%;
      --color-bg-darker-l: 6%;
      --color-bg-dark: hsl(203, 18%, 9%);
      --color-bg-dark-h: 203;
      --color-bg-dark-s: 18%;
      --color-bg-dark-l: 9%;
      --color-bg: hsl(203, 24%, 13%);
      --color-bg-h: 203;
      --color-bg-s: 24%;
      --color-bg-l: 13%;
      --color-bg-light: hsl(203, 18%, 17%);
      --color-bg-light-h: 203;
      --color-bg-light-s: 18%;
      --color-bg-light-l: 17%;
      --color-bg-lighter: hsl(204, 15%, 20%);
      --color-bg-lighter-h: 204;
      --color-bg-lighter-s: 15%;
      --color-bg-lighter-l: 20%;
      --color-contrast-lower: hsl(208, 12%, 24%);
      --color-contrast-lower-h: 208;
      --color-contrast-lower-s: 12%;
      --color-contrast-lower-l: 24%;
      --color-contrast-low: hsl(208, 6%, 40%);
      --color-contrast-low-h: 208;
      --color-contrast-low-s: 6%;
      --color-contrast-low-l: 40%;
      --color-contrast-medium: hsl(213, 5%, 56%);
      --color-contrast-medium-h: 213;
      --color-contrast-medium-s: 5%;
      --color-contrast-medium-l: 56%;
      --color-contrast-high: hsl(223, 8%, 82%);
      --color-contrast-high-h: 223;
      --color-contrast-high-s: 8%;
      --color-contrast-high-l: 82%;
      --color-contrast-higher: hsl(240, 100%, 99%);
      --color-contrast-higher-h: 240;
      --color-contrast-higher-s: 100%;
      --color-contrast-higher-l: 99%;
    }
  }
}
@media (min-width: 64rem) {
  :root, * {
    --space-xxxxs: calc(0.1875 * var(--space-unit));
    --space-xxxs: calc(0.375 * var(--space-unit));
    --space-xxs: calc(0.5625 * var(--space-unit));
    --space-xs: calc(0.75 * var(--space-unit));
    --space-sm: calc(1.125 * var(--space-unit));
    --space-md: calc(2 * var(--space-unit));
    --space-lg: calc(3.125 * var(--space-unit));
    --space-xl: calc(5.125 * var(--space-unit));
    --space-xxl: calc(8.25 * var(--space-unit));
    --space-xxxl: calc(13.25 * var(--space-unit));
    --space-xxxxl: calc(21.5 * var(--space-unit));
  }
}
:root {
  --radius: 0.25em;
}

.hover\:reduce-opacity {
  opacity: 1;
  transition: all 0.3s ease;
}
.hover\:reduce-opacity:hover {
  opacity: 0.8;
}

.hover\:scale {
  transition: transform 0.3s var(--ease-out-back);
}
.hover\:scale:hover {
  transform: scale(1.1);
}

.hover\:elevate {
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
}
.hover\:elevate:hover {
  box-shadow: var(--shadow-md);
}

.link-subtle {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
.link-subtle:hover {
  color: var(--color-primary);
}

:root {
  --font-primary: EnerGo, Arial, sans-serif;
  --text-base-size: 1rem;
  --text-scale-ratio: 1.2;
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 1;
  --text-unit: var(--text-base-size);
}

:root,
* {
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

@media (min-width: 64rem) {
  :root {
    --text-base-size: 1.25rem;
    --text-scale-ratio: 1.25;
  }
}
body {
  font-family: var(--font-primary);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-primary);
  --heading-font-weight: 700;
}

.font-primary {
  font-family: var(--font-primary);
}

mark {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
  color: inherit;
}

.text-component {
  --line-height-multiplier: 1;
  --text-space-y-multiplier: 1;
}
.text-component > * {
  --text-unit: 1em;
  --space-unit: 1em;
}
.text-component blockquote {
  padding-left: 1em;
  border-left: 4px solid var(--color-contrast-lower);
  font-style: italic;
}
.text-component hr {
  background: var(--color-contrast-lower);
  height: 1px;
}
.text-component figcaption {
  font-size: var(--text-sm);
  color: var(--color-contrast-low);
}

.article {
  --body-line-height: 1.58;
  --text-space-y-multiplier: 1.2;
}

:root {
  --btn-font-size: 1em;
  --btn-padding-x: var(--space-sm);
  --btn-padding-y: var(--space-xxs);
  --btn-radius: var(--radius-md);
}

.btn {
  background: var(--color-bg-dark);
  color: var(--color-contrast-higher);
  cursor: pointer;
  text-decoration: none;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.2s ease;
  will-change: transform;
}
.btn:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.15);
  outline: none;
}
.btn:active {
  transform: translateY(2px);
}

.btn--primary {
  background: var(--color-primary);
  color: var(--color-black);
  box-shadow: inset 0px 1px 0px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.15), var(--shadow-xs);
  font-weight: 700;
}
.btn--primary:hover {
  background: var(--color-primary-light);
  box-shadow: inset 0px 1px 0px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.15), var(--shadow-sm);
}
.btn--primary:focus {
  box-shadow: inset 0px 1px 0px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.15), 0px 0px 0px 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}

.btn--subtle {
  background: var(--color-bg-light);
  color: var(--color-contrast-higher);
  font-weight: 700;
}
.btn--subtle:hover {
  background: var(--color-bg-lighter);
  box-shadow: inset 0px 0px 0px 1px var(--color-contrast-lower), var(--shadow-sm);
}

.btn--accent {
  background: var(--color-accent);
  color: var(--color-white);
  box-shadow: inset 0px 1px 0px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.15), var(--shadow-xs);
}
.btn--accent:hover {
  background: var(--color-accent-light);
  box-shadow: inset 0px 1px 0px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.15), var(--shadow-sm);
}
.btn--accent:focus {
  box-shadow: inset 0px 1px 0px hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.15), 0px 0px 0px 2px hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
}

.btn--disabled,
.btn[disabled],
.btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn--sm {
  font-size: 0.8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
}

:root {
  --form-control-font-size: 1em;
  --form-control-padding-x: var(--space-xs);
  --form-control-padding-y: var(--space-xxs);
  --form-control-radius: var(--radius-md);
}

.form-control {
  background: var(--color-bg-dark);
  line-height: 1.2;
  box-shadow: inset 0px 0px 0px 1px var(--color-contrast-lower);
  transition: all 0.2s ease;
}
.form-control::placeholder {
  opacity: 1;
  color: var(--color-contrast-low);
}
.form-control:focus {
  background: var(--color-bg);
  box-shadow: inset 0px 0px 0px 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0px 0px 0px 2px var(--color-primary), var(--shadow-sm);
  outline: none;
}

.form-control--disabled, .form-control[disabled], .form-control[readonly] {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-control[aria-invalid=true], .form-control.form-control--error {
  box-shadow: inset 0px 0px 0px 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0px 0px 0px 2px var(--color-error);
}
.form-control[aria-invalid=true]:focus, .form-control.form-control--error:focus {
  box-shadow: inset 0px 0px 0px 1px hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0), 0px 0px 0px 2px var(--color-error), var(--shadow-sm);
}

@media (min-width: 80rem) {
  .app {
    margin-left: 0;
  }
}
.app-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px !important;
  color: #fff;
  padding: 10px 5px !important;
  text-align: left;
  border-radius: 10px;
  text-decoration: none;
  font-family: "Lucida Grande", sans-serif;
  font-size: 10px;
  text-transform: uppercase;
}
.app-btn.blu {
  background-color: #101010;
  transition: background-color 0.25s linear;
}
.app-btn.blu:hover {
  background-color: #454545;
}
.app-btn.apple {
  text-transform: none;
  width: 140px !important;
}
.app-btn i {
  width: 20%;
  text-align: center;
  font-size: 28px;
  margin-right: 7px;
}
.app-btn .big-txt {
  font-size: 17px;
  text-transform: capitalize;
}

@font-face {
  font-family: "EnerGo";
  src: url("../fonts/Radomir-Tinkov-Qanel.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "EnerGo";
  src: url("../fonts/Radomir-Tinkov-Qanel.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "EnerGo";
  src: url("../fonts/Radomir-Tinkov-Qanel.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "EnerGo";
  src: url("../fonts/Radomir-Tinkov-Qanel.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "EnerGo";
  src: url("../fonts/Radomir_Tinkov_-_Qan.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
.custom-section-divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.custom-section-divider svg {
  position: relative;
  display: block;
  height: auto;
  max-width: none;
  width: 102%;
  left: -1%;
}

.order-4 {
  order: 4;
}

/* -------------------------------- 

File#: _1_3d-cards
Title: 3D Cards
Descr: Images in a 3D space
Usage: codyhouse.co/license

-------------------------------- */
.td-cards {
  position: relative;
  z-index: 1;
}

.td-cards__card > img {
  display: block;
  width: 100%;
}

.td-cards--v1 {
  --card-ratio: 585/960;
  width: 90%;
  height: 0;
  padding-bottom: 82.0512820513%;
}
.td-cards--v1 .td-cards__inner {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  perspective: 1000px;
}
.td-cards--v1 .td-cards__card {
  width: 50%;
  border-radius: 50px;
  pointer-events: auto;
  will-change: transform;
  transform: rotateX(60deg) rotateZ(-60deg);
  transition-property: transform, top;
  transition-timing-function: var(--ease-out);
}
.td-cards--v1 .td-cards__card:nth-child(1) {
  position: relative;
  z-index: 1;
  box-shadow: 0 0px 0.2px rgba(0, 0, 0, 0.02), 0 0px 0.5px rgba(0, 0, 0, 0.028), 0 0px 1px rgba(0, 0, 0, 0.035), 0 0px 1.8px rgba(0, 0, 0, 0.042), 0 0px 3.3px rgba(0, 0, 0, 0.05), 0 0px 8px rgba(0, 0, 0, 0.07);
}
.td-cards--v1 .td-cards__card:nth-child(2),
.td-cards--v1 .td-cards__card:nth-child(3) {
  position: absolute;
}
.td-cards--v1 .td-cards__card:nth-child(2) {
  z-index: 2;
  top: calc(-10% - var(--top-offset, 0%));
  box-shadow: 0 0px 1.1px rgba(0, 0, 0, 0.02), 0 0px 2.5px rgba(0, 0, 0, 0.028), 0 0px 4.8px rgba(0, 0, 0, 0.035), 0 0px 8.5px rgba(0, 0, 0, 0.042), 0 0px 15.9px rgba(0, 0, 0, 0.05), 0 0px 38px rgba(0, 0, 0, 0.07);
}
.td-cards--v1 .td-cards__card:nth-child(3) {
  z-index: 3;
  top: calc(-20% - var(--top-offset, 0%));
  box-shadow: 0 0px 2.4px rgba(0, 0, 0, 0.022), 0 0px 5.9px rgba(0, 0, 0, 0.032), 0 0px 11px rgba(0, 0, 0, 0.04), 0 0px 19.7px rgba(0, 0, 0, 0.048), 0 0px 36.8px rgba(0, 0, 0, 0.058), 0 0px 88px rgba(0, 0, 0, 0.08);
}

.td-cards--v2 {
  --card-ratio: 585/960;
  width: 100%;
  height: 0;
  padding-bottom: calc(50% / (var(--card-ratio)) + 10%);
}
.td-cards--v2 .td-cards__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 1500px;
  transform-style: preserve-3d;
}
.td-cards--v2 .td-cards__card {
  position: absolute;
  width: 50%;
  border-radius: 50px;
  box-shadow: var(--shadow-md);
  backface-visibility: hidden;
  transform: translate3d(0);
  will-change: transform;
  transition: transform 0.4s var(--ease-out);
}
.td-cards--v2 .td-cards__card:nth-child(1) {
  z-index: 1;
  transform: translateX(-30%) rotateY(45deg);
}
.td-cards--v2 .td-cards__card:nth-child(2) {
  z-index: 2;
  transform: translateX(30%) rotateY(45deg);
}
.td-cards--v2:hover .td-cards__card:nth-child(1) {
  transform: translateY(-5%) translateX(-30%) rotateY(0deg);
}
.td-cards--v2:hover .td-cards__card:nth-child(2) {
  transform: translateY(5%) translateX(30%) rotateY(0deg);
}

.td-cards--v3 {
  --card-ratio: 960/640;
  width: 100%;
  height: 0;
  padding-bottom: calc(100% / (var(--card-ratio)));
  perspective: 1500px;
}
.td-cards--v3 .td-cards__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform: rotateX(45deg);
  transform-style: preserve-3d;
  will-change: transform;
  transition: transform 0.4s var(--ease-out);
}
.td-cards--v3 .td-cards__card {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  border-radius: 50px;
  box-shadow: var(--shadow-md);
  will-change: transform;
  transition: transform 0.4s var(--ease-out);
}
.td-cards--v3 .td-cards__card:nth-child(1) {
  transform: translateZ(-50px);
  box-shadow: 0 0px 0.2px rgba(0, 0, 0, 0.02), 0 0px 0.5px rgba(0, 0, 0, 0.028), 0 0px 1px rgba(0, 0, 0, 0.035), 0 0px 1.8px rgba(0, 0, 0, 0.042), 0 0px 3.3px rgba(0, 0, 0, 0.05), 0 0px 8px rgba(0, 0, 0, 0.07);
}
.td-cards--v3 .td-cards__card:nth-child(2) {
  box-shadow: 0 0px 1.1px rgba(0, 0, 0, 0.02), 0 0px 2.5px rgba(0, 0, 0, 0.028), 0 0px 4.8px rgba(0, 0, 0, 0.035), 0 0px 8.5px rgba(0, 0, 0, 0.042), 0 0px 15.9px rgba(0, 0, 0, 0.05), 0 0px 38px rgba(0, 0, 0, 0.07);
}
.td-cards--v3 .td-cards__card:nth-child(3) {
  transform: translateZ(50px);
  box-shadow: 0 0px 2.4px rgba(0, 0, 0, 0.022), 0 0px 5.9px rgba(0, 0, 0, 0.032), 0 0px 11px rgba(0, 0, 0, 0.04), 0 0px 19.7px rgba(0, 0, 0, 0.048), 0 0px 36.8px rgba(0, 0, 0, 0.058), 0 0px 88px rgba(0, 0, 0, 0.08);
}
.td-cards--v3:hover .td-cards__inner {
  transform: rotateX(0deg);
}
.td-cards--v3:hover .td-cards__card:nth-child(1),
.td-cards--v3:hover .td-cards__card:nth-child(3) {
  transform: translateZ(0px);
}

.td-cards--v4,
.td-cards--v5 {
  --card-ratio: 960/640;
  width: 100%;
  height: 0;
  padding-bottom: calc(100% / (var(--card-ratio)) + 10%);
}
.td-cards--v4 .td-cards__inner,
.td-cards--v5 .td-cards__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: 1500px;
}
.td-cards--v4 .td-cards__card,
.td-cards--v5 .td-cards__card {
  border-radius: 50px;
  transform: var(--rotation);
  animation: td-card-v4-floating-anim 5s infinite;
}
.td-cards--v4 .td-cards__shadow,
.td-cards--v5 .td-cards__shadow {
  position: absolute;
  bottom: -0.1em;
  width: 100%;
  left: 0%;
  height: 0.2em;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  transform: scaleX(0.9);
  filter: blur(5px);
  animation: td-card-shadow-anim 5s infinite;
}
.td-cards--v4 .td-cards__shadow::before,
.td-cards--v5 .td-cards__shadow::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  background-color: rgba(0, 0, 0, 0.15);
  filter: blur(5px);
}

.td-cards--v4 .td-cards__card {
  --rotation: rotate3d(0.5, 0.9, 0, 20deg);
}

.td-cards--v5 .td-cards__card {
  --rotation: rotate3d(0.5, -0.9, 0, 20deg);
}

@keyframes td-card-v4-floating-anim {
  0%, 100% {
    transform: translateY(0) var(--rotation);
  }
  50% {
    transform: translateY(5%) var(--rotation);
  }
}
/* -------------------------------- 

File#: _1_accordion
Title: Accordion
Descr: Create stacked sections of content and allow the user to expand/collapse them
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --accordion-border-width: 1px;
  --accordion-border-color: var(--color-contrast-lower);
  --accordion-icon-size: 1em;
  --accordion-icon-stroke-width: 1.5px;
}

.accordion__item {
  border-style: solid;
  border-color: var(--accordion-border-color);
  border-bottom-width: var(--accordion-border-width);
}
.accordion__item:first-child {
  border-top-width: var(--accordion-border-width);
}

.accordion__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
}
.accordion__header .icon {
  --size: var(--accordion-icon-size);
}
.accordion__header .icon .icon__group {
  stroke-width: var(--accordion-icon-stroke-width);
}

.accordion__icon-arrow .icon__group,
.accordion__icon-arrow-v2 .icon__group,
.accordion__icon-plus .icon__group {
  will-change: transform;
  transform-origin: 8px 8px;
}
.accordion__icon-arrow .icon__group > *,
.accordion__icon-arrow-v2 .icon__group > *,
.accordion__icon-plus .icon__group > * {
  transform-origin: 8px 8px;
}

.accordion__icon-arrow .icon__group > *,
.accordion__icon-arrow-v2 .icon__group > * {
  stroke-dasharray: 17;
  transform: translateY(3px);
}
.accordion__icon-arrow .icon__group > *:first-child,
.accordion__icon-arrow-v2 .icon__group > *:first-child {
  stroke-dashoffset: 8.5;
}
.accordion__icon-arrow .icon__group > *:last-child,
.accordion__icon-arrow-v2 .icon__group > *:last-child {
  stroke-dashoffset: 8.5;
}

.accordion__icon-plus .icon__group {
  transform: rotate(-90deg);
}
.accordion__icon-plus .icon__group > *:first-child {
  transform: rotate(-90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group > *:first-child {
  transform: translateY(-3px) rotate(-90deg);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group > *:last-child {
  transform: translateY(-3px) rotate(90deg);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group {
  transform: rotate(-90deg);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group > *:first-child, .accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group *:last-child {
  stroke-dashoffset: 0;
  transform: translateY(0px);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-plus .icon__group {
  transform: rotate(0);
}
.accordion__item--is-open > .accordion__header > .accordion__icon-plus .icon__group > *:first-child {
  transform: rotate(0);
}

.js .accordion__panel {
  display: none;
  will-change: height;
  transform: translateZ(0px);
}
.js .accordion__item--is-open > .accordion__panel {
  display: block;
}

.accordion[data-animation=on] .accordion__item--is-open .accordion__panel > * {
  animation: accordion-entry-animation 0.4s var(--ease-out);
}
.accordion[data-animation=on] .accordion__icon-arrow .icon__group,
.accordion[data-animation=on] .accordion__icon-arrow-v2 .icon__group,
.accordion[data-animation=on] .accordion__icon-plus .icon__group {
  transition: transform 0.3s var(--ease-out);
}
.accordion[data-animation=on] .accordion__icon-arrow .icon__group > *,
.accordion[data-animation=on] .accordion__icon-arrow-v2 .icon__group > *,
.accordion[data-animation=on] .accordion__icon-plus .icon__group > * {
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition-timing-function: var(--ease-out);
}

@keyframes accordion-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
/* -------------------------------- 

File#: _1_anim-menu-btn
Title: Animated Menu Button
Descr: A menu button w/ a morphing icon
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --anim-menu-btn-size: 48px;
  --anim-menu-btn-transition-duration: .2s;
  --anim-menu-btn-icon-size: 32px;
  --anim-menu-btn-icon-stroke: 2px;
}

.anim-menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--anim-menu-btn-size);
  height: var(--anim-menu-btn-size);
}

.anim-menu-btn__icon {
  position: relative;
  display: block;
  font-size: var(--anim-menu-btn-icon-size);
  width: 1em;
  height: var(--anim-menu-btn-icon-stroke);
  color: inherit;
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  transform: scale(1);
}
.anim-menu-btn__icon::before, .anim-menu-btn__icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: inherit;
  border-radius: inherit;
}

.anim-menu-btn__icon--close {
  background-size: 100% 100%;
  will-change: transform, background-size;
  transition-property: transform, background-size;
  transition-duration: var(--anim-menu-btn-transition-duration, 0.2s);
}
.anim-menu-btn:active .anim-menu-btn__icon--close {
  transform: scale(0.9);
}
.anim-menu-btn__icon--close::before, .anim-menu-btn__icon--close::after {
  will-change: inherit;
  transition: inherit;
}
.anim-menu-btn__icon--close::before {
  transform: translateY(-0.25em) rotate(0);
}
.anim-menu-btn__icon--close::after {
  transform: translateY(0.25em) rotate(0);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--close {
  background-size: 0% 100%;
}
.anim-menu-btn--state-b .anim-menu-btn__icon--close::before {
  transform: translateY(0) rotate(45deg);
}
.anim-menu-btn--state-b .anim-menu-btn__icon--close::after {
  transform: translateY(0) rotate(-45deg);
}

.anim-menu-btn__icon--arrow-left,
.anim-menu-btn__icon--arrow-right,
.anim-menu-btn__icon--arrow-up,
.anim-menu-btn__icon--arrow-down {
  border-radius: 50em;
  will-change: transform;
  transition-property: transform;
  transition-duration: var(--anim-menu-btn-transition-duration, 0.2s);
}
.anim-menu-btn:active .anim-menu-btn__icon--arrow-left,
.anim-menu-btn:active .anim-menu-btn__icon--arrow-right,
.anim-menu-btn:active .anim-menu-btn__icon--arrow-up,
.anim-menu-btn:active .anim-menu-btn__icon--arrow-down {
  transform: scale(0.9);
}
.anim-menu-btn__icon--arrow-left::before, .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn__icon--arrow-down::before,
.anim-menu-btn__icon--arrow-down::after {
  transform-origin: calc(var(--anim-menu-btn-icon-stroke) / 2) 50%;
  will-change: transform, width;
  transition-property: transform, width;
  transition-duration: var(--anim-menu-btn-transition-duration, 0.2s);
}
.anim-menu-btn__icon--arrow-left::before,
.anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn__icon--arrow-down::before {
  transform: translateY(-0.25em) rotate(0);
}
.anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn__icon--arrow-down::after {
  transform: translateY(0.25em) rotate(0);
}

.anim-menu-btn__icon--arrow-right {
  transform: rotate(180deg);
}
.anim-menu-btn:active .anim-menu-btn__icon--arrow-right {
  transform: rotate(180deg) scale(0.9);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::before, .anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::after {
  width: 50%;
}
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::before {
  transform: translateY(0) rotate(-45deg);
}
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::after {
  transform: translateY(0) rotate(45deg);
}
.anim-menu-btn--state-b:active .anim-menu-btn__icon--arrow-up {
  transform: rotate(90deg) scale(0.9);
}
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up {
  transform: rotate(90deg);
}
.anim-menu-btn--state-b:active .anim-menu-btn__icon--arrow-down {
  transform: rotate(-90deg) scale(0.9);
}
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down {
  transform: rotate(-90deg);
}

/* -------------------------------- 

File#: _1_back-to-top
Title: Back to top
Descr: Component that triggers scroll-y to the top of the page
Usage: codyhouse.co/license

-------------------------------- */
.back-to-top {
  --size: 50px;
  width: var(--size);
  height: var(--size);
  display: flex;
  position: fixed;
  left: var(--space-md);
  bottom: var(--space-md);
  z-index: var(--z-index-fixed-element, 10);
  border-radius: 50%;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.9);
  transition: 0.2s;
}
.back-to-top:hover {
  background-color: var(--color-contrast-higher);
}
.back-to-top .icon {
  --size: 20px;
  display: block;
  margin: auto;
  color: var(--color-bg);
}

.js .back-to-top {
  visibility: hidden;
  opacity: 0;
}

.js .back-to-top--is-visible {
  visibility: visible;
  opacity: 1;
}

/* -------------------------------- 

File#: _1_bg-gradients
Title: Background Gradients
Descr: Customizable background gradients
Usage: codyhouse.co/license

-------------------------------- */
.bg-gradient-1, .bg-gradient-2, .bg-gradient-3, .bg-gradient-4, .bg-gradient-5 {
  background-repeat: no-repeat;
  background-position: center;
}
@media (prefers-reduced-motion: no-preference) {
  .bg-gradient-1[data-bg-animate=on], .bg-gradient-2[data-bg-animate=on], .bg-gradient-3[data-bg-animate=on], .bg-gradient-4[data-bg-animate=on], .bg-gradient-5[data-bg-animate=on] {
    will-change: background-position;
  }
}

.bg-gradient-1 {
  background-color: #0E131F;
  background-image: radial-gradient(ellipse at 90% 30%, #0E131F 20%, rgba(14, 19, 31, 0) 50%), radial-gradient(ellipse at 70% 0%, #FF0035 0%, rgba(255, 0, 53, 0) 50%), radial-gradient(ellipse at 20% 10%, #38405F 10%, rgba(56, 64, 95, 0) 70%), radial-gradient(ellipse at 50% 100%, #FF0035 0%, rgba(255, 0, 53, 0) 50%);
  background-size: 200% 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .bg-gradient-1[data-bg-animate=on] {
    background-size: 400% 200%;
    background-position: 0% 0%;
    animation: bg-gradient-anim-1 30s linear infinite;
  }
}

@keyframes bg-gradient-anim-1 {
  0%, 100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0% 100%;
  }
}
.bg-gradient-2 {
  background-color: #FFFCF2;
  background-image: radial-gradient(ellipse at 10% 20%, #E980FC 0%, rgba(233, 128, 252, 0) 40%), radial-gradient(ellipse at 50% 0%, #FFFCF2 25%, rgba(255, 252, 242, 0) 75%), radial-gradient(ellipse at 100% 100%, #BCB6FF 35%, rgba(188, 182, 255, 0) 70%), radial-gradient(ellipse at 50% 100%, #FFFCF2 20%, rgba(255, 252, 242, 0) 70%);
  background-size: 120% 200%;
}
@media (prefers-reduced-motion: no-preference) {
  .bg-gradient-2[data-bg-animate=on] {
    background-size: 240% 400%;
    background-position: 0% 0%;
    animation: bg-gradient-anim-2 30s linear infinite;
  }
}

@keyframes bg-gradient-anim-2 {
  0%, 100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0% 100%;
  }
}
.bg-gradient-3 {
  background-color: #30C5FF;
  background-image: radial-gradient(ellipse at 25% 90%, #5C946E 10%, rgba(92, 148, 110, 0) 70%), radial-gradient(ellipse at 55% 90%, #FFFCF2 0%, rgba(255, 252, 242, 0) 70%), radial-gradient(ellipse at 100% 100%, #EB5E28 5%, rgba(235, 94, 40, 0) 60%), radial-gradient(ellipse at 0% 0%, #5C946E 15%, rgba(92, 148, 110, 0) 50%), radial-gradient(ellipse at 100% 0%, #FFFCF2 30%, rgba(255, 252, 242, 0) 70%);
  background-size: 100% 140%;
}
@media (prefers-reduced-motion: no-preference) {
  .bg-gradient-3[data-bg-animate=on] {
    background-size: 200% 280%;
    background-position: 50% 0%;
    animation: bg-gradient-anim-3 30s linear infinite;
  }
}

@keyframes bg-gradient-anim-3 {
  0%, 100% {
    background-position: 0% 0%;
  }
  33% {
    background-position: 100% 0%;
  }
  66% {
    background-position: 100% 100%;
  }
}
.bg-gradient-4 {
  background-color: #1D1128;
  background-image: radial-gradient(ellipse at 70% 0%, rgba(60, 31, 132, 0.7) 0%, rgba(60, 31, 132, 0) 30%), radial-gradient(circle at 90% 0%, rgba(238, 68, 105, 0.5) 0%, rgba(238, 68, 105, 0) 30%), radial-gradient(ellipse at 0% 0%, rgba(60, 185, 174, 0.15) 0%, rgba(60, 185, 174, 0) 40%), radial-gradient(circle at 30% 100%, rgba(60, 31, 132, 0.7) 0%, rgba(60, 31, 132, 0) 40%), radial-gradient(ellipse at 10% 100%, rgba(238, 68, 105, 0.5) 0%, rgba(238, 68, 105, 0) 40%), radial-gradient(ellipse at 100% 100%, rgba(60, 185, 174, 0.15) 0%, rgba(60, 185, 174, 0) 40%);
}
@media (prefers-reduced-motion: no-preference) {
  .bg-gradient-4[data-bg-animate=on] {
    background-size: 200% 200%;
    background-position: 0% 0%;
    animation: bg-gradient-anim-4 30s linear infinite;
  }
}

@keyframes bg-gradient-anim-4 {
  0%, 100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
}
.bg-gradient-5 {
  background-color: #e6e5f7;
  background-image: radial-gradient(ellipse at 50% 0%, rgba(225, 91, 151, 0.35) 0%, rgba(225, 91, 151, 0) 30%), radial-gradient(ellipse at 100% 0%, rgba(132, 19, 134, 0.32) 0%, rgba(132, 19, 134, 0) 60%);
  background-size: 200% 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .bg-gradient-5[data-bg-animate=on] {
    background-size: 400% 200%;
    background-position: 0% 0%;
    animation: bg-gradient-anim-5 20s linear infinite;
  }
}

@keyframes bg-gradient-anim-5 {
  0%, 100% {
    background-position: 0% 0%;
  }
  40%, 60% {
    background-position: 100% 0%;
  }
}
/* -------------------------------- 

File#: _1_boxed-feature
Title: Boxed Feature
Descr: Feature section with a "boxed" layout
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _1_card-v2
Title: Card v2
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */
.card-v2 {
  position: relative;
  overflow: hidden;
}
.card-v2 img {
  display: block;
  width: 100%;
}

.card-v2__caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0), hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 1));
  background-blend-mode: multiply;
  color: var(--color-white);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-v2--blur-fx .card-v2__caption {
  background: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.8);
  backdrop-filter: blur(10px);
}

a.card-v2 {
  display: block;
  text-decoration: none;
  transition: box-shadow 0.2s;
}
a.card-v2:hover {
  box-shadow: var(--shadow-md);
}

/* -------------------------------- 

File#: _1_customer-logos
Title: Customer Logos
Descr: A gallery of trusted companies
Usage: codyhouse.co/license

-------------------------------- */
.customer-logos__logo {
  display: block;
  fill: currentColor;
}

/* -------------------------------- 

File#: _1_devices
Title: Devices
Descr: A collection of CSS tech devices
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --dev-phone-radius: 14%;
  --dev-phone-img-ratio: 0.56;
  --dev-tablet-radius: 6%;
  --dev-tablet-img-ratio: 0.75;
  --dev-browser-radius: 1.5%;
  --dev-browser-img-ratio: 1.6;
  --dev-laptop-radius: 3%;
  --dev-laptop-img-ratio: 1.6;
  --dev-desktop-radius: 3%;
  --dev-desktop-img-ratio: 1.6;
}

/* #region (Phone) */
.dev-phone,
.dev-phone__body {
  position: relative;
  width: 100%;
}

.dev-phone {
  z-index: 1;
}

.dev-phone__body {
  background-color: var(--color-white);
  --phone-inner-shadow: inset 0 0 0.285em hsla(0, 0%, 0%, 0.15), inset 0 0 0.1em hsla(0, 0%, 0%, 0.035),
    inset 0 0 0.05em hsla(0, 0%, 0%, 0.005);
  box-shadow: var(--phone-inner-shadow);
  padding: 3%;
  z-index: 2;
  border-top-left-radius: var(--dev-phone-radius) calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio));
  border-top-right-radius: var(--dev-phone-radius) calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio));
  border-bottom-right-radius: var(--dev-phone-radius) calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio));
  border-bottom-left-radius: var(--dev-phone-radius) calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio));
}
.dev-phone--dark .dev-phone__body {
  background-color: var(--color-black);
  border: 3px solid #f34747;
}

.dev-phone__media {
  position: relative;
  overflow: hidden;
  border-top-left-radius: calc(var(--dev-phone-radius) * 0.85) calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio));
  border-top-right-radius: calc(var(--dev-phone-radius) * 0.85) calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio));
  border-bottom-left-radius: calc(var(--dev-phone-radius) * 0.85) calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio));
  border-bottom-right-radius: calc(var(--dev-phone-radius) * 0.85) calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio));
}
.dev-phone__media img,
.dev-phone__media svg,
.dev-phone__media video {
  display: block;
  width: 100%;
  height: auto;
}
.dev-phone__media::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
  border-radius: inherit;
  pointer-events: none;
}
.dev-phone--dark .dev-phone__media::after {
  display: none;
}

.dev-phone__notch {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 50%;
  right: 25%;
  height: 4%;
  border-radius: 0 0 0.85em 0.85em;
  background-color: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-width: 0;
}
.dev-phone--dark .dev-phone__notch {
  background-color: var(--color-black);
  border-width: 0;
}

.dev-phone--landscape .dev-phone__body {
  padding: 1.75%;
  border-top-left-radius: calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio)) var(--dev-phone-radius);
  border-top-right-radius: calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio)) var(--dev-phone-radius);
  border-bottom-right-radius: calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio)) var(--dev-phone-radius);
  border-bottom-left-radius: calc(var(--dev-phone-radius) * var(--dev-phone-img-ratio)) var(--dev-phone-radius);
}
.dev-phone--landscape .dev-phone__media {
  border-top-left-radius: calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio)) calc(var(--dev-phone-radius) * 0.85);
  border-top-right-radius: calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio)) calc(var(--dev-phone-radius) * 0.85);
  border-bottom-left-radius: calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio)) calc(var(--dev-phone-radius) * 0.85);
  border-bottom-right-radius: calc(var(--dev-phone-radius) * 0.85 * var(--dev-phone-img-ratio)) calc(var(--dev-phone-radius) * 0.85);
}
.dev-phone--landscape .dev-phone__notch {
  right: 0;
  height: 56%;
  top: 22%;
  width: 4%;
  border-radius: 0.85em 0 0 0.85em;
  border-right-width: 0px;
  border-top-width: 1px;
}

.dev-phone--front-light .dev-phone__body {
  box-shadow: var(--phone-inner-shadow), var(--shadow-lg);
}

.dev-phone--top-light .dev-phone__shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 1em;
  width: 100%;
  left: 0;
  bottom: -0.5em;
  border-radius: 100%;
  filter: blur(5px);
}
.dev-phone--top-light .dev-phone__shadow::before, .dev-phone--top-light .dev-phone__shadow::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}
.dev-phone--top-light .dev-phone__shadow::before {
  top: 20%;
  left: 10%;
  width: 80%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.4);
}
.dev-phone--top-light .dev-phone__shadow::after {
  top: 20%;
  left: 5%;
  width: 90%;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.15);
}

/* #endregion */
/* #region (Tablet) */
.dev-tablet,
.dev-tablet__body {
  position: relative;
  width: 100%;
}

.dev-tablet {
  z-index: 1;
}

.dev-tablet__body {
  background-color: var(--color-bg);
  --tablet-inner-shadow: inset 0 0 0.285em hsla(0, 0%, 0%, 0.15), inset 0 0 0.1em hsla(0, 0%, 0%, 0.035),
    inset 0 0 0.05em hsla(0, 0%, 0%, 0.005);
  box-shadow: var(--tablet-inner-shadow);
  padding: 3%;
  z-index: 2;
  border-top-left-radius: var(--dev-tablet-radius) calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio));
  border-top-right-radius: var(--dev-tablet-radius) calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio));
  border-bottom-right-radius: var(--dev-tablet-radius) calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio));
  border-bottom-left-radius: var(--dev-tablet-radius) calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio));
}

.dev-tablet__media {
  position: relative;
  overflow: hidden;
  border-top-left-radius: calc(var(--dev-tablet-radius) * 0.75) calc(var(--dev-tablet-radius) * 0.75 * var(--dev-tablet-img-ratio));
  border-top-right-radius: calc(var(--dev-tablet-radius) * 0.75) calc(var(--dev-tablet-radius) * 0.75 * var(--dev-tablet-img-ratio));
  border-bottom-left-radius: calc(var(--dev-tablet-radius) * 0.75) calc(var(--dev-tablet-radius) * 0.75 * var(--dev-tablet-img-ratio));
  border-bottom-right-radius: calc(var(--dev-tablet-radius) * 0.75) calc(var(--dev-tablet-radius) * 0.75 * var(--dev-tablet-img-ratio));
}
.dev-tablet__media img,
.dev-tablet__media svg,
.dev-tablet__media video {
  display: block;
  width: 100%;
  height: auto;
}
.dev-tablet__media::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
  border-radius: inherit;
  pointer-events: none;
}

.dev-tablet--landscape .dev-tablet__body {
  padding: 1.75%;
  border-top-left-radius: calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio)) var(--dev-tablet-radius);
  border-top-right-radius: calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio)) var(--dev-tablet-radius);
  border-bottom-right-radius: calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio)) var(--dev-tablet-radius);
  border-bottom-left-radius: calc(var(--dev-tablet-radius) * var(--dev-tablet-img-ratio)) var(--dev-tablet-radius);
}
.dev-tablet--landscape .dev-tablet__media {
  border-top-left-radius: calc(var(--dev-tablet-radius) * 0.85 * var(--dev-tablet-img-ratio)) calc(var(--dev-tablet-radius) * 0.85);
  border-top-right-radius: calc(var(--dev-tablet-radius) * 0.85 * var(--dev-tablet-img-ratio)) calc(var(--dev-tablet-radius) * 0.85);
  border-bottom-left-radius: calc(var(--dev-tablet-radius) * 0.85 * var(--dev-tablet-img-ratio)) calc(var(--dev-tablet-radius) * 0.85);
  border-bottom-right-radius: calc(var(--dev-tablet-radius) * 0.85 * var(--dev-tablet-img-ratio)) calc(var(--dev-tablet-radius) * 0.85);
}

.dev-tablet--front-light .dev-tablet__body {
  box-shadow: var(--tablet-inner-shadow), var(--shadow-lg);
}

.dev-tablet--top-light .dev-tablet__shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 1em;
  width: 100%;
  left: 0;
  bottom: -0.5em;
  border-radius: 100%;
  filter: blur(5px);
}
.dev-tablet--top-light .dev-tablet__shadow::before, .dev-tablet--top-light .dev-tablet__shadow::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}
.dev-tablet--top-light .dev-tablet__shadow::before {
  top: 20%;
  left: 10%;
  width: 80%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.4);
}
.dev-tablet--top-light .dev-tablet__shadow::after {
  top: 20%;
  left: 5%;
  width: 90%;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.15);
}

/* #endregion */
/* #region (Browser) */
.dev-browser,
.dev-browser__body {
  position: relative;
  width: 100%;
}

.dev-browser {
  z-index: 1;
}

.dev-browser__body {
  background: var(--color-bg);
  padding: 2em 1.5% 1.5%;
  --browser-inner-shadow: inset 0 0 0.285em hsla(0, 0%, 0%, 0.15), inset 0 0 0.1em hsla(0, 0%, 0%, 0.035),
    inset 0 0 0.05em hsla(0, 0%, 0%, 0.005);
  box-shadow: var(--browser-inner-shadow);
  border-top-left-radius: var(--dev-browser-radius) calc(var(--dev-browser-radius) * var(--dev-browser-img-ratio));
  border-top-right-radius: var(--dev-browser-radius) calc(var(--dev-browser-radius) * var(--dev-browser-img-ratio));
  border-bottom-right-radius: var(--dev-browser-radius) calc(var(--dev-browser-radius) * var(--dev-browser-img-ratio));
  border-bottom-left-radius: var(--dev-browser-radius) calc(var(--dev-browser-radius) * var(--dev-browser-img-ratio));
  z-index: 2;
}

.dev-browser__media {
  overflow: hidden;
  border-radius: var(--radius-md);
}
.dev-browser__media img,
.dev-browser__media svg,
.dev-browser__media video {
  display: block;
  width: 100%;
  height: auto;
}

.dev-browser__dots {
  position: absolute;
  left: calc(1.5% + 0.4em);
  top: calc(1em - 6px);
  display: flex;
}
.dev-browser__dots span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.2);
  margin-right: var(--space-xxs);
}

.dev-browser--front-light .dev-browser__body {
  box-shadow: var(--browser-inner-shadow), var(--shadow-lg);
}

.dev-browser--top-light .dev-browser__shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 1em;
  width: 100%;
  left: 0;
  bottom: -0.5em;
  border-radius: 100%;
  filter: blur(5px);
}
.dev-browser--top-light .dev-browser__shadow::before, .dev-browser--top-light .dev-browser__shadow::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}
.dev-browser--top-light .dev-browser__shadow::before {
  top: 20%;
  left: 10%;
  width: 80%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.4);
}
.dev-browser--top-light .dev-browser__shadow::after {
  top: 20%;
  left: 5%;
  width: 90%;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.2);
}

/* #endregion */
/* #region (Laptop) */
.dev-laptop {
  position: relative;
  z-index: 1;
  width: 100%;
}

.dev-laptop__body {
  width: 85%;
  margin: 0 auto;
  background-color: var(--color-black);
  border: 3px solid var(--color-contrast-low);
  border-bottom-width: 0;
  padding: 3% 1.5% 1.5%;
  border-top-left-radius: var(--dev-laptop-radius) calc(var(--dev-laptop-radius) * var(--dev-laptop-img-ratio));
  border-top-right-radius: var(--dev-laptop-radius) calc(var(--dev-laptop-radius) * var(--dev-laptop-img-ratio));
  z-index: 2;
}

.dev-laptop__media img,
.dev-laptop__media svg,
.dev-laptop__media video {
  display: block;
  width: 100%;
  height: auto;
}

.dev-laptop__base {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 3%;
  background: linear-gradient(hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 1.4)), var(--color-contrast-low) 7% 55%, hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 0.85)) 55%, hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 0.5)));
  border-bottom-left-radius: 10% 50%;
  border-bottom-right-radius: 10% 50%;
  z-index: 2;
}
.dev-laptop__base::after {
  content: "";
  position: absolute;
  top: 0;
  left: 40%;
  width: 20%;
  height: 35%;
  border-radius: 0 0 1em 1em;
  background-color: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 0.8));
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
}

.dev-laptop__shadow {
  position: absolute;
  z-index: 1;
  height: 1em;
  width: 94%;
  left: 3%;
  bottom: -0.5em;
  perspective: 400px;
}
.dev-laptop__shadow::before, .dev-laptop__shadow::after {
  content: "";
  position: absolute;
}
.dev-laptop__shadow::before {
  top: 0;
  left: 1%;
  width: 98%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  transform: rotateX(70deg);
  filter: blur(5px);
}
.dev-laptop__shadow::after {
  top: calc(44% - 1px);
  left: 4%;
  width: 92%;
  height: 12%;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  filter: blur(1px);
}

/* #endregion */
/* #region (Desktop) */
.dev-desktop {
  position: relative;
  z-index: 1;
  width: 100%;
}

.dev-desktop__body {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  background-color: var(--color-black);
  border: 3px solid var(--color-contrast-low);
  padding: 4%;
  border-top-left-radius: var(--dev-desktop-radius) calc(var(--dev-desktop-radius) * var(--dev-desktop-img-ratio));
  border-top-right-radius: var(--dev-desktop-radius) calc(var(--dev-desktop-radius) * var(--dev-desktop-img-ratio));
  border-bottom-right-radius: var(--dev-desktop-radius) calc(var(--dev-desktop-radius) * var(--dev-desktop-img-ratio));
  border-bottom-left-radius: var(--dev-desktop-radius) calc(var(--dev-desktop-radius) * var(--dev-desktop-img-ratio));
}

.dev-desktop__media img,
.dev-desktop__media svg,
.dev-desktop__media video {
  display: block;
  width: 100%;
  height: auto;
}

.dev-desktop__base {
  position: relative;
  z-index: 1;
  width: 33%;
  margin: 0 auto;
  height: 0;
  padding-bottom: 15%;
  perspective: 180px;
}

.dev-desktop__base-top {
  position: absolute;
  z-index: 3;
  top: -1px;
  left: 15%;
  width: 70%;
  height: 80%;
  clip-path: polygon(3% 0%, 97% 0%, 100% 100%, 0% 100%);
  background: linear-gradient(hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * 0.6)), var(--color-contrast-low));
}

.dev-desktop__base-bottom {
  position: absolute;
  z-index: 2;
  top: calc(80% - 2px);
  left: 15%;
  width: 70%;
  height: 50%;
  border-radius: 0 0 0.25em 0.25em;
  transform-origin: center top;
  transform: rotateX(80deg);
  background-color: var(--color-contrast-low);
  box-shadow: inset 0 -2px 0 rgba(255, 255, 255, 0.25);
}

.dev-desktop__shadow {
  position: absolute;
  z-index: 1;
  top: calc(80% - 2px);
  left: 17%;
  width: 66%;
  height: 50%;
  border-radius: 0 0 0.25em 0.25em;
  transform-origin: center top;
  transform: rotateX(80deg);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4), 0 4px 4px rgba(0, 0, 0, 0.15);
}

/* #endregion */
/* -------------------------------- 

File#: _1_feature-v7
Title: Feature v7
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _1_feature-v12
Title: Feature v12
Descr: Feature section containing text that overlaps a background image
Usage: codyhouse.co/license

-------------------------------- */
.feature-v12, .feature-v12__offset-item, .feature-v12__bg-item {
  position: relative;
}

.feature-v12, .feature-v12__bg-item {
  z-index: 1;
}

.feature-v12__offset-item {
  z-index: 2;
}

.feature-v12--left .feature-v12__offset-item, .feature-v12--right .feature-v12__offset-item {
  width: calc(100% + var(--feature-v12-offset, 3em));
}

.feature-v12--right .feature-v12__offset-item {
  margin-left: calc(-1 * var(--feature-v12-offset, 3em));
}

.feature-v12--top .feature-v12__offset-item {
  margin-bottom: calc(-1 * var(--feature-v12-offset, 3em));
}

.feature-v12--bottom .feature-v12__offset-item {
  margin-top: calc(-1 * var(--feature-v12-offset, 3em));
}

.feature-v12--offset-xs {
  --feature-v12-offset: 2em;
}

.feature-v12--offset-sm {
  --feature-v12-offset: 3em;
}

.feature-v12--offset-md {
  --feature-v12-offset: 5em;
}

.feature-v12--offset-lg {
  --feature-v12-offset: 8em;
}

.feature-v12--offset-xl {
  --feature-v12-offset: 13em;
}

@media (min-width: 32rem) {
  .feature-v12--left\@xs .feature-v12__offset-item, .feature-v12--right\@xs .feature-v12__offset-item {
    width: calc(100% + var(--feature-v12-offset, 3em));
    margin: 0;
  }

  .feature-v12--right\@xs .feature-v12__offset-item {
    margin-left: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--top\@xs .feature-v12__offset-item,
.feature-v12--bottom\@xs .feature-v12__offset-item {
    width: auto;
    margin: 0;
  }

  .feature-v12--top\@xs .feature-v12__offset-item {
    margin-bottom: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--bottom\@xs .feature-v12__offset-item {
    margin-top: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--offset-xs\@xs {
    --feature-v12-offset: 2em;
  }

  .feature-v12--offset-sm\@xs {
    --feature-v12-offset: 3em;
  }

  .feature-v12--offset-md\@xs {
    --feature-v12-offset: 5em;
  }

  .feature-v12--offset-lg\@xs {
    --feature-v12-offset: 8em;
  }

  .feature-v12--offset-xl\@xs {
    --feature-v12-offset: 13em;
  }
}
@media (min-width: 48rem) {
  .feature-v12--left\@sm .feature-v12__offset-item, .feature-v12--right\@sm .feature-v12__offset-item {
    width: calc(100% + var(--feature-v12-offset, 3em));
    margin: 0;
  }

  .feature-v12--right\@sm .feature-v12__offset-item {
    margin-left: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--top\@sm .feature-v12__offset-item,
.feature-v12--bottom\@sm .feature-v12__offset-item {
    width: auto;
    margin: 0;
  }

  .feature-v12--top\@sm .feature-v12__offset-item {
    margin-bottom: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--bottom\@sm .feature-v12__offset-item {
    margin-top: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--offset-xs\@sm {
    --feature-v12-offset: 2em;
  }

  .feature-v12--offset-sm\@sm {
    --feature-v12-offset: 3em;
  }

  .feature-v12--offset-md\@sm {
    --feature-v12-offset: 5em;
  }

  .feature-v12--offset-lg\@sm {
    --feature-v12-offset: 8em;
  }

  .feature-v12--offset-xl\@sm {
    --feature-v12-offset: 13em;
  }
}
@media (min-width: 64rem) {
  .feature-v12--left\@md .feature-v12__offset-item, .feature-v12--right\@md .feature-v12__offset-item {
    width: calc(100% + var(--feature-v12-offset, 3em));
    margin: 0;
  }

  .feature-v12--right\@md .feature-v12__offset-item {
    margin-left: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--top\@md .feature-v12__offset-item,
.feature-v12--bottom\@md .feature-v12__offset-item {
    width: auto;
    margin: 0;
  }

  .feature-v12--top\@md .feature-v12__offset-item {
    margin-bottom: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--bottom\@md .feature-v12__offset-item {
    margin-top: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--offset-xs\@md {
    --feature-v12-offset: 2em;
  }

  .feature-v12--offset-sm\@md {
    --feature-v12-offset: 3em;
  }

  .feature-v12--offset-md\@md {
    --feature-v12-offset: 5em;
  }

  .feature-v12--offset-lg\@md {
    --feature-v12-offset: 8em;
  }

  .feature-v12--offset-xl\@md {
    --feature-v12-offset: 13em;
  }
}
@media (min-width: 80rem) {
  .feature-v12--left\@lg .feature-v12__offset-item, .feature-v12--right\@lg .feature-v12__offset-item {
    width: calc(100% + var(--feature-v12-offset, 3em));
    margin: 0;
  }

  .feature-v12--right\@lg .feature-v12__offset-item {
    margin-left: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--top\@lg .feature-v12__offset-item,
.feature-v12--bottom\@lg .feature-v12__offset-item {
    width: auto;
    margin: 0;
  }

  .feature-v12--top\@lg .feature-v12__offset-item {
    margin-bottom: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--bottom\@lg .feature-v12__offset-item {
    margin-top: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--offset-xs\@lg {
    --feature-v12-offset: 2em;
  }

  .feature-v12--offset-sm\@lg {
    --feature-v12-offset: 3em;
  }

  .feature-v12--offset-md\@lg {
    --feature-v12-offset: 5em;
  }

  .feature-v12--offset-lg\@lg {
    --feature-v12-offset: 8em;
  }

  .feature-v12--offset-xl\@lg {
    --feature-v12-offset: 13em;
  }
}
@media (min-width: 90rem) {
  .feature-v12--left\@xl .feature-v12__offset-item, .feature-v12--right\@xl .feature-v12__offset-item {
    width: calc(100% + var(--feature-v12-offset, 3em));
    margin: 0;
  }

  .feature-v12--right\@xl .feature-v12__offset-item {
    margin-left: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--top\@xl .feature-v12__offset-item,
.feature-v12--bottom\@xl .feature-v12__offset-item {
    width: auto;
    margin: 0;
  }

  .feature-v12--top\@xl .feature-v12__offset-item {
    margin-bottom: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--bottom\@xl .feature-v12__offset-item {
    margin-top: calc(-1 * var(--feature-v12-offset, 3em));
  }

  .feature-v12--offset-xs\@xl {
    --feature-v12-offset: 2em;
  }

  .feature-v12--offset-sm\@xl {
    --feature-v12-offset: 3em;
  }

  .feature-v12--offset-md\@xl {
    --feature-v12-offset: 5em;
  }

  .feature-v12--offset-lg\@xl {
    --feature-v12-offset: 8em;
  }

  .feature-v12--offset-xl\@xl {
    --feature-v12-offset: 13em;
  }
}
/* -------------------------------- 

File#: _1_hero
Title: Hero
Descr: A full-width callout section
Usage: codyhouse.co/license

-------------------------------- */
.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.hero--overlay-layer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.65);
  z-index: 1;
}

/* -------------------------------- 

File#: _1_search-input
Title: Search input
Descr: Search input field with custom button
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --search-input-btn-width: 2.2em;
  --search-input-icon-size: 1em;
  --search-input-shortcut-margin: 0.325em;
  /* gap between the shortcut badge and the input edges */
}

.search-input {
  position: relative;
}

.search-input__input {
  width: 100%;
  height: 100%;
}
.search-input__input::-webkit-search-decoration, .search-input__input::-webkit-search-cancel-button, .search-input__input::-webkit-search-results-button, .search-input__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.search-input__input::-ms-clear, .search-input__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.search-input--icon-right .search-input__input {
  padding-right: var(--search-input-btn-width);
}
.search-input--icon-left .search-input__input {
  padding-left: var(--search-input-btn-width);
}

.search-input__btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: var(--search-input-btn-width);
}
.search-input__btn:active .icon {
  transform: translateY(2px);
}
.search-input__btn .icon {
  display: block;
  --size: var(--search-input-icon-size);
  margin-left: auto;
  margin-right: auto;
  color: var(--color-contrast-low);
  /* icon color */
  transition: 0.2s;
}
.search-input--icon-left .search-input__btn {
  left: 0;
  right: auto;
  pointer-events: none;
}

.search-input__btn:focus .icon,
.search-input .search-input__input:focus + .search-input__btn .icon {
  color: var(--color-primary);
  /* active icon color */
}

/* --shortcut */
.search-input__shortcut {
  position: absolute;
  right: var(--search-input-shortcut-margin);
  top: var(--search-input-shortcut-margin);
  height: calc(100% - var(--search-input-shortcut-margin) * 2);
  display: flex;
  align-items: center;
  background-color: var(--color-bg);
  border: 1px solid var(--color-contrast-lower);
  border-radius: var(--radius-sm);
  --space-unit: 1em;
  padding: 0 var(--space-xxxs);
  line-height: 1;
  color: var(--color-contrast-medium);
}

.search-input:focus-within .search-input__shortcut {
  display: none;
}

/* -------------------------------- 

File#: _2_flexi-header
Title: Flexi Header
Descr: Customizable header template
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --f-header-height: 50px;
  --f-header-logo-width: 148px;
}
@media (min-width: 64rem) {
  :root {
    --f-header-height: 70px;
  }
}

.f-header {
  height: var(--f-header-height);
  width: 100%;
  z-index: var(--z-index-header, 3);
}
.f-header::before {
  /* used in JS to detect menu style */
  display: none;
  content: "mobile";
}

.f-header--expanded {
  /* class added when navigation is visible - small devices only */
}

.f-header__mobile-content {
  /* logo + menu button on small devices */
  position: relative;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.f-header__logo {
  display: block;
  width: var(--f-header-logo-width);
  flex-shrink: 0;
  text-decoration: none;
  margin-bottom: -10px;
}
.f-header__logo svg,
.f-header__logo img {
  display: block;
}

.f-header__nav-control {
  /* menu button */
  --anim-menu-btn-size: 40px;
  /* 🍔 icon */
  --anim-menu-btn-icon-size: 28px;
  --anim-menu-btn-icon-stroke: 2px;
}

.f-header__nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: calc(100vh - var(--f-header-offset, 0px));
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  padding: 0 0 var(--space-md);
  background-color: var(--color-primary);
  box-shadow: var(--shadow-md);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1em);
}
.f-header__nav::before {
  /* top header bg + border */
  content: "";
  display: block;
  position: sticky;
  top: 0;
  height: var(--f-header-height);
  background-color: inherit;
  border-bottom: 1px solid var(--color-contrast-lower);
}

.f-header__nav--is-visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: visibility 0s, opacity 0.3s, transform 0.3s;
}

.f-header__nav-logo-wrapper {
  display: none;
  /* hide logo nav on small devices */
}

.f-header__item {
  flex-shrink: 0;
  border-bottom: 1px solid var(--color-darker);
}

.f-header__dropdown-icon {
  --size: 16px;
  flex-shrink: 0;
  margin: 0 10px 0 auto;
  display: none;
  /* hide on mobile if link */
}
.f-header__dropdown-control .f-header__dropdown-icon {
  display: block;
}

.f-header__link,
.f-header__dropdown-control,
.f-header__dropdown-link,
.f-header__btn,
.f-header__form-control {
  font-size: var(--text-md);
}

.f-header__link,
.f-header__dropdown-control,
.f-header__dropdown-link {
  display: flex;
  align-items: center;
  color: var(--color-black);
  text-decoration: none;
  padding: var(--space-xs) 0;
  font-weight: 700;
  transition-duration: 0.3s;
}
.f-header__link:hover, .f-header__link[aria-current=page],
.f-header__dropdown-control:hover,
.f-header__dropdown-control[aria-current=page],
.f-header__dropdown-link:hover,
.f-header__dropdown-link[aria-current=page] {
  color: var(--color-white);
}
.f-header__link:focus,
.f-header__dropdown-control:focus,
.f-header__dropdown-link:focus {
  outline: none;
}

.f-header__btn,
.f-header__form-control {
  width: 100%;
  margin: var(--space-xs) 0;
}

.f-header__dropdown-control {
  width: 100%;
}

.f-header__dropdown {
  /* sub navigation */
  padding-left: var(--space-md);
  /* offset sub nav */
}

.f-header__dropdown-control + .f-header__dropdown {
  display: none;
}
.f-header__dropdown-control[aria-expanded=true] + .f-header__dropdown {
  display: block;
}

@media (min-width: 64rem) {
  .f-header::before {
    content: "desktop";
  }

  .f-header__mobile-content {
    display: none;
    /* hide logo + menu (mobile content) */
  }

  .f-header__nav {
    /* reset */
    position: static;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: none;
    max-height: none;
    overflow: visible;
    overscroll-behavior: auto;
    height: var(--f-header-height);
  }
  .f-header__nav::before {
    /* reset */
    display: none;
  }

  .f-header__nav-logo-wrapper {
    display: flex;
  }

  .f-header__nav-grid,
.f-header__list {
    display: flex;
    align-items: center;
  }

  .f-header__nav-grid {
    height: 100%;
  }

  .f-header__item {
    position: relative;
    border-bottom: none;
    margin-right: var(--space-xs);
    /* margin between nav items */
  }
  .f-header__item:last-child {
    margin-right: 0;
  }

  .f-header__dropdown-icon {
    --size: 12px;
    display: block;
    margin: 0 0 0 var(--space-xxxxs);
  }

  .f-header__link,
.f-header__dropdown-control,
.f-header__dropdown-link,
.f-header__btn,
.f-header__form-control {
    font-size: 1.125rem;
  }

  .f-header__link {
    padding: var(--space-xxxs) var(--space-xxs);
  }

  .f-header__link--icon span {
    display: none;
    /* hide icon label */
  }

  .f-header__btn,
.f-header__form-control {
    margin: 0;
    /* reset */
  }

  .f-header__dropdown {
    position: absolute;
    top: 100%;
    width: 220px;
    left: calc(50% - 110px);
    padding: var(--space-xxxs) 0;
    background-color: var(--color-bg-light);
    border-radius: var(--radius-md);
    box-shadow: var(--inner-glow), var(--shadow-md);
    z-index: var(--z-index-popover, 5);
    /* hide */
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s 0.2s, opacity 0.2s 0s;
  }
  .f-header__dropdown-control + .f-header__dropdown {
    display: block;
    /* reset style */
  }
  .f-header__item:hover .f-header__dropdown, .f-header__dropdown-control[aria-expanded=true] + .f-header__dropdown {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s 0s, opacity 0.2s 0s;
  }

  .f-header__dropdown-link {
    color: var(--color-contrast-high);
    padding: var(--space-xs) var(--space-sm);
    transition: 0.2s;
  }
  .f-header__dropdown-link:hover {
    background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
    color: var(--color-contrast-higher);
  }
}
html:not(.js) .f-header {
  height: auto;
}
html:not(.js) .f-header__nav-control {
  display: none;
}
html:not(.js) .f-header__nav {
  position: static;
  padding: var(--space-xxs) 0 var(--space-md);
  box-shadow: none;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

/* --------------------------------

File#: _1_sub-navigation
Title: Sub Navigation
Descr: Secondary navigation template
Usage: codyhouse.co/license

-------------------------------- */
.subnav__nav {
  position: relative;
  display: flex;
}
.subnav__nav::after {
  content: "";
  width: 1em;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to right, hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), 0), hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), 1));
  pointer-events: none;
}

.subnav__list {
  display: flex;
  overflow: auto;
}

.subnav__item {
  display: inline-block;
  flex-shrink: 0;
}

.subnav__link {
  display: block;
  font-size: var(--text-sm);
  padding: 1.5625em;
  text-decoration: none;
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.65);
  border: 3px solid transparent;
  border-right-width: 0;
  border-left-width: 0;
  transition: 0.2s;
}
.subnav__link:hover {
  color: var(--color-contrast-high);
}
.subnav__link[aria-current=page] {
  border-bottom-color: var(--color-primary);
  color: var(--color-contrast-high);
}

.subnav:not(.subnav--collapsed) .subnav__control, .subnav:not(.subnav--collapsed) .subnav__close-btn {
  display: none;
}

.subnav--collapsed {
  display: inline-block;
}
.subnav--collapsed .subnav__wrapper {
  position: fixed;
  display: block;
  z-index: var(--z-index-overlay, 15);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
  visibility: hidden;
  opacity: 0;
}
.subnav--collapsed .subnav__wrapper--is-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}
.subnav--collapsed .subnav__wrapper--is-visible .subnav__nav {
  transform: translateY(0);
  transition: transform 0.3s;
}
.subnav--collapsed .subnav__nav {
  display: block;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  max-height: 100%;
  overflow: auto;
  transform: translateY(-1em);
}
.subnav--collapsed .subnav__nav::after {
  display: none;
}
.subnav--collapsed .subnav__list {
  flex-direction: column;
  overflow: visible;
  padding: 0 var(--space-md) var(--space-md);
}
.subnav--collapsed .subnav__link {
  border-width: 0;
  font-size: var(--text-md);
  padding: var(--space-xs) 0;
}
.subnav--collapsed .subnav__link[aria-current=page] {
  color: var(--color-primary);
}

.subnav__close-btn {
  --size: 2em;
  width: var(--size);
  height: var(--size);
  display: flex;
  margin: var(--space-xs) var(--space-xs) 0 auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  transition: 0.2s;
}
.subnav__close-btn:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-md);
}
.subnav__close-btn .icon {
  display: block;
  color: var(--color-contrast-high);
}

[class*=subnav--expanded]::before {
  display: none;
  content: "collapsed";
}

@media (min-width: 32rem) {
  .subnav--expanded\@xs::before {
    content: "expanded";
  }
}
@media (min-width: 48rem) {
  .subnav--expanded\@sm::before {
    content: "expanded";
  }
}
@media (min-width: 64rem) {
  .subnav--expanded\@md::before {
    content: "expanded";
  }
}
@media (min-width: 80rem) {
  .subnav--expanded\@lg::before {
    content: "expanded";
  }
}
@media (min-width: 90rem) {
  .subnav--expanded\@xl::before {
    content: "expanded";
  }
}
/* -------------------------------- 

File#: _1_hiding-nav
Title: Auto Hiding Navigation
Descr: A Navigation container that auto-hides when the user scrolls down, and is revealed when they scrolls back up
Usage: codyhouse.co/license

-------------------------------- */
.hide-nav {
  --hide-nav-transition-duration: 0.3s;
  position: sticky !important;
  top: 0;
  will-change: transform;
  transition: transform var(--hide-nav-transition-duration), background-color var(--hide-nav-transition-duration);
}

.hide-nav--fixed {
  background-color: transparent;
}

.hide-nav--has-bg {
  background-color: var(--color-bg);
}

/* -------------------------------- 

File#: _1_list
Title: List
Descr: Custom list component
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --list-space-y: 0.375em;
  --list-offset: 1em;
  --list-line-height-multiplier: 1;
}

.list, .text-component .list {
  padding-left: 0;
  list-style: none;
}
.list ul, .list ol, .text-component .list ul, .text-component .list ol {
  list-style: none;
  margin: 0;
  margin-top: calc(var(--list-space-y) / 2 * var(--text-space-y-multiplier, 1));
  padding-top: calc(var(--list-space-y) / 2 * var(--text-space-y-multiplier, 1));
  padding-left: var(--list-offset);
}
.list li, .text-component .list li {
  padding-bottom: calc(var(--list-space-y) / 2 * var(--text-space-y-multiplier, 1));
  margin-bottom: calc(var(--list-space-y) / 2 * var(--text-space-y-multiplier, 1));
  line-height: calc(var(--body-line-height) * var(--list-line-height-multiplier));
}
.list > li:last-child, .list ul > li:last-child, .list ol > li:last-child, .text-component .list > li:last-child, .text-component .list ul > li:last-child, .text-component .list ol > li:last-child {
  margin-bottom: 0;
}
.list:not(.list--border) > li:last-child, .list ul > li:last-child, .list ol > li:last-child, .text-component .list:not(.list--border) > li:last-child, .text-component .list ul > li:last-child, .text-component .list ol > li:last-child {
  padding-bottom: 0;
}

/* #region (ul + ol) */
.list--ul, .text-component .list--ul,
.list--ol, .text-component .list--ol {
  --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));
}
.list--ul ul, .list--ul ol, .text-component .list--ul ul, .text-component .list--ul ol,
.list--ol ul,
.list--ol ol, .text-component .list--ol ul, .text-component .list--ol ol {
  padding-left: 0;
}
.list--ul li, .text-component .list--ul li,
.list--ol li, .text-component .list--ol li {
  padding-left: var(--list-offset);
}
.list--ul li::before, .text-component .list--ul li::before,
.list--ol li::before, .text-component .list--ol li::before {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--list-bullet-size);
  height: var(--list-bullet-size);
  vertical-align: middle;
  position: relative;
  top: -0.1em;
  left: calc(var(--list-bullet-margin-right) * -1);
  margin-left: calc(var(--list-bullet-size) * -1);
}

.list--ul, .text-component .list--ul {
  --list-bullet-size: 7px;
  --list-bullet-margin-right: 12px;
}
.list--ul > li::before, .text-component .list--ul > li::before {
  content: "";
  border-radius: 50%;
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
  background-color: currentColor;
}
.list--ul ul li::before, .text-component .list--ul ul li::before {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px currentColor;
}

.list--ol, .text-component .list--ol {
  --list-bullet-size: 26px;
  --list-bullet-margin-right: 6px;
  --list-bullet-font-size: 14px;
  counter-reset: list-items;
}
.list--ol > li, .text-component .list--ol > li {
  counter-increment: list-items;
}
.list--ol ol, .text-component .list--ol ol {
  counter-reset: list-items;
}
.list--ol > li::before, .text-component .list--ol > li::before {
  content: counter(list-items);
  font-size: var(--list-bullet-font-size, 14px);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
  color: var(--color-contrast-higher);
  line-height: 1;
  border-radius: 50%;
}
.list--ol ol > li::before, .text-component .list--ol ol > li::before {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
}

/* #endregion */
/* #region (border) */
.list--border li:not(:last-child), .text-component .list--border li:not(:last-child) {
  border-bottom: 1px solid var(--color-contrast-lower);
}
.list--border ul, .list--border ol, .text-component .list--border ul, .text-component .list--border ol {
  border-top: 1px solid var(--color-contrast-lower);
}

/* #endregion */
/* #region (icons) */
.list--icons, .text-component .list--icons {
  --list-bullet-size: 24px;
  --list-bullet-margin-right: 8px;
  --list-offset: calc(var(--list-bullet-size) + var(--list-bullet-margin-right));
}
.list--icons ul, .list--icons ol, .text-component .list--icons ul, .text-component .list--icons ol {
  padding-left: var(--list-offset);
}

.list__icon {
  position: relative;
  width: var(--list-bullet-size);
  height: var(--list-bullet-size);
  margin-right: var(--list-bullet-margin-right);
}
.list__icon:not(.top-0) {
  top: calc((1em * var(--body-line-height) * var(--list-line-height-multiplier) - var(--list-bullet-size)) / 2);
}

/* #endregion */
/* -------------------------------- 

File#: _1_main-footer-v3
Title: Main Footer v3
Descr: Footer navigation template
Usage: codyhouse.co/license

-------------------------------- */
.footer-v3 {
  position: relative;
}

.footer-v3__logo a,
.footer-v3__logo svg,
.footer-v3__logo img {
  width: 148px;
  height: 35px;
  display: block;
  margin-bottom: -10px;
}

.footer-v3__nav {
  margin: var(--space-lg) 0;
}

.footer-v3__nav-item {
  margin-bottom: var(--space-sm);
}
.footer-v3__nav-item a {
  color: var(--color-black);
  font-size: 1.25em;
}

.footer-v3__socials {
  display: flex;
  align-items: center;
}
.footer-v3__socials a {
  text-decoration: none;
  display: inline-block;
  margin: 0 var(--space-xs) 0 0;
  color: var(--color-contrast-high);
}
.footer-v3__socials a:hover {
  color: var(--color-primary);
}
.footer-v3__socials a .icon {
  display: block;
  width: 1.25em;
  height: 1.25em;
}

@supports (display: grid) {
  @media (min-width: 64rem) {
    .footer-v3__nav {
      margin: 0;
    }

    .footer-v3__nav-list {
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
    }

    .footer-v3__nav-item {
      padding: 0;
      margin: 0;
      position: relative;
    }
    .footer-v3__nav-item a {
      font-size: 0.875em;
    }
    .footer-v3__nav-item:first-child {
      padding-left: 0;
    }
    .footer-v3__nav-item:last-child {
      padding-right: 0;
    }
    .footer-v3__nav-item:last-child::after {
      display: none;
    }

    .footer-v3__socials a {
      margin: 0 0 0 var(--space-xs);
    }
    .footer-v3__socials a .icon {
      width: 1em;
      height: 1em;
    }
  }
}
/* --------------------------------

File#: _1_newsletter-input
Title: Newsletter Input
Descr: Animated newsletter input
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --news-form-input-height: 48px;
  --news-form-input-padding-x: 18px;
  --news-form-input-radius: 50em;
  --news-form-btn-margin: 4px;
  --news-form-btn-padding-x: 16px;
  --news-form-icon-size: 24px;
}

.news-form__wrapper {
  position: relative;
}

.news-form__input {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.075);
  padding: 0 var(--news-form-input-padding-x);
  border-radius: var(--news-form-input-radius);
  width: 100%;
  height: var(--news-form-input-height);
  transition: 0.2s;
}
.news-form__input:focus {
  outline: none;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md), 0 0 0 2px var(--color-primary);
}

.news-form__btn {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  top: var(--news-form-btn-margin);
  right: var(--news-form-btn-margin);
  height: calc(var(--news-form-input-height) - 2 * var(--news-form-btn-margin));
  padding: 0 var(--news-form-btn-padding-x);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.2);
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.9);
  border-radius: calc(var(--news-form-input-radius) * 0.85);
  box-shadow: var(--shadow-sm);
  font-size: var(--text-sm);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  transition: 0.2s;
}
.news-form__btn > * {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s var(--ease-out);
}
.news-form__btn > *:last-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
}

.news-form__icon {
  display: block;
  width: var(--news-form-icon-size);
  height: var(--news-form-icon-size);
}
.news-form__icon > * {
  transform-origin: 50% 50%;
}

.news-form__icon-circle {
  stroke-dashoffset: 160;
  stroke-dasharray: 32;
}

.news-form__icon-check {
  stroke-dashoffset: 30;
  stroke-dasharray: 30;
}

.news-form__icon-excl-line {
  stroke-dashoffset: 14;
  stroke-dasharray: 14;
}

.news-form__icon-excl-dot {
  transform-origin: 12px 21.5px;
  transform: scale(0);
}

.news-form--enabled .news-form__btn {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.news-form--enabled .news-form__btn:hover {
  background-color: var(--color-primary-dark);
}

.news-form--loading .news-form__btn > *:first-child {
  transform: translateY(-100%);
}
.news-form--loading .news-form__btn > *:last-child {
  transform: translateY(0);
}

.news-form--circle-loop .news-form__icon-circle {
  animation: news-input-circle-anim-loader 1s var(--ease-in-out);
}

.news-form--success .news-form__btn > *,
.news-form--error .news-form__btn > * {
  transition: none;
}
.news-form--success .news-form__btn > *:first-child,
.news-form--error .news-form__btn > *:first-child {
  transform: translateY(-100%);
}
.news-form--success .news-form__btn > *:last-child,
.news-form--error .news-form__btn > *:last-child {
  transform: translateY(0);
}

.news-form--success .news-form__icon-circle {
  animation: news-input-circle-anim-success 1s var(--ease-in-out) forwards;
}
.news-form--success .news-form__icon-check {
  animation: news-input-check-anim-success 0.5s 0.8s var(--ease-in-out) forwards;
}

.news-form--error .news-form__icon-circle {
  animation: news-input-circle-anim-error 0.8s var(--ease-in-out) forwards;
}
.news-form--error .news-form__icon-excl-line {
  animation: news-input-line-anim-error 0.3s 0.6s var(--ease-in-out) forwards;
}
.news-form--error .news-form__icon-excl-dot {
  animation: news-input-dot-anim-error 0.3s 0.9s var(--ease-out) forwards;
}

@keyframes news-input-circle-anim-loader {
  from {
    transform: rotate(0);
    stroke-dashoffset: 160;
  }
  to {
    stroke-dashoffset: 96;
    transform: rotate(-180deg);
  }
}
@keyframes news-input-circle-anim-success {
  from {
    transform: rotate(0);
    stroke-dashoffset: 160;
  }
  to {
    transform: rotate(-180deg);
    stroke-dashoffset: 96;
  }
}
@keyframes news-input-circle-anim-error {
  from {
    transform: rotate(0);
    stroke-dashoffset: 160;
  }
  to {
    transform: rotate(-90deg);
    stroke-dashoffset: 96;
  }
}
@keyframes news-input-check-anim-success {
  from {
    stroke-dashoffset: 30;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes news-input-line-anim-error {
  from {
    stroke-dashoffset: 14;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes news-input-dot-anim-error {
  from {
    transform: translateY(-4px) scale(0);
  }
  to {
    transform: translateY(0) scale(1);
  }
}
.news-form__msg {
  position: absolute;
  z-index: var(--z-index-popover, 5);
  top: calc(14px + var(--news-form-input-height));
  left: 5%;
  width: 90%;
  border-top-left-radius: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s, visibility 0s 0.3s, transform 0.3s var(--ease-out);
}
.news-form__msg::before {
  content: "";
  width: 12px;
  height: 10px;
  background-color: inherit;
  position: absolute;
  left: 0;
  bottom: calc(100% - 1px);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}

.news-form__msg--success {
  background-color: var(--color-success-lighter);
  color: var(--color-black);
}

.news-form__msg--error {
  background-color: var(--color-error);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.news-form--success .news-form__msg--success,
.news-form--error .news-form__msg--error {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: opacity 0.3s 1s, transform 0.3s 1s var(--ease-out);
}

/* --------------------------------

File#: _1_product-tour
Title: Product Tour
Descr: A step-by-step interactive guide
Usage: codyhouse.co/license

-------------------------------- */
:root {
  /* 👇 visible only on bigger screens */
  --p-tour-pin-size: 12px;
  /* dot indicator size */
  --p-tour-pin-tooltip-gap: 4px;
  /* gap between dot and tooltip */
}

.p-tour {
  --p-tour-layout: "mobile";
  /* used in JS to detect layout type */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-overlay, 15);
  opacity: 1;
  visibility: visible;
}

.p-tour--is-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s;
}

.p-tour__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.8);
}

.p-tour__pin {
  display: none;
  /* hide pin on smaller devices */
}

.p-tour__steps {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.p-tour__step {
  position: absolute;
  width: calc(100% - 2 * var(--space-md));
  left: var(--space-md);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  pointer-events: auto;
  z-index: 1;
  max-height: calc(100% - 2 * var(--space-md));
  overflow: auto;
  background-color: var(--color-bg-light);
  border-radius: var(--radius-md);
  box-shadow: 0 0.9px 2.2px rgba(0, 0, 0, 0.025), 0 2.1px 5.3px rgba(0, 0, 0, 0.036), 0 3.9px 10px rgba(0, 0, 0, 0.045), 0 6.9px 17.9px rgba(0, 0, 0, 0.054), 0 13px 33.4px rgba(0, 0, 0, 0.065), 0 31px 80px rgba(0, 0, 0, 0.09);
  transition: transform 0.3s, opacity 0.3s, visibility 0s 0.3s;
}

.p-tour__step--current {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  transition: transform 0.3s, opacity 0.3s, visibility 0s;
}

/* slide effect on smaller devices */
.p-tour__step--m-left {
  transform: translateY(-50%) translateX(-50px);
}

.p-tour__step--m-right {
  transform: translateY(-50%) translateX(50px);
}

.p-tour__skip {
  --size: 28px;
  width: var(--size);
  height: var(--size);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  backface-visibility: hidden;
  transition: background-color 0.2s;
}
.p-tour__skip:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.15);
}
.p-tour__skip .icon {
  --size: 16px;
}

@media (min-width: 48rem) {
  .p-tour {
    --p-tour-layout: "desktop";
    display: block;
    padding: 0;
    pointer-events: none;
  }

  .p-tour__background {
    opacity: 0;
  }

  .p-tour__background--animate {
    animation: p-tour-bg-anim 1.5s;
  }

  @keyframes p-tour-bg-anim {
    0%, 100% {
      opacity: 0;
    }
    30%, 60% {
      opacity: 1;
    }
  }
  .p-tour__step {
    width: 340px;
    transform: translate3d(0);
    transition: opacity 0.3s, visibility 0s 0.3s;
    max-height: none;
    overflow: visible;
  }

  .p-tour__step--current {
    transition: opacity 0.3s, visibility 0s;
  }

  /* triangle indicator */
  .p-tour__step--top::before,
.p-tour__step--bottom::before,
.p-tour__step--right::before,
.p-tour__step--left::before,
.p-tour__step--bottom-right::before,
.p-tour__step--bottom-left::before,
.p-tour__step--top-right::before,
.p-tour__step--top-left::before {
    content: "";
    position: absolute;
    color: var(--color-bg-light);
    /* triangle color */
  }

  .p-tour__step--bottom-right,
.p-tour__step--top-right {
    transform: translateX(calc(1.5 * var(--p-tour-pin-size) + var(--p-tour-pin-tooltip-gap)));
  }

  .p-tour__step--bottom-left,
.p-tour__step--top-left {
    transform: translateX(calc(-1.5 * var(--p-tour-pin-size) - var(--p-tour-pin-tooltip-gap)));
  }

  .p-tour__step--top {
    transform: translateY(calc(-1.5 * var(--p-tour-pin-size) - var(--p-tour-pin-tooltip-gap)));
  }
  .p-tour__step--top::before {
    top: calc(100% - 1px);
    left: 50%;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-top-color: currentColor;
  }

  .p-tour__step--top-right::before,
.p-tour__step--top-left::before {
    width: var(--p-tour-pin-size);
    height: var(--p-tour-pin-size);
    background-color: currentColor;
    bottom: 0;
  }

  .p-tour__step--top-right {
    border-bottom-left-radius: 0;
  }
  .p-tour__step--top-right::before {
    right: calc(100% - 1px);
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
  }

  .p-tour__step--top-left {
    border-bottom-right-radius: 0;
  }
  .p-tour__step--top-left::before {
    left: calc(100% - 1px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  }

  .p-tour__step--bottom {
    transform: translateY(calc(var(--p-tour-pin-size) * 1.5 + var(--p-tour-pin-tooltip-gap)));
  }
  .p-tour__step--bottom::before {
    bottom: calc(100% - 1px);
    left: 50%;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-bottom-color: currentColor;
  }

  .p-tour__step--bottom-right::before,
.p-tour__step--bottom-left::before {
    width: var(--p-tour-pin-size);
    height: var(--p-tour-pin-size);
    background-color: currentColor;
    top: 0;
  }

  .p-tour__step--bottom-right {
    border-top-left-radius: 0;
  }
  .p-tour__step--bottom-right::before {
    right: calc(100% - 1px);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  }

  .p-tour__step--bottom-left {
    border-top-right-radius: 0;
  }
  .p-tour__step--bottom-left::before {
    left: calc(100% - 1px);
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  }

  .p-tour__step--left {
    transform: translateX(calc(-1.5 * var(--p-tour-pin-size) - var(--p-tour-pin-tooltip-gap)));
  }
  .p-tour__step--left::before {
    top: 50%;
    left: calc(100% - 1px);
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-left-color: currentColor;
  }

  .p-tour__step--right {
    transform: translateX(calc(1.5 * var(--p-tour-pin-size) + var(--p-tour-pin-tooltip-gap)));
  }
  .p-tour__step--right::before {
    top: 50%;
    right: calc(100% - 1px);
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-right-color: currentColor;
  }

  /* dot indicator */
  .p-tour__pin {
    position: absolute;
    display: inline-block;
    width: var(--p-tour-pin-size);
    height: var(--p-tour-pin-size);
    border-radius: 50%;
    background-color: var(--color-accent);
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.3s;
  }
  .p-tour__pin::before {
    /* animated bg */
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: inherit;
    animation: p-tour-pin-anim 1.5s infinite var(--ease-out);
  }

  .p-tour__pin--out {
    transform: translateX(-50%) translateY(-50%) scale(0);
  }

  @keyframes p-tour-pin-anim {
    from {
      opacity: 1;
      transform: scale(0.25);
    }
    to {
      opacity: 0;
      transform: scale(3);
    }
  }
}
/* -------------------------------- 

File#: _1_reveal-effects
Title: Reveal Effects
Descr: A collection of reveal effects targeting specific elements as they enter the viewport
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --reveal-fx-duration: 0.6s;
  --reveal-fx-timing-function: var(--ease-out);
}

.js .reveal-fx {
  opacity: 0;
  transition: opacity, transform var(--reveal-fx-timing-function);
  transition-duration: var(--reveal-fx-duration);
}
.js .reveal-fx::before {
  display: none;
  content: "reveal-fx";
}
.js .reveal-fx--translate, .js .reveal-fx--translate-up {
  transform: translateY(50px);
}
.js .reveal-fx--translate-right {
  transform: translateX(-50px);
}
.js .reveal-fx--translate-left {
  transform: translateX(50px);
}
.js .reveal-fx--translate-down {
  transform: translateY(-50px);
}
.js .reveal-fx--scale {
  transform: scale(0.8);
}
.js .reveal-fx--scale-up {
  transform: translateY(50px) scale(0.8);
}
.js .reveal-fx--scale-right {
  transform: translateX(-50px) scale(0.8);
}
.js .reveal-fx--scale-left {
  transform: translateX(50px) scale(0.8);
}
.js .reveal-fx--scale-down {
  transform: translateY(-50px) scale(0.8);
}
.js .reveal-fx--rotate, .js .reveal-fx--rotate-down, .js .reveal-fx--rotate-right, .js .reveal-fx--rotate-left, .js .reveal-fx--rotate-up {
  perspective: 1000px;
}
.js .reveal-fx--rotate > *, .js .reveal-fx--rotate-down > *, .js .reveal-fx--rotate-right > *, .js .reveal-fx--rotate-left > *, .js .reveal-fx--rotate-up > * {
  transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  backface-visibility: hidden;
}
.js .reveal-fx--rotate > *, .js .reveal-fx--rotate-down > * {
  transform-origin: top;
  transform: rotateX(-45deg);
}
.js .reveal-fx--rotate-right > * {
  transform-origin: left center;
  transform: rotateY(45deg);
}
.js .reveal-fx--rotate-left > * {
  transform-origin: right center;
  transform: rotateY(-45deg);
}
.js .reveal-fx--rotate-up > * {
  transform-origin: bottom;
  transform: rotateX(45deg);
}
.js .reveal-fx--text-mask {
  overflow: hidden;
}
.js .reveal-fx--text-mask > * {
  display: inline-block;
  transform: translateY(100%);
  transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  will-change: transform;
}
.js [class*=reveal-fx--translate], .js [class*=reveal-fx--scale] {
  will-change: opacity, transform;
}
.js .reveal-fx--text-mask > *, .js [class*=reveal-fx--rotate] > * {
  will-change: transform;
}
.js .reveal-fx--clip-x > *,
.js .reveal-fx--clip-y > * {
  transition: opacity, clip-path var(--ease-out);
  transition-duration: var(--reveal-fx-duration);
}
.js .reveal-fx--clip-x > * {
  clip-path: polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%);
}
.js .reveal-fx--clip-y > * {
  clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
}
.js .reveal-fx--is-visible {
  opacity: 1;
}
.js .reveal-fx--is-visible[class*=reveal-fx--translate], .js .reveal-fx--is-visible[class*=reveal-fx--scale], .js .reveal-fx--is-visible[class*=reveal-fx--rotate] > *, .js .reveal-fx--is-visible.reveal-fx--text-mask > * {
  transform: translate(0);
}
.js .reveal-fx--is-visible.reveal-fx--clip-x > *, .js .reveal-fx--is-visible.reveal-fx--clip-y > * {
  opacity: 1;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

/* -------------------------------- 

File#: _1_scrolling-animations
Title: Scrolling Animations
Descr: Scrolling animations plugin
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --scroll-fx-translate-x: 0;
  --scroll-fx-translate-y: 0;
  --scroll-fx-translate-z: 0;
  --scroll-fx-rotate-x: 0;
  --scroll-fx-rotate-y: 0;
  --scroll-fx-rotate-z: 0;
  --scroll-fx-skew-x: 0;
  --scroll-fx-skew-y: 0;
  --scroll-fx-scale-x: 1;
  --scroll-fx-scale-y: 1;
  --scroll-fx-opacity: 1;
  --scroll-fx-stroke-dashoffset: 0;
}

.js .scroll-fx {
  transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
  opacity: var(--scroll-fx-opacity) !important;
  stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
}
.js .scroll-fx.scroll-fx--theme-transition,
.js .scroll-fx.scroll-fx--theme-transition > * {
  transition: 0.3s;
}

@media (min-width: 32rem) {
  .js .scroll-fx\@xs {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .js .scroll-fx\@xs.scroll-fx--theme-transition,
.js .scroll-fx\@xs.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@media (min-width: 48rem) {
  .js .scroll-fx\@sm {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .js .scroll-fx\@sm.scroll-fx--theme-transition,
.js .scroll-fx\@sm.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@media (min-width: 64rem) {
  .js .scroll-fx\@md {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .js .scroll-fx\@md.scroll-fx--theme-transition,
.js .scroll-fx\@md.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@media (min-width: 80rem) {
  .js .scroll-fx\@lg {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .js .scroll-fx\@lg.scroll-fx--theme-transition,
.js .scroll-fx\@lg.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@media (min-width: 90rem) {
  .js .scroll-fx\@xl {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
    stroke-dashoffset: var(--scroll-fx-stroke-dashoffset) !important;
  }
  .js .scroll-fx\@xl.scroll-fx--theme-transition,
.js .scroll-fx\@xl.scroll-fx--theme-transition > * {
    transition: 0.3s;
  }
}
@supports (--css: variables) {
  [data-theme=demo-dark] {
    --color-primary-darker: hsl(319, 78%, 49%);
    --color-primary-darker-h: 319;
    --color-primary-darker-s: 78%;
    --color-primary-darker-l: 49%;
    --color-primary-dark: hsl(319, 78%, 58%);
    --color-primary-dark-h: 319;
    --color-primary-dark-s: 78%;
    --color-primary-dark-l: 58%;
    --color-primary: hsl(319, 78%, 66%);
    --color-primary-h: 319;
    --color-primary-s: 78%;
    --color-primary-l: 66%;
    --color-primary-light: hsl(319, 78%, 72%);
    --color-primary-light-h: 319;
    --color-primary-light-s: 78%;
    --color-primary-light-l: 72%;
    --color-primary-lighter: hsl(319, 78%, 79%);
    --color-primary-lighter-h: 319;
    --color-primary-lighter-s: 78%;
    --color-primary-lighter-l: 79%;
    --color-accent-darker: hsl(250, 79%, 56%);
    --color-accent-darker-h: 250;
    --color-accent-darker-s: 79%;
    --color-accent-darker-l: 56%;
    --color-accent-dark: hsl(250, 79%, 60%);
    --color-accent-dark-h: 250;
    --color-accent-dark-s: 79%;
    --color-accent-dark-l: 60%;
    --color-accent: hsl(250, 79%, 65%);
    --color-accent-h: 250;
    --color-accent-s: 79%;
    --color-accent-l: 65%;
    --color-accent-light: hsl(250, 79%, 71%);
    --color-accent-light-h: 250;
    --color-accent-light-s: 79%;
    --color-accent-light-l: 71%;
    --color-accent-lighter: hsl(250, 79%, 76%);
    --color-accent-lighter-h: 250;
    --color-accent-lighter-s: 79%;
    --color-accent-lighter-l: 76%;
    --color-bg: hsl(240, 8%, 12%);
    --color-bg-h: 240;
    --color-bg-s: 8%;
    --color-bg-l: 12%;
    --color-contrast-lower: hsl(240, 6%, 15%);
    --color-contrast-lower-h: 240;
    --color-contrast-lower-s: 6%;
    --color-contrast-lower-l: 15%;
    --color-contrast-low: hsl(240, 5%, 24%);
    --color-contrast-low-h: 240;
    --color-contrast-low-s: 5%;
    --color-contrast-low-l: 24%;
    --color-contrast-medium: hsl(240, 3%, 55%);
    --color-contrast-medium-h: 240;
    --color-contrast-medium-s: 3%;
    --color-contrast-medium-l: 55%;
    --color-contrast-high: hsl(240, 10%, 86%);
    --color-contrast-high-h: 240;
    --color-contrast-high-s: 10%;
    --color-contrast-high-l: 86%;
    --color-contrast-higher: hsl(240, 47%, 97%);
    --color-contrast-higher-h: 240;
    --color-contrast-higher-s: 47%;
    --color-contrast-higher-l: 97%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.demo-scroll-fx-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
}
.demo-scroll-fx-grid > *:nth-child(1) {
  grid-column: 1/4;
  grid-row: 1/-1;
}
.demo-scroll-fx-grid > *:nth-child(2) {
  grid-column: 3/7;
  grid-row: 2/5;
}

/* -------------------------------- 

File#: _1_socials
Title: Socials
Descr: Section with links to social media accounts
Usage: codyhouse.co/license

-------------------------------- */
.socials__btns a {
  --size: 2.5em;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg);
  transition: 0.3s;
  text-decoration: none;
}
.socials__btns a .icon {
  font-size: 1.25em;
  /* icon size */
  color: var(--color-contrast-higher);
  /* icon color */
  transition: color 0.3s, transform 0.3s var(--ease-out-back);
}
.socials__btns a:hover {
  background-color: var(--color-primary);
}
.socials__btns a:hover .icon {
  transform: scale(1.2);
  color: var(--color-white);
}

/* -------------------------------- 

File#: _1_text-points
Title: Text Points
Descr: A list of text components
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --text-points-bullet-size: 2em;
  --text-points-bullet-text-scale: 1;
}

.text-points {
  --text-unit: 1em;
  font-size: 0.875em;
}

.text-points__item {
  color: var(--color-contrast-medium);
}
.text-points__item:first-child .text-points__title {
  padding-left: calc(var(--text-points-bullet-size) + var(--space-md) + 5px);
}
@media (min-width: 80rem) {
  .text-points__item:first-child .text-points__title {
    padding-left: 0;
  }
}
.text-points__item:first-child .text-points__subtitle {
  padding-left: calc(var(--text-points-bullet-size) + var(--space-md) + 15px);
}
@media (min-width: 80rem) {
  .text-points__item:first-child .text-points__subtitle {
    padding-left: 0;
  }
}
.text-points__item .text-component {
  --line-height-multiplier: 1.15;
}

.text-points--counter .text-points__title,
.text-points--letter .text-points__title,
.text-points--icon .text-points__title {
  position: relative;
  padding-left: calc(var(--text-points-bullet-size) + var(--space-md));
}
.text-points--counter .text-points__title::before,
.text-points--letter .text-points__title::before,
.text-points--icon .text-points__title::before {
  position: absolute;
  top: 5px;
  left: 0;
  font-size: calc(1em * var(--text-points-bullet-text-scale));
  height: calc(var(--text-points-bullet-size) / var(--text-points-bullet-text-scale));
  width: calc(var(--text-points-bullet-size) / var(--text-points-bullet-text-scale));
  line-height: calc(var(--text-points-bullet-size) / var(--text-points-bullet-text-scale));
  font-weight: medium;
  text-align: center;
  background-color: var(--color-primary);
  color: var(--color-black);
  border-radius: 8px;
}
@media (min-width: 80rem) {
  .text-points--counter .text-points__title,
.text-points--letter .text-points__title,
.text-points--icon .text-points__title {
    padding-left: 0;
  }
  .text-points--counter .text-points__title::before,
.text-points--letter .text-points__title::before,
.text-points--icon .text-points__title::before {
    transform: translateX(-100%);
    top: 10px;
    left: -30px;
  }
}
.text-points--counter .text-points__subtitle,
.text-points--letter .text-points__subtitle,
.text-points--icon .text-points__subtitle {
  padding-left: calc(var(--text-points-bullet-size) + var(--space-md) + 20px);
}
@media (min-width: 80rem) {
  .text-points--counter .text-points__subtitle,
.text-points--letter .text-points__subtitle,
.text-points--icon .text-points__subtitle {
    padding-left: 0;
  }
}

.text-points--counter .text-points__item {
  counter-increment: text-points;
}
.text-points--counter .text-points__title::before {
  content: counter(text-points);
}

.text-points--letter .text-points__title::before {
  content: "?";
}

.text-points--icon .text-points__title::before {
  content: "";
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm2.658,18.284c-.661.26-2.952,1.354-4.272.191a1.676,1.676,0,0,1-.59-1.318,15.978,15.978,0,0,1,.919-3.957,5.7,5.7,0,0,0,.231-1.313c0-.7-.266-.887-.987-.887a3.31,3.31,0,0,0-1.095.257l.195-.8a7.64,7.64,0,0,1,2.621-.71c1.269,0,2.2.633,2.2,1.837A5.585,5.585,0,0,1,13.7,12.96l-.73,2.582c-.151.522-.424,1.673,0,2.014a2.214,2.214,0,0,0,1.887-.071ZM13.452,8a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,13.452,8Z'/%3E%3C/svg%3E");
  background: var(--color-contrast-lower);
}

/* -------------------------------- 

File#: _2_adv-gallery-v3
Title: Advanced Gallery v3
Descr: Advanced image gallery template
Usage: codyhouse.co/license

-------------------------------- */
@media (min-width: 64rem) {
  .adv-gallery-v3__title {
    border-top: 1px solid var(--color-contrast-lower);
    padding-top: var(--space-xs);
  }
}
.adv-gallery-v3__img-link {
  position: relative;
  display: block;
  text-decoration: none;
  color: inherit;
}
.adv-gallery-v3__img-link::before, .adv-gallery-v3__img-link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
}
.adv-gallery-v3__img-link::before {
  clip-path: polygon(100% 0%, 100% 0%, 100% 0%, 100% 0%);
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
  transition: clip-path 0.2s var(--ease-out);
}
.adv-gallery-v3__img-link::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpolyline points='4 2 14 2 14 12' fill='none' stroke='%23FFF' stroke-miterlimit='10' stroke-width='2'/%3E%3Cline x1='14' y1='2' x2='2' y2='14' fill='none' stroke='%23FFF' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(0.5) translateX(-5px) translateY(5px);
  will-change: transform;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s var(--ease-out);
}
.adv-gallery-v3__img-link:hover::before {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.adv-gallery-v3__img-link:hover::after {
  transform: scale(1) translateX(0) translateY(0);
  opacity: 1;
}

/* -------------------------------- 

File#: _2_anim-cards
Title: Animated Cards
Descr: A collection of cards that slides over time
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --anim-cards-width: 280px;
  --anim-cards-gap: var(--space-md);
}

@supports (--css: variables) {
  @media (min-width: 48rem) {
    :root {
      --anim-cards-width: 400px;
    }
  }
  @media (min-width: 64rem) {
    :root {
      --anim-cards-width: 550px;
    }
  }
}
.anim-cards {
  overflow: hidden;
  margin-top: var(--anim-cards-gap);
  margin-bottom: var(--anim-cards-gap);
}

.anim-cards__list {
  display: flex;
  overflow: auto;
}

.anim-cards__item {
  float: left;
  width: var(--anim-cards-width);
  flex-shrink: 0;
  margin-right: var(--anim-cards-gap);
}

.anim-cards-control {
  display: inline-flex;
  width: 40px;
  height: 40px;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.75);
  border-radius: 50%;
  transition: 0.2s;
}
.anim-cards-control i {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  margin: auto;
  color: var(--color-bg);
}
.anim-cards-control i::before, .anim-cards-control i::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: currentColor;
  transition: clip-path 0.2s var(--ease-out);
}
.anim-cards-control i::before {
  clip-path: polygon(1px 1px, 5px 1px, 5px calc(100% - 1px), 1px calc(100% - 1px));
}
.anim-cards-control i::after {
  clip-path: polygon(calc(100% - 5px) 1px, calc(100% - 1px) 1px, calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
}
.anim-cards-control:hover {
  cursor: pointer;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.95);
}
.anim-cards-control[aria-pressed=true] i::before {
  clip-path: polygon(2px 0%, 100% 50%, 100% 50%, 2px 100%);
}
.anim-cards-control[aria-pressed=true] i::after {
  clip-path: polygon(calc(100% - 5px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 5px) calc(100% - 1px));
}

.js .anim-cards:not(.anim-cards--anim-off) .anim-cards__list {
  overflow: visible;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}
.js .anim-cards--reverse:not(.anim-cards--anim-off) .anim-cards__list {
  flex-direction: row-reverse;
}

html:not(.js) .anim-cards-control {
  display: none;
}

/* -------------------------------- 

File#: _2_carousel
Title: Carousel
Descr: Display and cycle through a collection of items
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --carousel-grid-gap: var(--space-xs);
  --carousel-item-auto-size: 260px;
  --carousel-transition-duration: 0.5s;
}

.carousel {
  position: relative;
}

.carousel__list {
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.carousel__item {
  flex-shrink: 0;
  width: var(--carousel-item-auto-size);
  margin-right: var(--carousel-grid-gap);
  margin-bottom: var(--carousel-grid-gap);
}

.js .carousel__list--animating {
  transition-property: transform;
  transition-duration: var(--carousel-transition-duration);
  transition-timing-function: var(--ease-out);
}
.js .carousel__item {
  opacity: 0;
  margin-bottom: 0;
}
.js .carousel--loaded .carousel__item {
  opacity: 1;
}
.js .carousel:not(.carousel--is-dragging) .carousel__list:not(.carousel__list--animating) .carousel__item[tabindex="-1"] > * {
  visibility: hidden;
}
.js .carousel[data-drag=on] .carousel__item {
  user-select: none;
}
.js .carousel[data-drag=on] .carousel__item img {
  pointer-events: none;
}

.carousel__control {
  --size: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  background-color: var(--color-bg-light);
  border-radius: 50%;
  box-shadow: var(--inner-glow), var(--shadow-sm);
  z-index: 1;
  transition: 0.2s;
}
.carousel__control:active {
  transform: translateY(1px);
}
.carousel__control:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-md);
}
.carousel__control[disabled] {
  pointer-events: none;
  opacity: 0.5;
  box-shadow: none;
}
.carousel__control .icon {
  --size: 20px;
  display: block;
  margin: auto;
}

.carousel__navigation {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 10px);
  gap: var(--space-xs);
  justify-content: center;
  align-items: center;
  margin-top: var(--space-sm);
}

.carousel__nav-item {
  display: inline-block;
  margin: 0 var(--space-xxxs);
}
@supports (grid-area: auto) {
  .carousel__nav-item {
    margin: 0;
  }
}
.carousel__nav-item button {
  display: block;
  position: relative;
  font-size: 10px;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: var(--color-contrast-high);
  opacity: 0.4;
  cursor: pointer;
  transition: background 0.3s;
}
.carousel__nav-item button::before {
  content: "";
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  font-size: 16px;
  height: 1em;
  width: 1em;
  border-radius: inherit;
  border: 1px solid var(--color-contrast-high);
  opacity: 0;
  transform: scale(0);
  transition: 3s;
}
.carousel__nav-item button:focus {
  outline: none;
}
.carousel__nav-item button:focus::before {
  opacity: 1;
  transform: scale(1);
}

.carousel__nav-item--selected button {
  opacity: 1;
}

.carousel__navigation--pagination {
  grid-template-columns: repeat(auto-fit, 24px);
}
.carousel__navigation--pagination .carousel__nav-item button {
  width: 24px;
  height: 24px;
  color: var(--color-bg);
  font-size: 12px;
  line-height: 24px;
  border-radius: var(--radius-md);
  text-align: center;
}
.carousel__navigation--pagination .carousel__nav-item button:focus {
  outline: 1px solid var(--color-primary);
  outline-offset: 2px;
}

html:not(.js) .carousel__list {
  overflow: auto;
}

.carousel--hide-controls .carousel__navigation, .carousel--hide-controls .carousel__control {
  display: none;
}

/* -------------------------------- 

File#: _2_device-group
Title: Device Group
Descr: A group of devices
Usage: codyhouse.co/license

-------------------------------- */
.device-group-1 {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(10, 1fr);
}
.device-group-1 > * {
  position: relative;
  grid-row: 1/-1;
}
.device-group-1 > *:nth-child(1),
.device-group-1 > *:nth-child(3) {
  z-index: 1;
}
.device-group-1 > *:nth-child(1) {
  grid-column: 1/5;
}
.device-group-1 > *:nth-child(2) {
  z-index: 2;
  grid-column: 4/8;
}
.device-group-1 > *:nth-child(3) {
  grid-column: 7/11;
}
.device-group-1 .dev-phone-3d-wrapper {
  perspective: 1000px;
}
.device-group-1 .dev-phone-3d-wrapper:first-child .dev-phone {
  transform: rotateY(30deg);
  transform-origin: left center;
}
.device-group-1 .dev-phone-3d-wrapper:last-child .dev-phone {
  transform: rotateY(-30deg);
  transform-origin: right center;
}

.device-group-2 {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: end;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 2.5%;
}
.device-group-2 > * {
  position: relative;
  grid-row: 1/-1;
}
.device-group-2 .dev-phone {
  z-index: 2;
  grid-column: 1/4;
  bottom: -5%;
}
.device-group-2 .dev-laptop {
  z-index: 1;
  grid-column: 2/13;
}

.device-group-3 {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: end;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 3.75%;
}
.device-group-3 > * {
  position: relative;
  grid-row: 1/-1;
}
.device-group-3 .dev-phone {
  z-index: 2;
  grid-column: 1/4;
  bottom: -5%;
}
.device-group-3 .dev-desktop {
  z-index: 1;
  grid-column: 2/13;
}

.device-group-4 {
  position: relative;
  z-index: 1;
  display: grid;
  align-items: end;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 2.75%;
}
.device-group-4 > * {
  position: relative;
  grid-row: 1/-1;
}
.device-group-4 .dev-phone {
  z-index: 2;
  grid-column: 1/3;
  bottom: -5%;
}
.device-group-4 .dev-desktop {
  z-index: 1;
  grid-column: 2/10;
}
.device-group-4 .dev-laptop {
  z-index: 2;
  bottom: -5%;
  grid-column: 6/13;
}

/* -------------------------------- 

File#: _2_diagonal-animated-divider
Title: Diagonal Animated Divider
Descr: A diagonal section divider that animates on scroll
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _2_draggable-img-gallery
Title: Draggable Image Gallery
Descr: A gallery of images that can be dragged to reveal new items
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --drag-gallery-img-width: 260px;
  --drag-gallery-img-gap: var(--space-lg);
}

@supports (--css: variables) {
  @media (min-width: 48rem) {
    :root {
      --drag-gallery-img-width: 400px;
    }
  }
  @media (min-width: 64rem) {
    :root {
      --drag-gallery-img-width: 520px;
    }
  }
}
.drag-gallery {
  position: relative;
  overflow: hidden;
}
.drag-gallery:focus {
  outline: none;
}

.drag-gallery__list {
  display: flex;
  align-items: center;
  overflow: auto;
  padding: 0 var(--component-padding);
}
.drag-gallery__list::after {
  display: block;
  content: "";
  height: 1px;
  padding-right: inherit;
}

.drag-gallery__item {
  float: left;
  width: var(--drag-gallery-img-width);
  flex-shrink: 0;
  margin-right: var(--drag-gallery-img-gap);
}
.drag-gallery__item:last-child {
  margin-right: 0;
}
.drag-gallery__item img {
  display: block;
}

.drag-gallery__gesture-hint {
  width: 4em;
  height: 4em;
  background-color: rgba(0, 0, 0, 0.95);
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 2em);
  display: none;
}

.js .drag-gallery {
  cursor: grab;
}
.js .drag-gallery--is-dragging {
  cursor: grabbing;
}
.js .drag-gallery__list {
  overflow: visible;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}
.js .drag-gallery__item {
  opacity: 0;
  transition: opacity 1.5s;
  user-select: none;
}
.js .drag-gallery__item img {
  pointer-events: none;
}
.js .drag-gallery__item--visible {
  opacity: 1;
}
.js .drag-gallery--anim-off .drag-gallery__item {
  opacity: 1;
}
.js .drag-gallery__gesture-hint {
  display: block;
  opacity: 0;
  transform: translateX(0) scale(1);
}
.js .drag-gallery__gesture-hint--animate {
  animation: drag-gallery-gesture-hint 2.8s;
}

@keyframes drag-gallery-gesture-hint {
  0%, 60% {
    opacity: 0;
    transform: translateX(0) scale(1);
  }
  5%, 65% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
  10%, 70% {
    opacity: 1;
    transform: translateX(0) scale(0.8);
  }
  30%, 90% {
    opacity: 1;
    transform: translateX(-200%) scale(0.8);
    animation-timing-function: var(--ease-in-out);
  }
  35%, 95% {
    opacity: 1;
    transform: translateX(-200%) scale(1);
  }
  40%, 100% {
    opacity: 0;
    transform: translateX(-200%) scale(1);
  }
}
/* -------------------------------- 

File#: _3_carousel-v3
Title: Carousel v3
Descr: Display a collection of items and navigate through them
Usage: codyhouse.co/license

-------------------------------- */
.carousel-v3 {
  --carousel-grid-gap: var(--space-xs);
  --carousel-item-auto-size: 260px;
  --carousel-transition-duration: 0.5s;
}

.carousel-v3__control {
  --size: 60px;
  height: var(--size);
  width: var(--size);
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.7);
  backdrop-filter: blur(10px);
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.carousel-v3__control:hover {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
}
.carousel-v3__control[disabled] {
  display: none;
}
.carousel-v3__control:active {
  transform: translateY(2px);
}
.carousel-v3__control .icon {
  --size: 20px;
  display: block;
  color: var(--color-white);
}

/* -------------------------------- 

File#: _3_feature-v14
Title: Feature v14
Descr: Feature content with tech devices
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _1_list-v2
Title: List v2
Descr: Custom list component
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --list-v2-bullet-size: 40px;
  --list-v2-bullet-font-size: 1rem;
  --list-v2-bullet-margin-right: 1rem;
  --list-v2-gap-y: var(--space-lg);
  /* list points gap */
}

.list-v2,
.text-component .list-v2 {
  padding-left: 0;
  list-style: none;
}
.list-v2 > li:not(:last-child),
.text-component .list-v2 > li:not(:last-child) {
  margin-bottom: var(--list-v2-gap-y);
}

.list-v2__title {
  padding-left: calc(var(--list-v2-bullet-size) + var(--list-v2-bullet-margin-right));
}

.list-v2__bullet {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--list-v2-bullet-size);
  height: var(--list-v2-bullet-size);
  margin-right: var(--list-v2-bullet-margin-right);
  margin-left: calc(-1 * var(--list-v2-bullet-size) - var(--list-v2-bullet-margin-right));
  vertical-align: middle;
  position: relative;
  top: -0.1em;
}

.list-v2__content {
  margin-left: calc(var(--list-v2-bullet-size) + var(--list-v2-bullet-margin-right));
}

/* ordered list */
.list-v2--ol,
.text-component .list-v2--ol {
  counter-reset: list-items;
}
.list-v2--ol > li,
.text-component .list-v2--ol > li {
  counter-increment: list-items;
}
.list-v2--ol .list-v2__bullet,
.text-component .list-v2--ol .list-v2__bullet {
  border-radius: 50%;
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  color: var(--color-primary);
  font-size: var(--list-v2-bullet-font-size);
  font-weight: 500;
}
.list-v2--ol .list-v2__bullet::before,
.text-component .list-v2--ol .list-v2__bullet::before {
  content: counter(list-items);
}

/* unordered list */
.list-v2--ul,
.text-component .list-v2--ul {
  --list-v2-bullet-size: 10px;
}
.list-v2--ul .list-v2__bullet,
.text-component .list-v2--ul .list-v2__bullet {
  border-radius: 50%;
  background-color: var(--color-primary);
}

/* icons */
.list-v2--icons,
.text-component .list-v2--icons {
  --list-v2-bullet-size: 48px;
}
.list-v2--icons .list-v2__bullet,
.text-component .list-v2--icons .list-v2__bullet {
  border-radius: 50%;
}
.list-v2--icons .list-v2__icon,
.text-component .list-v2--icons .list-v2__icon {
  --size: 48px;
  color: var(--color-primary);
  /* icon color */
}

/* -------------------------------- 

File#: _1_card-v14
Title: Card v14
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */
.card-v14 {
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  border-radius: var(--radius-lg);
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
  width: 100%;
}
.card-v14:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-md);
}

.card-v14__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card-v14__icon {
  --size: 48px;
  color: var(--color-primary);
}

/* -------------------------------- 

File#: _1_tabs
Title: Tabs
Descr: A list of content sections (panels), accessible one at a time using control labels
Usage: codyhouse.co/license

-------------------------------- */
.tabs__control {
  text-decoration: none;
  color: var(--color-contrast);
}
.tabs__control:focus {
  outline: none;
}
.tabs__control:hover {
  color: var(--color-contrast-high);
}

.js .tabs__control[aria-checked=true] {
  color: var(--color-contrast-high);
  color: var(--color-primary);
}

/* -------------------------------- 

File#: _1_section-divider
Title: Section divider
Descr: A collection of SVG section dividers.
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --section-divider-width: 1920;
  --section-divider-height: 60;
  --section-divider-ratio: calc(100% * var(--section-divider-height) / var(--section-divider-width));
}

[class*=has-section-divider] {
  position: relative;
}

.has-section-dividers {
  padding-top: var(--section-divider-ratio);
  padding-bottom: var(--section-divider-ratio);
}
.has-section-dividers .section-divider + .section-divider {
  bottom: auto;
  top: -1px;
  transform: scaleY(-1);
}

.has-section-divider-top {
  padding-top: var(--section-divider-ratio);
}
.has-section-divider-top .section-divider {
  bottom: auto;
  top: -1px;
  transform: scaleY(-1);
}

.has-section-divider-bottom {
  padding-bottom: var(--section-divider-ratio);
}

.section-divider {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.section-divider svg {
  position: relative;
  display: block;
  height: auto;
  max-width: none;
  width: 102%;
  left: -1%;
}

/* -------------------------------- 

File#: _1_custom-checkbox
Title: Custom Checkbox
Descr: Replace the native checkbox button with a custom element (e.g., an icon)
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --custom-checkbox-size: 20px;
  --custom-checkbox-radius: 4px;
  --custom-checkbox-border-width: 1px;
  --custom-checkbox-marker-size: 18px;
}

.custom-checkbox {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: var(--custom-checkbox-size);
}

.custom-checkbox__input {
  position: relative;
  /* hide native input */
  margin: 0;
  padding: 0;
  opacity: 0;
  height: 1em;
  width: 1em;
  display: block;
  z-index: 1;
}

.custom-checkbox__control {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  transition: transform 0.2s;
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), 0.65);
  /* unchecked color */
}
.custom-checkbox__control::before, .custom-checkbox__control::after {
  content: "";
  position: absolute;
}
.custom-checkbox__control::before {
  /* focus circle */
  width: 160%;
  height: 160%;
  background-color: currentColor;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  border-radius: 50%;
  will-change: transform;
  transition: transform 0.2s;
}
.custom-checkbox__control::after {
  /* custom checkbox */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* custom checkbox style */
  background-color: var(--color-bg);
  border-radius: var(--custom-checkbox-radius);
  box-shadow: inset 0 0 0 var(--custom-checkbox-border-width) currentColor, var(--shadow-xs);
  /* border */
}

.custom-checkbox__input:checked ~ .custom-checkbox__control::after,
.custom-checkbox__input:indeterminate ~ .custom-checkbox__control::after {
  background-color: currentColor;
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--custom-checkbox-marker-size);
  box-shadow: none;
}

.custom-checkbox__input:checked ~ .custom-checkbox__control {
  color: var(--color-primary);
  /* checked color */
}
.custom-checkbox__input:checked ~ .custom-checkbox__control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline points='2.5 8 6.5 12 13.5 3' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E");
}

.custom-checkbox__input:indeterminate ~ .custom-checkbox__control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cline x1='2' y1='8' x2='14' y2='8' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
}

.custom-checkbox__input:active ~ .custom-checkbox__control {
  transform: scale(0.9);
}

.custom-checkbox__input:checked:active ~ .custom-checkbox__control,
.custom-checkbox__input:indeterminate:active ~ .custom-checkbox__control {
  transform: scale(1);
}

.custom-checkbox__input:focus ~ .custom-checkbox__control::before {
  opacity: 0.2;
  transform: translate(-50%, -50%) scale(1);
}

/* --icon */
.custom-checkbox--icon {
  --custom-checkbox-size: 32px;
}
.custom-checkbox--icon .custom-checkbox__control::after {
  display: none;
}
.custom-checkbox--icon .icon {
  display: block;
  color: inherit;
  position: relative;
  z-index: 1;
}
